import React, { useState, useEffect } from 'react'

import moment from 'moment'
import SweetAlert from 'react-bootstrap-sweetalert'
import { useSelector, useDispatch } from 'react-redux'
import { addStaff, changeStaff, eraseStaff, resendPassword } from 'redux/actions/staff'
import { getTasks, addTask, modifyTask, eraseTask, sendTaskMessage } from 'redux/actions/maintenance'

import GridContainer from '../../components/Grid/GridContainer'
import GridItem from '../../components/Grid/GridItem'
import Card from '../../components/Card/Card'
import CardHeader from '../../components/Card/CardHeader'
import CardFooter from '../../components/Card/CardFooter'
import CardIcon from '../../components/Card/CardIcon'
import Table from 'components/Table/Table'
import CardBody from 'components/Card/CardBody'
import Wizard from 'components/Wizard/Wizard'
import Profile from '../wizardSteps/profile'
import NewTasks from '../wizardSteps/newTasks'
import CustomInput from 'components/CustomInput/CustomInput.js'
import Bubble from 'components/Chat/Bubble'

import { Dialog, DialogContent, Slide } from '@material-ui/core'

//styles
import { makeStyles } from '@material-ui/styles'
import { Equalizer, Assignment } from '@material-ui/icons'
import dashboardStyle from 'assets/jss/material-dashboard-pro-react/views/dashboardStyle'
import extendedTablesStyle from 'assets/jss/material-dashboard-pro-react/views/extendedTablesStyle'
import notificationsStyle from 'assets/jss/material-dashboard-pro-react/views/notificationsStyle.js'
import sweetAlertStyle from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js'


const useStyles = makeStyles(theme => ({
  ...extendedTablesStyle,
  ...notificationsStyle,
  ...sweetAlertStyle,
  ...dashboardStyle,
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='down' ref={ref} {...props} />
})

const Maintenance = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const hotelId = localStorage.getItem('hotel')

  useEffect(() => {
    dispatch(getTasks(hotelId))
  }, [dispatch, hotelId])

  const { edit, mainUsers, userModal, userId, firstname, lastname, email } = useSelector(state => state.staff)
  const { updatePending, updateDue, updateSolved } = useSelector(state => state.cardsTime)
  const { mainTasks, taskModal, editTask, room, title, description, taskId } = useSelector(state => state.tasks)
  const [modal, setModal] = useState(false)
  const [alert, setAlert] = useState(null)
  const [taskInfoModal, setTaskInfoModal] = useState(false)
  const [taskInfo, setTaskInfo] = useState({})
  const [message, setMessage] = useState('')
  let filterTask = taskInfo && mainTasks.filter(task => task.id === taskInfo)[0]

  const [pendingTime, setPendingTime] = useState(null)
  const [dueTime, setDueTime] = useState(null)
  const [solvedTime, setSolvedTime] = useState(null)

  let pending = mainTasks.filter(task => task.status === 'pending').length
  let due = mainTasks.filter(task => moment().isAfter(moment(task.created).add(24, 'hours'))).length
  let solved = mainTasks.filter(task => task.status === 'finished').length

  useEffect(() => {
    dispatch({ type: 'UPDATE_TIMES', payload: { updatePending: moment() } })
  }, [dispatch, pending])

  useEffect(() => {
    dispatch({ type: 'UPDATE_TIMES', payload: { updateDue: moment() } })
  }, [dispatch, due])

  useEffect(() => {
    dispatch({ type: 'UPDATE_TIMES', payload: { updateSolved: moment() } })
  }, [dispatch, solved])

  useEffect(() => {
    var maintenanceInterval = setInterval(() => {
      setPendingTime(moment(updatePending).from(moment()))
      setDueTime(moment(updateDue).from(moment()))
      setSolvedTime(moment(updateSolved).from(moment()))
    }, 1000)
    return () => {
      clearInterval(maintenanceInterval)
    }
  })

  const cardsInfo = [
    { color: 'rose', head: 'Tareas Pendientes', amount: pending, lastUpdate: pendingTime },
    { color: 'rose', head: 'Tareas Vencidas', amount: due, lastUpdate: dueTime },
    { color: 'warning', head: 'Tareas Resueltas', amount: solved, lastUpdate: solvedTime }
  ]

  const newStaff = function newStaff() {
    dispatch({ type: 'UPDATE_STAFF', payload: { edit: false, userModal: true } })
    setModal(true)
  }

  const resendPass = (data) => {
    const { firstname, lastname, contraseña, email } = data
    dispatch(resendPassword({ firstname, lastname, contraseña, email }))
    setAlert(
      <SweetAlert
        success
        style={{ display: 'block', marginTop: '200px' }}
        title='Email enviado!'
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes.button + ' ' + classes.success}
      >
        ¡Se ha enviado un correo para editar la contraseña!
      </SweetAlert>
    )
  }

  const editStaff = (data) => {
    dispatch({ type: 'UPDATE_STAFF', payload: { edit: true, userModal: true, firstname: data.name, lastname: data.lastName, email: data.email, userId: data.id } })
    setModal(true)
  }

  const sendDelete = (data) => {
    console.log('data', data)
    setAlert(
      <SweetAlert
        warning
        style={{ display: 'block', marginTop: '200px' }}
        title={`Eliminar ${data.room ? 'Tarea' : 'Perfil'}`}
        onConfirm={() => successDelete(data)}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes.button + ' ' + classes.success}
        cancelBtnCssClass={classes.button + ' ' + classes.danger}
        confirmBtnText='Eliminar'
        cancelBtnText='Cancelar'
        showCancel
      >
        ¿Seguro desea eliminar {data.room ? 'esta tarea' : 'este perfil'}
      </SweetAlert>
    )
  }

  const successDelete = (data) => {
    data.room ? dispatch(eraseTask(hotelId, data)) : dispatch(eraseStaff(hotelId, data))
    setAlert(
      <SweetAlert
        success
        style={{ display: 'block', marginTop: '200px' }}
        title='¡Eliminado!'
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes.button + ' ' + classes.success}
      >
        ¡Se ha eliminado exitosamente!
      </SweetAlert>
    )
  }

  const newTask = () => {
    dispatch({ type: 'UPDATE_TASKS', payload: { taskModal: true } })
    setModal(true)
  }

  const changeTask = data => {
    dispatch({ type: 'UPDATE_TASKS', payload: { taskModal: true, editTask: true, ...data, taskId: data.id } })
    setModal(true)
  }

  const openTask = data => {
    setTaskInfo(data.id)
    setTaskInfoModal(true)
    setModal(true)
  }

  const taskMessage = (data) => {
    data && dispatch(sendTaskMessage(hotelId, { id: taskInfo, message: data }))
    setMessage('')
  }

  const closeModal = () => {
    setModal(false)
    setTaskInfoModal(false)
    setTaskInfo({})
    dispatch({ type: 'RESET_STAFF' })
    dispatch({ type: 'RESET_TASKS' })
  }

  const hideAlert = () => {
    setAlert(null)
  }

  const finishButton = () => {
    if (taskModal) {
      editTask ?
        dispatch(modifyTask(hotelId, { room, title, description, id: taskId }))
        :
        dispatch(addTask(hotelId, { room, title, description }))
    } else {
      edit ?
        dispatch(changeStaff(hotelId, { email, firstname, lastname, id: userId }))
        :
        dispatch(addStaff(hotelId, { email, firstname, lastname, type: 'maintenance' }))
    }
    closeModal()
  }

  return (
    <div>
      {alert}
      <GridContainer>
        {cardsInfo.map((info, i) => {
          return (
            <GridItem md={4} key={i}>
              <Card
                className='card-dashboard'
                style={{ userSelect: 'none', cursor: 'pointer' }}
              >
                <CardHeader stats icon>
                  <CardIcon
                    color={info.color}
                  >
                    <Equalizer />
                  </CardIcon>
                  <p className={classes.cardCategory}>
                    {info.head}
                  </p>
                  <h3 className={classes.cardTitle}>
                    {info.amount}
                  </h3>
                </CardHeader>
                <CardFooter style={{ height: 25 }} stats>
                  <div className={classes.stats}>
                    Actualizado {info.lastUpdate}
                  </div>
                </CardFooter>
              </Card>
            </GridItem>
          )
        })}
        <GridItem xs={12} sm={12} md={12} lg={12} >
          <Card
            buttonright={{ text: 'Nueva tarea', function: newTask }}>
            <CardHeader color='rose' icon>
              <CardIcon color='rose'>
                <Assignment />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Tareas</h4>
            </CardHeader>
            <CardBody >
              <Table
                tableHead={['#', 'Habitación', 'Desde', 'Tarea', 'Asignado', 'Estado', 'Acciones']}
                tableData={mainTasks}
                customCellClasses={[classes.left, classes.left, classes.right]}
                customClassesForCells={[0, 4, 5]}
                customHeadCellClasses={[classes.left, classes.left, classes.right]}
                customHeadClassesForCells={[0, 4, 5]}
                maintenanceTasks={true}
                changeTask={changeTask}
                deleteTask={sendDelete}
                openTask={openTask}
              />
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={12} >
          <Card
            buttonright={{ text: 'Crear usuario', function: newStaff }}>
            <CardHeader color='rose' icon>
              <CardIcon color='rose'>
                <Assignment />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Usuarios</h4>
            </CardHeader>
            <CardBody >
              <Table
                tableHead={['#', 'Nombre', 'Cargo', 'Desde', 'Tarea Actual', 'Acciones']}
                tableData={mainUsers}
                customCellClasses={[classes.left, classes.left, classes.right]}
                customClassesForCells={[0, 4, 5]}
                customHeadCellClasses={[classes.left, classes.left, classes.right]}
                customHeadClassesForCells={[0, 4, 5]}
                maintenance={true}
                resendPass={resendPass}
                editStaff={editStaff}
                deleteStaff={sendDelete}
                buttonStyle={classes.actionButton}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <Dialog
        classes={{
          root: classes.center + ' ' + classes.modalRoot,
          paper: classes.modal
        }}
        open={modal}
        maxWidth={'lg'}
        TransitionComponent={Transition}
        keepMounted
        onClose={closeModal}
        aria-labelledby='classic-modal-slide-title'
        aria-describedby='classic-modal-slide-description'
      >
        <DialogContent
          id='classic-modal-slide-description'
          className={classes.modalBody}
        >
          {(userModal || taskModal) &&
            <Wizard
              validate
              steps={[
                { stepName: userModal ? edit ? 'Editar Perfil' : 'Nuevo Perfil' : edit ? 'Editar Tarea' : 'Nueva Tarea', stepComponent: userModal ? Profile : NewTasks, stepId: userModal ? 'perfil' : 'tarea' },
              ]}
              title={userModal ? edit ? 'Editar Perfil' : 'Crear Perfil' : edit ? 'Editar Tarea' : 'Crear Tarea'}
              subtitle={userModal ? `Complete la información para ${edit ? 'editar el' : 'crear un nuevo'} perfil.`
                : `Complete la información para ${edit ? 'editar la' : 'crear una nueva'} tarea.`}
              finishButtonClick={finishButton}
            />}

          {taskInfoModal &&
            <GridContainer justify='center'>
              <GridItem xs={12} sm={12}>
                <h2 className={classes.infoText}>
                  {filterTask.room} - {filterTask.title}
                </h2>
              </GridItem>
              <GridItem xs={12} sm={8}>
                <h4>
                  {filterTask.description}
                </h4>
              </GridItem>
              <GridItem xs={12} sm={8}>
                {filterTask.messages && filterTask.messages.map((msg, key) => {
                  return (
                    msg.from === 'hotel' ?
                      <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }} key={key}>
                        <Bubble
                          arrow={(key !== 0 && filterTask.messages[key - 1].from === msg.from) ? false : true}
                          color='#2196f3'
                          from='you'
                          msg={msg.message}
                          time={moment(msg.date.toDate()).format('LT')}
                        />
                      </div>
                      :
                      <div style={{ width: '100%', display: 'flex' }} key={key}>
                        <Bubble
                          arrow={(key !== 0 && filterTask.messages[key - 1].from === msg.from) ? false : true}
                          color='#c1c0c0'
                          msg={msg.message}
                          time={moment(msg.date.toDate()).format('LT')}
                        />
                      </div>
                  )
                })}
                <CustomInput
                  labelText={
                    <span>
                      Escriba su mensaje<small></small>
                    </span>
                  }
                  id='room'
                  value={message}
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    onChange: event => setMessage(event.target.value),
                    onKeyPress: e => e.key === 'Enter' && taskMessage(message)
                  }}
                />
              </GridItem>
            </GridContainer>}
        </DialogContent>
      </Dialog>
    </div>
  )
}


export default Maintenance