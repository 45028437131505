import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Button from '../components/button'
import { getService } from 'redux/actions/webappServices'

export default ({ room, hotel, license }) => {
	const service = useSelector(state => state.services.chat)
	const history = useHistory()
	const dispatch = useDispatch()
	const hotelId = localStorage.getItem('hotel')

	useEffect(() => dispatch(getService(hotelId, room, 'chat')), [room, dispatch, hotelId])

	const onClick = () => history.push(`/${hotel}/app/${room}/chat`, { response: license })

	return <Button key={service.key} onClick={onClick} service={service} />
}
