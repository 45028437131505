import axios from 'axios';

const API = 'https://hotels-prod-a5b59.firebaseapp.com/'

export const sendSurvey = () => (dispatch, getState) => {
  const { ...survey } = getState().survey
  const newSurvey = {
    text: `Hola ${survey.surveyName}! Esperamos que hayas disfrutado de estos días en SeaSide Beach Hotel. Gracias otra vez por elegirnos! Si querés dejarnos tu puntuación en una breve encuesta o dejarnos alguna sugerencia podés hacerlo desde este link https://escuestas.seasidebeach.com`,
    ...survey
  }

  axios.post(`${API}/api/hotel/sendSurvey`, newSurvey)
    .then(resp => console.log(resp))
    .catch((err => console.log(err)))
}