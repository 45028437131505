import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Button from '../components/button'
import { getService, changeStatus } from 'redux/actions/webappServices'

export default ({ room }) => {
	const state = useSelector(state => state.services.disturb)
	const dispatch = useDispatch()
	const hotelId = localStorage.getItem('hotel')

	useEffect(() => dispatch(getService(hotelId, room, 'disturb')), [hotelId, room, dispatch])

	const { name, key } = state

	return <Button onClick={() => changeStatus(hotelId, room, name, key)} service={state} />
}
