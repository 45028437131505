import Background from '../../../assets/img/faena.svg'

export default {
  root: {
    position: 'fixed',
    bottom: 0,
    top: 0,
    left: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    backgroundImage: `url(${Background})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
  },
  container: {
    height: '40%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  containInputs: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    flex: 2,
    width: '100%'
  },
  refInputs: {
    margin: 0,
    color: '#fff',
    fontFamily: 'Lato',
    fontWeight: 600,
    fontSize: 20
  },
  containButton: {
    flex: 1,
    display: 'flex',
    alignItems: 'flex-end'
  },
  button: {
    width: 100,
    height: 40,
    border: 'none',
    backgroundColor: '#E91E63',
    color: '#fff',
    fontFamily: 'Lato',
    fontSize: 14,
    fontWeight: 400
  },
  inputs: {
    width: '80%',
    height: 50,
    fontSize: 28,
    border: 'none',
    fontFamily: 'Lato'
  },
}