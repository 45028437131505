const initialState = {
	user: [],
}

export default (state = initialState, { type, payload }) => {
	switch (type) {
		case 'GET_USER':
			return {
				...state,
				user: payload
			}

		default:
			return state
	}
}