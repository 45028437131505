import React, { useState, useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import styles from './styles'

import { AccountBox } from '@material-ui/icons'

export default props => {
  const history = useHistory()
  const profiles = useSelector(state => state.webapp.profiles)
  const { hotel } = props.match.params
  const room = localStorage.getItem('room')
  const code = localStorage.getItem('code')

  const selectProfile = profile => {
    localStorage.setItem('profile', JSON.stringify(profile))
    history.push(`/${hotel}/app/home`)
  }

  return (
    <div style={styles.root}>
      <div style={styles.containTitle}>
        <h2 style={styles.title}>Selecciona tu Nombre</h2>
      </div>
      <div style={styles.containProfiles}>
        {
          profiles.map((profile, key) => {
            return (
              <div style={styles.profileBox} onClick={() => selectProfile(profile)} key={`profile-${key}`}>
                <AccountBox style={styles.profileIcon} />
                <p style={styles.profileName}>{profile.name}</p>
              </div>
            )
          })
        }
      </div>
    </div>
  )
}