import firebase from '../../services/firebase'
import admin from 'firebase/app'

const hotel = firebase.firestore().collection('hotels')

export const getService = id => dispatch => {
  hotel.doc(id).collection('services').onSnapshot(services => {
    let currentServices = []
    services.docs.forEach(service => {
      if (service.data().onDash) currentServices.push({ name: service.data().name, key: service.data().key })
    })

    dispatch({ type: 'SET_SERVICES', payload: currentServices })
  })
}

export const addService = (id, room, key) => dispatch => {
  hotel.doc(id).collection('services').doc(key).get().then(service => {
    let newService = service.data().rooms
    let serviceName = service.data().name
    let status = false
    if (!newService[room]) {
        newService[room] = 'pending'
        status = !status
    }
    else delete newService[room]

    return [status, newService, serviceName]
  })
  .then(changes => {
    hotel.doc(id).collection('services').doc(key).update({ rooms: changes[1] })

    hotel.doc(id).collection('rooms').doc(room).update({
      services: changes[0]
        ? admin.firestore.FieldValue.arrayUnion(changes[2])
        : admin.firestore.FieldValue.arrayRemove(changes[2])
    })
  })
}

export const removeService = (id, room, name) => dispatch => {
  hotel.doc(id).collection('services').get().then(services => {
    let aux = []
    
    services.docs.forEach(service => {
      if (service.data().name === name) {
        if (name === 'Emergencia') {
          let newRooms = service.data().rooms
          let serviceKey = service.data().key

          newRooms[room] = 'pending'
          aux.push(newRooms, serviceKey)
        }
        else {
          let newRooms = service.data().rooms
          let serviceKey = service.data().key

          delete newRooms[room]
          aux.push(newRooms, serviceKey)
        }
      }
    })
    return aux
  })
  .then(changes => {
    hotel.doc(id).collection('services').doc(changes[1]).update({ rooms: changes[0] })

    if (changes[1] !== 'emergency') hotel.doc(id).collection('rooms').doc(room).update({
      services: admin.firestore.FieldValue.arrayRemove(name)
    })
    else if (name === "Emergencia en Curso") {
      hotel.doc(id).collection("rooms").doc(room).update({
        services: admin.firestore.FieldValue.arrayRemove(name)
      })
    }
    else {
      hotel.doc(id).collection('rooms').doc(room).update({ services: admin.firestore.FieldValue.arrayRemove(name) })
      hotel.doc(id).collection('rooms').doc(room).update({ services: admin.firestore.FieldValue.arrayUnion('Emergencia en Curso') })
    }
  })
}