import Background from 'assets/img/faena.svg'
import image from 'assets/img/faena.svg'

const styles = {
  rootContainer: {
    display: 'flex',
    flexDirection: 'column',
    position: 'fixed',
    bottom: 0,
    top: 0,
    left: 0,
    right: 0,
    backgroundImage: `url(${Background})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    background: image
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 70,
  },
  backButton: {
    border: 'none',
    background: 'none',
    marginLeft: 10,
    color: '#fff'
  },
  textRoom: {
    color: '#fff',
    fontSize: 20,
    margin: '0 20px 0 0',
  },
  containMessages: {
    height: 'calc(100% - 130px)',
    overflow: 'hidden auto', 
  },
  containClientBubble: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
  },
  clientBubble: {
    position: 'relative', 
    maxWidth: '70%',
    minWidth: '30%',
    alignSelf: 'flex-end',
    margin: 10,
    padding: 10,
    borderRadius: 6,
    backgroundColor: '#2196f3',
  },
  bodyBubble: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  dateMessage: {
    fontSize: 11,
    display: 'flex',
    alignItems: 'flex-end',
    fontWeight: 200,
    marginLeft: 15,
    minWidth: 15,
    justifyContent: 'flex-end',
  },
  containHotelBubble: {
    width: '100%',
  },
  hotelBubble: {
    maxWidth: '70%',
    minWidth: '30%',
    padding: 10,
    margin: 10,
    borderRadius: 6,
    alignSelf: 'flex-end',
    position: 'relative',
    backgroundColor: '#c1c0c0',
  },
  footer: {
    display: 'flex',
    height: 60,
    marginTop: 5
  },
  inputText: {
    width: 'calc(100% - 100px)',
    border: 'none',
    paddingLeft: 15,
  },
  sendButton: {
    width: 100,
    color: '#fff',
    border: 'none'
  }
}

export default styles