import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { history } from "../../redux/store";

// Actions
import { login } from "redux/actions/auth";

// @material-ui/core components
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import Email from "@material-ui/icons/Email";

// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CustomInput from "components/CustomInput/CustomInput";
import Button from "components/CustomButtons/Button";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CardFooter from "components/Card/CardFooter";
import CircularProgress from "@material-ui/core/CircularProgress";

import withStyles from "@material-ui/core/styles/withStyles";
import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle";

export default withStyles(loginPageStyle)(props => {
  const dispatch = useDispatch();
  const { error, errorEmail, errorPassword, loading } = useSelector(
    state => state.auth
  );

  const [animation, setAnimation] = useState("cardHidden");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const timeOutFunction = () => setTimeout(() => setAnimation(""), 700);

  useEffect(() => {
    timeOutFunction();
    return () => clearTimeout(timeOutFunction);
  }, []);

  const { classes } = props;

  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={6} md={4}>
          <form>
            <Card login className={classes[animation]}>
              <CardHeader
                className={`${classes.cardHeader} ${classes.textCenter}`}
                color="rose"
              >
                <h4 className={classes.cardTitle}>Iniciar Sesión</h4>
              </CardHeader>
              <CardBody>
                <CustomInput
                  labelText="Email"
                  id="email"
                  formControlProps={{
                    fullWidth: true,
                    error: errorEmail
                  }}
                  type="text"
                  onChange={e => setEmail(e.target.value)}
                  value={email}
                  inputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Email className={classes.inputAdornmentIcon} />
                      </InputAdornment>
                    )
                  }}
                />
                {errorEmail ? <p style={{ color: "red" }}>{error}</p> : <p></p>}
                <CustomInput
                  labelText="Contraseña"
                  id="password"
                  formControlProps={{
                    fullWidth: true,
                    error: errorPassword
                  }}
                  onKeyPress={e =>
                    e.key === "Enter" &&
                    dispatch(login(email, password, history))
                  }
                  type="password"
                  onChange={e => setPassword(e.target.value)}
                  value={password}
                  inputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Icon className={classes.inputAdornmentIcon}>
                          lock_outline
                        </Icon>
                      </InputAdornment>
                    )
                  }}
                />
                {errorPassword ? (
                  <p style={{ color: "red" }}>{error}</p>
                ) : (
                  <p></p>
                )}
              </CardBody>
              <CardFooter className={classes.justifyContentCenter}>
                {!loading ? (
                  <Button
                    color="rose"
                    simple
                    size="lg"
                    block
                    onClick={() => dispatch(login(email, password, history))}
                  >
                    INGRESAR
                  </Button>
                ) : (
                  <div style={{ height: 64 }}>
                    <CircularProgress style={{ color: "#ff2068" }} />
                  </div>
                )}
              </CardFooter>
            </Card>
          </form>
        </GridItem>
      </GridContainer>
    </div>
  );
});
