const initialState = {
	layout: []
}

export default (state = initialState, action = {}) => {
	switch (action.type) {

		case 'SET_SURVEYAPP':
			return {
				...state,
				layout: action.payload
			}

			default:
				return state
	}
}
