import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import styles from './styles'
import firebase from 'firebase'

import { getRoomService } from 'redux/actions/webApp'

import withStyles from '@material-ui/core/styles/withStyles'
import { Face } from '@material-ui/icons'
import CustomDropdown from 'components/CustomDropdown/CustomDropdown'
import Logo from 'assets/img/faenaLogo.png'

import {
  Alarm,
  Chat,
  Clean,
  Disturb,
  Emergency,
  Events,
  RoomService,
  Information,
  Map,
  Medic,
  Reservation,
  Ski,
  Survey,
  Towels
} from '../../services'

const DB_HOTEL = firebase.firestore().collection('hotels')

// const messaging = firebase.messaging()
// messaging.usePublicVapidKey('BKP8P0UpUf9yplI7R4hJ96sBflLx9dAP0TgebIgcBDWh1wZvvRA7cklbwzqJGbNharaRrepSLUmoZdcOSGUQx7A')

export default withStyles(styles)(props => {
  const history = useHistory()
  const dispatch = useDispatch()

  const [license, setLicense] = useState('')
  const [statusService, setStatusService] = useState(null)

  const profile = JSON.parse(localStorage.getItem('profile'))
  const room = localStorage.getItem('room')

  const services = useSelector(state => state.webServices.webServices)
  const { hotel } = props.match.params

  // useEffect(() => {
  //   messaging.requestPermission()
  //     .then(async function() {
  //       const token = await messaging.getToken()
  //     })
  //     .catch(function(err) {
  //       console.log('Unable to get permission to notify', err)
  //     })

  //   navigator.serviceWorker.addEventListener('message', message => console.log(message))
  // }, [])

  useEffect(() => {
    room !== undefined && dispatch(getRoomService(room, hotel))
    localStorage.setItem('hotel', hotel)
  }, [dispatch, room])

  useEffect(() => {
    if (services.includes('Emergencia')) {
      DB_HOTEL.doc(hotel)
        .collection('services')
        .doc('emergency')
        .onSnapshot(snap => {
          let data = snap.data()
          data.rooms[room]
            ? setStatusService({ name: 'Emergencia', status: data.rooms[room] })
            : setStatusService(null)
        })
    }
  }, [services])

  useEffect(() => {
    (async () => {
      let hotelData = (await DB_HOTEL.doc(hotel).get()).data()
      setLicense(hotelData.license)
    })()
  }, [])

  const signOut = () => {
    localStorage.clear()
    history.push(`/${hotel}/app/auth`)
  }

  const changeProfile = () => localStorage.removeItem('profile')

  const UserMenu = props => {
    const dropdownButtons = [
      <button onClick={props.changeProfile} style={styles.buttonDropDown}>
        CAMBIAR PERFIL
      </button>,
      <button onClick={props.signOut} style={styles.buttonDropDown}>
        CERRAR SESIÓN
      </button>
    ]

    if (props.license === 'lite') {
      return (
        <button onClick={props.signOut} style={styles.buttonEndSession}>
          CERRAR SESIÓN
        </button>
      )
    } else {
      return (
        <CustomDropdown
          hoverColor='info'
          buttonText={profile.name}
          buttonIcon={Face}
          buttonProps={{
            fullWidth: true,
            style: { color: 'white' },
            color: 'transparent'
          }}
          dropdownList={dropdownButtons}
        />
      )
    }
  }

  return (
    <div style={styles.root}>
      {
        statusService && (
          <div style={styles.containAlert}>
            <p style={styles.textAlert}>
              {`Estado ${statusService.name}: ${statusService.status}`}
            </p>
          </div>
        )
      }
      <div style={{ position: 'absolute', right: 0 }}>
        <UserMenu signOut={signOut} changeProfile={changeProfile} license />
      </div>
      <div style={styles.body}>
        <div style={styles.topSection}>
          <img alt='Logo' src={Logo} style={{ width: 140 }} />
        </div>
        <div style={styles.midSection}>
          <Disturb room={room} />
          <Clean {...props} room={room} />
          <RoomService {...props} />
          <Alarm room={room} />
          <Emergency {...props} room={room} />
          <Chat room={room} hotel={hotel} license={license} />
          <Events room={room} />
          <Towels room={room} />
          <Medic room={room} />
          <Reservation room={room} />
          <Map room={room} hotel={hotel} />
          <Ski room={room} />
          <Survey room={room} />
          <Information {...props} room={room} />
        </div>
        <div style={styles.bottomSection}>
          <p style={styles.subTitle}>HABITACIÓN</p>
          <p style={styles.roomNumber}>{room}</p>
        </div>
      </div>
    </div>
  )
})
