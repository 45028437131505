import Rooms from 'views/Rooms/index'
import Campaign from 'views/Campaigns'
import WebChat from 'views/Chat'
// import Settings from 'views/Settings'

import Dash from './views/Dashboard/index'
import Room from './views/Rooms/room.jsx'
import Survey from './views/Surveys/index'
// import Analytics from './views/Analytics/index'
import Checkin from './views/Checkin/index'
import Cleaning from './views/Cleaning/index'
import Maintenance from './views/Maintenance/index'
import Creator from "./adminPanel/index"
import Creation from "./hotelCreation/index"
import GraduatesCheckIn from "./views/CheckIn/Graduates/index"
import DashboardIcon from '@material-ui/icons/Dashboard'
import {
  AssignmentTurnedIn,
  SupervisedUserCircle,
  // SettingsApplications,
  Hotel,
  Forum,
  ContactMail,
  ViewModule,
} from '@material-ui/icons'

export const creatorRoutes = [
  {
    path: '/creator',
    name: 'Creacion De Hoteles',
    id: 'Creator',
    component: Creator,
    layout: '/admin',
  },
]
export const onCreationRoute = [
  {
    path: '/creation',
    name: 'Creación de Hotel',
    id: 'hotel',
    icon: AssignmentTurnedIn,
    component: Creation,
    layout: '/admin',
  }
]
export const liteRoutes = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    icon: DashboardIcon,
    component: Dash,
    layout: '/admin',
  },
  {
    path: '/rooms',
    name: 'Habitaciones',
    icon: ViewModule,
    component: Rooms,
    layout: '/admin',
  },
  {
    hidden: true,
    path: '/room/:room',
    name: 'Habitaciones',
    id: 'hotel',
    component: Room,
    layout: '/admin',
  },
  {
    path: '/chat',
    name: 'Chat',
    icon: Forum,
    component: WebChat,
    layout: '/admin',
  },
  {
    path: '/campaigns',
    name: 'Campañas',
    icon: ContactMail,
    component: Campaign,
    layout: '/admin',
  },
  {
    path: '/surveys',
    name: 'Encuesta',
    id: 'hotel',
    icon: AssignmentTurnedIn,
    component: Survey,
    layout: '/admin',
  },
  {
    path: '/cleaning',
    name: 'Limpieza',
    id: 'hotel',
    icon: Hotel,
    component: Cleaning,
    layout: '/admin'
  },
  {
    path: '/maintenance',
    name: 'Mantenimiento',
    id: 'hotel',
    icon: AssignmentTurnedIn,
    component: Maintenance,
    layout: '/admin'
  },
  // {
  //   path: '/settings',
  //   name: 'Configuración',
  //   id: 'settings',
  //   icon: SettingsApplications,
  //   component: Settings,
  //   layout: '/admin',
  // }
]

export const dashRoutes = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    icon: DashboardIcon,
    component: Dash,
    layout: '/admin',
  },
  {
    path: '/rooms',
    name: 'Habitaciones',
    icon: ViewModule,
    component: Rooms,
    layout: '/admin',
  },
  {
    hidden: true,
    path: '/room/:room',
    name: 'Habitaciones',
    id: 'hotel',
    component: Room,
    layout: '/admin',
  },
  {
    path: '/checkin',
    name: 'Check In',
    id: 'hotel',
    noLite: true,
    icon: SupervisedUserCircle,
    component: Checkin,
    layout: '/admin'
  },
  {
    path: '/chat',
    name: 'Chat',
    icon: Forum,
    component: WebChat,
    layout: '/admin',
  },
  {
    path: '/campaigns',
    name: 'Campañas',
    icon: ContactMail,
    component: Campaign,
    layout: '/admin',
  },
  // {
  //   path: '/analytics',
  //   name: 'Analytics',
  //   id: 'hotel',
  //   icon: Equalizer,
  //   component: Analytics,
  //   layout: '/admin'
  // },
  {
    path: '/surveys',
    name: 'Encuesta',
    id: 'hotel',
    icon: AssignmentTurnedIn,
    component: Survey,
    layout: '/admin',
  },
  {
    path: '/cleaning',
    name: 'Limpieza',
    id: 'hotel',
    icon: Hotel,
    component: Cleaning,
    layout: '/admin'
  },
  {
    path: '/maintenance',
    name: 'Mantenimiento',
    id: 'hotel',
    icon: AssignmentTurnedIn,
    component: Maintenance,
    layout: '/admin'
  },
  // {
  //   path: '/settings',
  //   name: 'Configuración',
  //   id: 'settings',
  //   icon: SettingsApplications,
  //   component: Settings,
  //   layout: '/admin',
  // }
]
export const graduatesRoutes = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    icon: DashboardIcon,
    component: Dash,
    layout: '/admin',
  },
  {
    path: '/rooms',
    name: 'Habitaciones',
    icon: DashboardIcon,
    component: Rooms,
    layout: '/admin',
  },
  {
    hidden: true,
    path: '/room/:room',
    name: 'Habitaciones',
    id: 'hotel',
    component: Room,
    layout: '/admin',
  },
  {
    path: '/checkin',
    name: 'Check In',
    id: 'hotel',
    noLite: true,
    icon: SupervisedUserCircle,
    component: GraduatesCheckIn,
    layout: '/admin'
  },
  {
    path: '/chat',
    name: 'Chat',
    icon: Forum,
    component: WebChat,
    layout: '/admin',
  },
  {
    path: '/campaigns',
    name: 'Campañas',
    icon: ContactMail,
    component: Campaign,
    layout: '/admin',
  },
  // {
  //   path: '/analytics',
  //   name: 'Analytics',
  //   id: 'hotel',
  //   icon: Equalizer,
  //   component: Analytics,
  //   layout: '/admin'
  // },
  {
    path: '/surveys',
    name: 'Encuesta',
    id: 'hotel',
    icon: AssignmentTurnedIn,
    component: Survey,
    layout: '/admin',
  },
  {
    path: '/cleaning',
    name: 'Limpieza',
    id: 'hotel',
    icon: Hotel,
    component: Cleaning,
    layout: '/admin'
  },
  {
    path: '/maintenance',
    name: 'Mantenimiento',
    id: 'hotel',
    icon: AssignmentTurnedIn,
    component: Maintenance,
    layout: '/admin'
  },
  // {
  //   path: '/settings',
  //   name: 'Configuración',
  //   id: 'settings',
  //   icon: SettingsApplications,
  //   component: Settings,
  //   layout: '/admin',
  // }
]

