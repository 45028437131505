import firebase from '../../services/firebase'

const auth = firebase.auth()
const userAuth = firebase.firestore().collection('users')
const dbHotel = firebase.firestore().collection('hotels')

export const login = (email, password, history) => (dispatch, getState) => {

  dispatch({ type: 'LOGIN_STATUS', payload: { loading: true, error: '', errorPassword: false, errorEmail: false } })

  if (email === '' || !email.includes('@')) {
    dispatch({ type: 'LOGIN_STATUS', payload: { loading: false, errorEmail: true, error: 'Ingrese un Email' } })
  } else if (password === '') {
    dispatch({ type: 'LOGIN_STATUS', payload: { loading: false, errorPassword: true, error: 'Ingrese una Contraseña' } })
  } else {
    auth.signInWithEmailAndPassword(email, password)
      .then(resp => {
        userAuth.doc(resp.user.uid).get()
          .then(doc => {
            const data = doc.data()
            const { uid, hotel } = data
          
            if (hotel) {
              localStorage.setItem('hotel', hotel)
              let dataUser = { isLogin: 'active', uid, hotel }
              console.log("paso ?")
              
              dbHotel.doc(hotel.toString()).get()
                .then(doc => {
                  console.log("pase 1")
                  let snap = doc.data()
                  let onCreation = snap.onCreation
                  // console.log(snap)
                  if (!onCreation) {
                    const { email, settings, styles, license } = snap
                    let dataHotel = { email, hotel, settings, styles, onCreation, license }
                    dispatch({ type: 'SET_HOTEL', payload: dataHotel })
                    dispatch({ type: 'LOGIN_RESET' })
                    dispatch({ type: 'USER_DATA', payload: dataUser })
                    history.push(`/admin/dashboard`)
                  }
                  else {
                    const { email, settings, styles } = snap
                    let dataHotel = { email, hotel, settings, styles, onCreation }
                    dispatch({ type: 'SET_HOTEL', payload: dataHotel })
                    dispatch({ type: 'LOGIN_RESET' })
                    dispatch({ type: 'USER_DATA', payload: dataUser })

                    history.push(`/admin/creation`)
                  }
                })
            }
            else {
              dispatch({ type: 'LOGIN_RESET' })
              history.push(`/admin/creator`)
            }
          })
      })
      .catch(() => {
        dispatch({ type: 'LOGIN_STATUS', payload: { loading: false, errorPassword: true, error: 'El Email y/o Contraseña son Incorrectos' } })
      })
  }
}

export const isLogin = history => (dispatch, getState) => {

  auth.onAuthStateChanged(user => {
    if (user) {
      userAuth.doc(user.uid).get()
        .then(doc => {
          const data = doc.data()
          const { uid, hotel } = data
         
          if (hotel) {
            localStorage.setItem('hotel', hotel)
            let dataUser = { isLogin: 'active', uid, hotel }
            console.log( typeof hotel.toString())
            dbHotel.doc(hotel.toString()).get()
              .then(doc => {
                console.log("pase 2")
                let snap = doc.data()
                let onCreation = snap.onCreation
                if (!onCreation) {
                  const { email, settings, styles, license } = snap
                  let dataHotel = { email, hotel, settings, styles, onCreation, license }
                  dispatch({ type: 'SET_HOTEL', payload: dataHotel })
                  dispatch({ type: 'LOGIN_RESET' })
                  dispatch({ type: 'USER_DATA', payload: dataUser })
                  history.push(`/admin/dashboard`)
                }
                else {
                  const { email, settings, styles } = snap
                  let dataHotel = { email, hotel, settings, styles, onCreation }
                  dispatch({ type: 'SET_HOTEL', payload: dataHotel })
                  dispatch({ type: 'LOGIN_RESET' })
                  dispatch({ type: 'USER_DATA', payload: dataUser })
                  history.push(`/admin/creation`)
                }
              })
          }
          else {

            history.push(`/admin/creator`)
            dispatch({ type: 'SET_HOTEL', payload: "isCreator" })
          }
        }).catch(err => dispatch({ type: 'LOGIN_ALLRESET' }))
    } else {
      dispatch({ type: 'LOGIN_ALLRESET' })
      return false
    }
  })
}
