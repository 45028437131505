const initialState = {
  emergencies: [],
  emergency: []
}

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case 'SET_EMERGENCIES':
      return {
        ...state,
        emergencies: action.payload
      }

    case 'SET_EMERGENCY':
      return {
        ...state,
        emergency: action.payload
      }

    default:
      return state
  }
}
