import React, { useEffect, useRef } from 'react';
import PerfectScrollbar from 'perfect-scrollbar';
import { NavLink } from 'react-router-dom';
import cx from 'classnames';

import firebase from 'services/firebase';

// @material-ui/core components
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  Collapse,
  Icon
} from '@material-ui/core';

import withStyles from '@material-ui/core/styles/withStyles';
import sidebarStyle from 'assets/jss/material-dashboard-pro-react/components/sidebarStyle';

const db = firebase.firestore().collection('hotels');

let ps;

const SidebarWrapper = props => {
  useEffect(() => {
    if (navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(props.refs.sidebarWrapper, {
        suppressScrollX: true,
        suppressScrollY: false
      });
    }

    return () => navigator.platform.indexOf('Win') > -1 && ps.destroy();
  }, [props.refs]);

  const ref = useRef('sidebarWrapper');
  const { className, headerLinks, links } = props;

  return (
    <div className={className} ref={ref}>
      {headerLinks}
      {links}
    </div>
  );
};

class Sidebar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      openAvatar: false,
      miniActive: true,
      counter: 0,
      ...this.getCollapseStates(props.routes)
    };
  }
  componentDidMount() {
    db.doc(localStorage.getItem('hotel')).collection('rooms').onSnapshot(docs => {
      this.setState({ counter: 0 })
      docs.forEach(doc => {
        let data = doc.data()

        if (data.chat) {
          data.chat.forEach(msg => {
            if (msg.from === 'client' && msg.viewed === false) this.setState(prevState => ({ counter: prevState.counter + 1 }))
          })
        }
      })
    })
  }

  // this creates the intial state of this component based on the collapse routes
  // that it gets through this.props.routes
  getCollapseStates = routes => {
    let initialState = {};

    routes.map(prop => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: this.getCollapseInitialState(prop.views),
          ...this.getCollapseStates(prop.views),
          ...initialState
        };
      }
      return null;
    });
    return initialState;
  };
  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms - route /admin/regular-forms

  getCollapseInitialState(routes) {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && this.getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (window.location.href.indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  }

  // verifies if routeName is the one active (in browser input)
  activeRoute = routeName =>
    this.props.location.pathname.indexOf(routeName) > -1 ? 'active' : '';

  openCollapse(collapse) {
    let st = {};
    st[collapse] = !this.state[collapse];
    this.setState(st);
  }

  // this function creates the links and collapses that appear in the sidebar (left menu)
  createLinks = routes => {
    const { classes, miniActive } = this.props;

    return routes.map((prop, key) => {
      if (prop.redirect) return null;
      if (prop.hidden) return null;

      if (prop.collapse) {
        let st = {};
        st[prop['state']] = !this.state[prop.state];

        const navLinkClasses =
          classes.itemLink +
          ' ' +
          cx({
            [' ' + classes.collapseActive]: this.getCollapseInitialState(
              prop.views
            )
          });

        const itemText =
          classes.itemText +
          ' ' +
          cx({
            [classes.itemTextMini]: miniActive && this.state.miniActive
          });

        const collapseItemText =
          classes.collapseItemText +
          ' ' +
          cx({
            [classes.collapseItemTextMini]: miniActive && this.state.miniActive
          });

        const itemIcon = classes.itemIcon;
        const caret = classes.caret;
        const collapseItemMini = classes.collapseItemMini;

        return (
          <ListItem
            key={key}
            className={cx(
              { [classes.item]: prop.icon !== undefined },
              { [classes.collapseItem]: prop.icon === undefined }
            )}
          >
            <NavLink
              to={'#'}
              className={navLinkClasses}
              onClick={e => {
                e.preventDefault();
                this.setState(st);
              }}
            >
              {prop.icon !== undefined ? (
                typeof prop.icon === 'string' ? (
                  <Icon className={itemIcon}>{prop.icon}</Icon>
                ) : (
                  <prop.icon className={itemIcon} />
                )
              ) : (
                <span className={collapseItemMini}>{prop.mini}</span>
              )}
              <ListItemText
                primary={prop.name}
                secondary={
                  <b
                    className={
                      caret +
                      ' ' +
                      (this.state[prop.state] && classes.caretActive)
                    }
                  />
                }
                disableTypography={true}
                className={cx(
                  { [itemText]: prop.icon !== undefined },
                  { [collapseItemText]: prop.icon === undefined }
                )}
              />
            </NavLink>
            <Collapse in={this.state[prop.state]} unmountOnExit>
              <List className={classes.list + ' ' + classes.collapseList}>
                {this.createLinks(prop.views)}
              </List>
            </Collapse>
          </ListItem>
        );
      }

      const innerNavLinkClasses = classes.collapseItemLink;
      const collapseItemMini = classes.collapseItemMini;
      const navLinkClasses = classes.itemLink;
      const itemText =
        classes.itemText +
        ' ' +
        cx({ [classes.itemTextMini]: miniActive && this.state.miniActive });
      const collapseItemText =
        classes.collapseItemText +
        ' ' +
        cx({
          [classes.collapseItemTextMini]: miniActive && this.state.miniActive
        });
      const itemIcon = classes.itemIcon;

      return (
        <ListItem
          key={key}
          className={cx(
            { [classes.item]: prop.icon !== undefined },
            { [classes.collapseItem]: prop.icon === undefined }
          )}
        >
          <NavLink
            to={prop.layout + prop.path}
            className={cx(
              { [navLinkClasses]: prop.icon !== undefined },
              { [innerNavLinkClasses]: prop.icon === undefined }
            )}
            // Color de navlinks
            style={{
              userSelect: 'none',
              display: 'flex',
              alignItems: 'center',
              backgroundColor: this.activeRoute(prop.path)
                ? this.props.color
                : 'transparent',
              color: this.activeRoute(prop.path) ? '#fff' : '#3c4858'
            }}
          >
            {prop.icon !== undefined ? (
              typeof prop.icon === 'string' ? (
                <Icon className={itemIcon}>{prop.icon}</Icon>
              ) : (
                <prop.icon className={itemIcon} />
              )
            ) : (
              <span className={collapseItemMini}>{prop.mini}</span>
            )}
            <ListItemText
              primary={prop.name}
              disableTypography={true}
              className={cx(
                { [itemText]: prop.icon !== undefined },
                { [collapseItemText]: prop.icon === undefined }
              )}
            />
            {prop.name === 'Chat' && this.state.counter !== 0 ? (
              <div
                style={{
                  width: 20,
                  height: 20,
                  backgroundColor: '#ff5722',
                  borderRadius: '50%'
                }}
              />
            ) : null}
          </NavLink>
        </ListItem>
      );
    });
  };

  render() {
    const { classes, image, routes, bgColor, miniActive } = this.props;
    // console.log(bgColor);

    const links = (
      <List className={classes.list}>{this.createLinks(routes)}</List>
    );

    // const logoNormal = classes.logoNormal + ' ' + cx({[
    //   classes.logoNormalSidebarMini]: miniActive && this.state.miniActive
    // })

    // const logoMini = classes.logoMini
    const logoClasses =
      classes.logo +
      ' ' +
      cx({
        [classes.whiteAfter]: bgColor === 'white'
      });

    let brand = (
      <div
        className={logoClasses}
        style={{ userSelect: 'none', cursor: 'default' }}
      >
        {miniActive && this.state.miniActive ? (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <p
              style={{
                margin: 0,
                color: '#ff2068',
                fontFamily: 'Lato',
                fontWeight: 800,
                fontSize: 28
              }}
            >
              L
            </p>
            <p
              style={{
                margin: 0,
                color: '#000',
                fontFamily: 'Lato',
                fontWeight: 800,
                fontSize: 28
              }}
            >
              H
            </p>
          </div>
        ) : (
          // : <img src={logo} alt='logo' style={{ width: 200 }} />
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <p
              style={{
                margin: 0,
                color: '#ff2068',
                fontFamily: 'Lato',
                fontWeight: 800,
                fontSize: 28
              }}
            >
              LOVABLE
            </p>
            <p
              style={{
                margin: 0,
                color: '#000',
                fontFamily: 'Lato',
                fontWeight: 800,
                fontSize: 28
              }}
            >
              Hotels
            </p>
          </div>
        )}
      </div>
    );

    const drawerPaper =
      classes.drawerPaper +
      ' ' +
      cx({ [classes.drawerPaperMini]: miniActive && this.state.miniActive });

    const sidebarWrapper =
      classes.sidebarWrapper +
      ' ' +
      cx({
        [classes.drawerPaperMini]: miniActive && this.state.miniActive,
        [classes.sidebarWrapperWithPerfectScrollbar]:
          navigator.platform.indexOf('Win') > -1
      });

    return (
      <Drawer
        style={{
          width: miniActive ? 80 : 260,
          transitionDuration: '.2s, .2s, .35s',
          transitionProperty: 'top, bottom, width',
          transitionTimingFunction: 'linear, linear, ease'
        }}
        variant="permanent"
        open
        classes={{ paper: drawerPaper + ' ' + classes[bgColor + 'Background'] }}
      >
        {brand}
        <SidebarWrapper className={sidebarWrapper} links={links} />
        {image !== undefined ? <div className={classes.background} /> : image}
      </Drawer>
    );
  }
}

export default withStyles(sidebarStyle)(Sidebar);
