import React from "react"
import cx from "classnames"

// @material-ui/core components
import {
  AppBar,
  Toolbar,
} from "@material-ui/core"

// core components
import Button from "components/CustomButtons/Button"

import withStyles from "@material-ui/core/styles/withStyles"
import authNavbarStyle from "assets/jss/material-dashboard-pro-react/components/authNavbarStyle"

export default withStyles(authNavbarStyle)(props => {

  const { classes, color } = props
  const appBarClasses = cx({[" " + classes[color]]: color})

  return (
    <AppBar position="static" className={classes.appBar + appBarClasses}>
      <Toolbar className={classes.container}>
          <div className={classes.flex} style={{display: 'flex', justifyContent: 'center'}}>
            <Button href="#" className={classes.title} color="transparent">
              <p style={{ fontSize: 24, fontWeight: 900 }}>LOVABLE</p>
            </Button>
          </div>
      </Toolbar>
    </AppBar>
  )
})
