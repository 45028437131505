import Background from '../../../assets/img/faena.svg'

export default {
  root: {
    position: 'fixed',
    bottom: 0,
    top: 0,
    left: 0,
    right: 0,
    display: 'flex',
    flexDirection: 'column',
    backgroundImage: `url(${Background})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  containTitle: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    color: '#fff'
  },
  containProfiles: {
    flex: 3,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  profileBox: {
    width: '40%',
    height: 100,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  profileIcon: {
    fontSize: 60,
    color: '#fff',
  },
  profileName: {
    margin: 0,
    fontSize: 24,
    color: '#fff',
  },
}