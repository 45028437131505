const initialState = {
  services: [],
  alarm: [],
  chatApp: [],
  clean: [],
  events: [],
  chat: [],
  disturb: [],
  emergency: [],
  reservation: [],
  survey: [],
  food: [],
  information: [],
  maintanence: [],
  map: [],
  medic: [],
  ski: [],
  towels: []
}

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case 'SET_SERVICES':
      return {
        ...state,
        services: action.payload
      }
    
    case 'SET_SERVICE':
      return {
        ...state,
        [action.payload.type]: action.payload.data
      }

    default:
      return state
  }
}
