import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Button from '../components/button'
import { getService } from 'redux/actions/webappServices'

export default ({ room }) => {
    const service = useSelector(state => state.services.food)
    const dispatch = useDispatch()
    const history = useHistory()
    const hotelId = localStorage.getItem('hotel')

    useEffect(() => dispatch(getService(hotelId, room, 'food')), [dispatch, hotelId, room])

    return (
        <Button
            onClick={() => history.push(`/${hotelId}/app/${room}/menu`)}
            service={service}
        />
    )
}
