const initialState = {
	rooms: [],
	room: []
}


export default (state = initialState, { type, payload }) => {

	switch (type) {
		case 'GET_ROOMS':
			return {
				...state,
				rooms: payload
			}
		case 'GET_ROOM':
			return {
				...state,
				room: payload
			}

		default:
			return state
	}
}