import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import styles from './styles'
import firebase from 'firebase'

const DB_HOTEL = firebase.firestore().collection('hotels')

export default props => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [room, setRoom] = useState('')
  const [checkinCode, setCheckinCode] = useState('')
  const [license, setLicense] = useState('')
  const { hotel } = props.match.params

  useEffect(() => {
    (async () => {
      let hotelData = await DB_HOTEL.doc(hotel).get()
      setLicense(hotelData.data().license)
    })()
  })

  const handleIn = async () => {
    let DB_ROOM = DB_HOTEL.doc(hotel).collection('rooms').doc(room)
    let DB_CHECKIN = DB_HOTEL.doc(hotel).collection('checkIn').doc(checkinCode)

    try {
      let room = await DB_ROOM.get()
      let dataRoom = room.data()

      if (room.exists && dataRoom.roomCode === checkinCode) {
        localStorage.setItem('room', room.id)
        localStorage.setItem('code', checkinCode)

        if(license !== 'lite') {
          let checkinData = (await DB_CHECKIN.get()).data()
          let profiles = checkinData.companions

          profiles.push({ name: checkinData.name, isChild: false })
          dispatch({ type: 'SET_PROFILES', payload: { profiles }})

          // if(profiles.length === 1) return history.push(`/${hotel}/app/home`)
          return history.push(`/${hotel}/app/profile`)
        }
        return history.push(`/${hotel}/app/profile`)
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div style={styles.root}>
      <div style={styles.container}>
        <div style={styles.containInputs}>
          <label htmlFor="room" style={styles.refInputs}>
            Número de Habitación
          </label>
          <input
            id="room"
            style={styles.inputs}
            type="text"
            onChange={({ target }) => setRoom(target.value)}
          />
          <label htmlFor="code" style={styles.refInputs}>
            Código de Check In
          </label>
          <input
            id="code"
            style={styles.inputs}
            type="text"
            onChange={({ target }) => setCheckinCode(target.value)}
          />
        </div>
        <div style={styles.containButton}>
          <button style={styles.button} onClick={() => handleIn(room, checkinCode)}>
            INGRESAR
          </button>
        </div>
      </div>
    </div>
  )
}