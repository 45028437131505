const initialState = {
  cleanUsers: [],
  edit: false,
  firstname: '',
  lastname: '',
  email: '',
  userId: '',
  updateCleanRoom: null,
  updateRestRoom: null,
  updateRequest: null,
  updateDontdisturb: null
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case 'UPDATE_CLEANING':
      return { ...state, ...payload }

    default:
      return state
  }
}
