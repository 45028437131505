import React, { Component } from "react";
import { connect } from "react-redux";
import "./index.css";
import styles from "./styles";

import Bubble from "components/Chat/Bubble";
import Card from "components/Card/Card";
import GridContainer from "components/Grid/GridContainer";

import userIcon from "assets/img/user.svg";
import Search from "@material-ui/icons/Search";
import withStyles from "@material-ui/core/styles/withStyles";
import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";

import moment from "moment";
import admin from "firebase/app";

const DB_HOTEL = admin.firestore().collection("hotels");

class Chat extends Component {
  constructor(props) {
    super(props);

    this.state = {
      messages: [],
      message: "",
      chats: [],
      room: "",
      name: ""
    };
  }

  componentDidMount() {
    const { hotelId, license } = this.props;

    this.getChats(hotelId, license);
  }

  componentDidUpdate(prevState) {
    const { messages } = this.state;
    let elementChat = document.getElementById("chat");

    if (messages.length > 0 && messages !== prevState.messages && elementChat)
      elementChat.scrollTop = elementChat.scrollHeight;
  }

  getChats(id) {
    DB_HOTEL.doc(id)
      .collection("chat")
      .orderBy("lastMessageTime", "asc")
      .onSnapshot(snap => {
        snap.docChanges().forEach(change => {
          let chat = change.doc.data();
          switch (change.type) {
            case "added":
              this.setState(({ chats }) => ({ chats: [...chats, chat] }));
              return true;
            case "modified":
              let newChats = this.state.chats.map(e =>
                e.room === chat.room ? chat : e
              );

              newChats.sort((a, b) =>
                a.lastMessageTime.toDate() > b.lastMessageTime.toDate() ? -1 : 1
              );

              if (
                chat.room === this.state.room &&
                chat.profile === this.state.name
              ) {
                let toViewed = chat.messages.map(e =>
                  !e.viewed ? { ...e, viewed: true } : e
                );
                // console.log(change.doc.id)
                DB_HOTEL.doc(id)
                  .collection("chat")
                  .doc(change.doc.id)
                  .update({
                    messages: toViewed
                  });
                this.setState({ messages: chat.messages });
              }
              return this.setState({ chats: newChats });
            default:
              break;
          }
        });
      });
  }

  // toViewed(messages) {
  //   messages
  // }

  async handleChat(room, name) {
    const { hotelId } = this.props;
    let filterChat = this.state.chats.filter(
      e => e.room === room && e.profile === name
    );

    this.setState({
      messages: filterChat[0].messages,
      room,
      name
    });

    let toViewed = filterChat[0].messages.map(e =>
      !e.viewed ? { ...e, viewed: true } : e
    );
    let id = (
      await DB_HOTEL.doc(hotelId)
        .collection("chat")
        .where("room", "==", room)
        .where("profile", "==", name)
        .get()
    ).docs[0].id;
    DB_HOTEL.doc(hotelId)
      .collection("chat")
      .doc(id)
      .update({
        messages: toViewed
      });
  }

  handleChangeMessage({ target }) {
    this.setState({ message: target.value });
  }

  async sendMessage() {
    const { room, message, name } = this.state;
    const { hotelId } = this.props;
    const date = moment()["_d"];
    let ID_CHAT = (
      await DB_HOTEL.doc(hotelId)
        .collection("chat")
        .where("room", "==", room)
        .where("profile", "==", name)
        .get()
    ).docs[0].id;
    let DB_CHAT = DB_HOTEL.doc(hotelId)
      .collection("chat")
      .doc(ID_CHAT);

    DB_CHAT.update({
      lastMessageTime: date,
      messages: admin.firestore.FieldValue.arrayUnion({
        from: "hotel",
        message,
        date,
        viewed: false
      })
    });

    this.setState({ message: "" });
  }

  render() {
    const { message, messages, chats, room, name } = this.state;
    const { classes } = this.props;
    return (
      <GridContainer justify='center' style={{ height: '70vh', padding: '0 50px' }}>
        <Card className={classes.card} style={{ height: '85%', maxHeight: '70vh' }}>
          <div style={styles.rootDiv}>
            <div style={styles.sidebar}>
              <div style={styles.sidebarTop}>
                <Search />
                <input
                  type="text"
                  placeholder="Buscar..."
                  style={styles.searchInput}
                />
              </div>
              <div style={styles.sidebarBot}>
                {chats.length > 0 &&
                  chats.map((chat, key) => {
                    return (
                      <div
                        key={key}
                        style={{
                          ...styles.chatList,
                          background:
                            room === chat.room && name === chat.profile
                              ? "#dedede"
                              : "#fff"
                        }}
                        onClick={() => this.handleChat(chat.room, chat.profile)}
                      >
                        <div
                          style={{
                            width: "20%",
                            display: "flex",
                            justifyContent: "center"
                          }}
                        >
                          <div style={styles.containIconChat}>
                            <img
                              alt="Usuario"
                              src={userIcon}
                              style={{ width: 28 }}
                            />
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "80%",
                            paddingLeft: 20,
                            justifyContent: "space-between"
                          }}
                        >
                          <p style={styles.chatName}>
                            {
                              chat.room ? (
                                `Habitación ${chat.room} ${chat.profile ? chat.profile : ''}`
                              ) : (
                                  'Chat from chatbot'
                                )
                            }

                          </p>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
            <div style={styles.containRightChat}>
              {
                messages.length > 0 && (
                  <React.Fragment>
                    <div style={styles.containBubbles} id='chat'>
                      {
                        messages.map((msg, key) => {
                          return (
                            (msg.from === 'hotel' || msg.from === 'bot') ?
                              <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }} key={key}>
                                <Bubble
                                  arrow={(key !== 0 && messages[key - 1].from === msg.from) ? false : true}
                                  color='#2196f3'
                                  from='you'
                                  msg={msg.message}
                                  time={moment(msg.date.toDate()).format('LT')}
                                />
                              </div>
                              :
                              <div style={{ width: '100%', display: 'flex' }} key={key}>
                                <Bubble
                                  arrow={(key !== 0 && messages[key - 1].from === msg.from) ? false : true}
                                  color='#c1c0c0'
                                  msg={msg.message}
                                  time={moment(msg.date.toDate()).format('LT')}
                                />
                              </div>
                          )
                        })
                      }
                      value={message}
                      />
                  <button
                        style={{
                          ...styles.sendButtonMessage,
                          backgroundColor:
                            message.length === 0 ? "#9e9e9e" : "#3aadff"
                        }}
                        disabled={message.length === 0 ? true : false}
                        onClick={() => this.sendMessage()}
                      >
                        <p style={styles.textSendButtonMessage}>ENVIAR</p>
                      </button>
                    </div>
                  </React.Fragment>
                )}
            </div>
          </div>
        </Card>
      </GridContainer >
    );
  }
}

const mapStateToProps = state => ({
  ...state,
  license: state.hotel.hotelInfo.license,
  hotelId: state.hotel.hotelInfo.hotel
});

export default connect(mapStateToProps)(withStyles(dashboardStyle)(Chat));
