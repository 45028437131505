import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
// @material-ui/icons
import Face from '@material-ui/icons/Face'
import RecordVoiceOver from '@material-ui/icons/RecordVoiceOver'

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import { InputAdornment } from '@material-ui/core'

// core components
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import CustomInput from 'components/CustomInput/CustomInput.js'
import formStyles from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle'

const style = {
  ...formStyles,
  infoText: {
    fontWeight: '300',
    margin: '10px 0 30px',
    textAlign: 'center'
  },
  inputAdornmentIcon: {
    color: '#555'
  },
  inputAdornment: {
    position: 'relative'
  }
}

class Tasks extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      titleState: '',
      descriptionState: '',
      roomState: ''
    }
  }

  componentDidMount() {
    this.props.room && this.setState({ roomState: 'success' })
    this.props.title && this.setState({ titleState: 'success' })
    this.props.description && this.setState({ descriptionState: 'success' })
  }

  componentDidUpdate(prevProps, prevState) {
    const { title, description } = this.props
    if (this.props.edit !== prevProps.edit) {
      if (title !== '') {
        this.setState({ titleState: this.verifyLength(title, 3) ? 'success' : 'error' })
      }
      if (description !== '') {
        this.setState({ descriptionState: this.verifyLength(description, 3) ? 'success' : 'error' })
      }
      this.props.edit === null && this.setState({ titleState: '', descriptionState: '' })
    }
  }
  sendState() {
    return this.state
  }
  // function that verifies if a string has a given length or not
  verifyLength(value, length) {
    if (value.length >= length) {
      return true
    }
    return false
  }

  verifyRoom(value) {
    return this.props.rooms.filter(room => room.roomNumber === value).length
  }

  change(event, stateName, type, stateNameEqualTo) {
    switch (type) {
      case 'length':
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({ [stateName + 'State']: 'success' })
        } else {
          this.setState({ [stateName + 'State']: 'error' })
        }
        break
      case 'room':
        if (this.verifyRoom(event.target.value)) {
          this.setState({ roomState: 'success' })
        } else {
          this.setState({ roomState: 'error' })
        }
        break
      default:
        break
    }
    this.props.dispatch({ type: 'UPDATE_TASKS', payload: { [stateName]: event.target.value } })
    // this.setState({ [stateName]: event.target.value })
  }
  isValidated() {
    if (
      this.state.titleState === 'success' &&
      this.state.descriptionState === 'success' &&
      this.state.roomState === 'success'
    ) {
      this.setState({ titleState: '', descriptionState: '', roomState: '' })
      return true
    } else {
      if (this.state.titleState !== 'success') {
        this.setState({ titleState: 'error' })
      }
      if (this.state.descriptionState !== 'success') {
        this.setState({ descriptionState: 'error' })
      }
      if (this.state.roomState !== 'success') {
        this.setState({ roomState: 'error' })
      }
    }
    return false
  }
  render() {
    const { classes, title, description, room } = this.props
    return (
      <GridContainer justify='center'>
        <GridItem xs={12} sm={12}>
          <h4 className={classes.infoText}>
            Complete la información básica de la tarea
          </h4>
        </GridItem>
        <GridItem xs={12} sm={8}>
          <CustomInput
            success={this.state.roomState === 'success'}
            error={this.state.roomState === 'error'}
            labelText={
              <span>
                Número de habitación<small></small>
              </span>
            }
            id='room'
            value={room}
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              onChange: event => this.change(event, 'room', 'room'),
              endAdornment: (
                <InputAdornment
                  position='end'
                  className={classes.inputAdornment}
                >
                  <Face className={classes.inputAdornmentIcon} />
                </InputAdornment>
              )
            }}
          />
          <CustomInput
            success={this.state.titleState === 'success'}
            error={this.state.titleState === 'error'}
            labelText={
              <span>
                Título <small>(requerido)</small>
              </span>
            }
            id='title'
            value={title}
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              onChange: event => this.change(event, 'title', 'length', 3),
              endAdornment: (
                <InputAdornment
                  position='end'
                  className={classes.inputAdornment}
                >
                  <Face className={classes.inputAdornmentIcon} />
                </InputAdornment>
              )
            }}
          />
          <CustomInput
            success={this.state.descriptionState === 'success'}
            error={this.state.descriptionState === 'error'}
            labelText={
              <span>
                Descripción <small>(requerido)</small>
              </span>
            }
            id='desc'
            value={description}
            multiline
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              onChange: event => this.change(event, 'description', 'length', 3),
              endAdornment: (
                <InputAdornment
                  position='end'
                  className={classes.inputAdornment}
                >
                  <RecordVoiceOver className={classes.inputAdornmentIcon} />
                </InputAdornment>
              )
            }}
          />
        </GridItem>
      </GridContainer>
    )
  }
}

Tasks.propTypes = {
  classes: PropTypes.object
}

const mapStateToProps = (state) => ({
  room: state.tasks.room,
  editTask: state.tasks.editTask,
  title: state.tasks.title,
  description: state.tasks.description,
  rooms: state.rooms.rooms,
})

export default connect(mapStateToProps)(withStyles(style)(Tasks))