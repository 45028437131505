const styles = {
  container: {
    justifyContent: 'center',
  },
  rootDiv: {
    height: '100%',
    display: 'flex',
    borderRadius: 6,
    userSelect: 'none',
    cursor: 'default',
    fontFamily: 'Lato',
  },
  sidebar: {
    display: 'flex',
    flexDirection: 'column',
    width: '30%',
  },
  sidebarTop: {
    height: 50,
    borderTopLeftRadius: 6,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 10,
  },
  searchInput: {
    border: 'none',
    width: '90%',
    marginLeft: 10,
    fontSize: 16,
  },
  sidebarBot: {
    height: '100%',
    borderTop: '1px solid #caacac',
    display: 'flex',
    flexDirection: 'column',
  },
  chatList: {
    display: 'flex',
    padding: '20px 6px',
    cursor: 'pointer',
  },
  containIconChat: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 50,
    height: 50,
    backgroundColor: '#9e9e9e',
    borderRadius: '50%',
  },
  chatName: {
    fontSize: 18,
    fontWeight: 700,
    color: '#544a4a',
    margin: 0,
  },
  lastMessageView: {
    maxWidth: '60%',
    fontSize: 12,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    color: '#898888',
    margin: 0,
  },
  containRightChat: {
    width: '70%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#dedede',
  },
  containBubbles: {
    width: '100%',
    overflowY: 'scroll',
    overflowX: 'hidden',
    height: 'calc(100% - 50px)',
    paddingTop: 20,
  },
  inputTypeMessage: {
    width: '90%',
    height: '100%',
    border: 'none',
    margin: 0,
    paddingLeft: 25,
    fontSize: 14,
    borderLeft: '1px solid rgb(222, 222, 222)',
    fontFamily: 'Lato',
  },
  sendButtonMessage: {
    height: '100%',
    width: '10%',
    border: 'none',
    margin: 0,
    borderBottomRightRadius: 6,
  },
  textSendButtonMessage: {
    color: '#fff',
    margin: 0,
    fontFamily: 'Lato',
    fontWeight: 600,
  }
}

export default styles;