import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import classNames from "classnames";
import firebase from "services/firebase";
import { getNotifications } from "../../redux/actions/notifications";
import { removeService } from "../../redux/actions/services";

import notificationSound from "../../assets/sounds/Popcorn.ogg";

import Snackbar from "components/Snackbar/Snackbar";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import {
  MenuItem,
  MenuList,
  ClickAwayListener,
  Paper,
  Grow,
  Hidden,
  Popper,
  Fade
} from "@material-ui/core";

// @material-ui/icons
import Person from "@material-ui/icons/Person";
import Notifications from "@material-ui/icons/Notifications";
import SweetAlert from "react-bootstrap-sweetalert";
// core components
import Button from "components/CustomButtons/Button";

import adminNavbarLinksStyle from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle";

const auth = firebase.auth();

export default withStyles(adminNavbarLinksStyle)(props => {
  const history = useHistory();

  const dispatch = useDispatch();
  const notifications = useSelector(state => state.notifications);
  const emergencies = useSelector(state => state.emergency.emergencies);
  const [anchorEl, setAnchorEl] = useState(null);
  const [placement, setPlacement] = useState();
  const [notification, handleNotification] = useState(false);
  const [profile, handleProfile] = useState(false);
  const [alert, setAlert] = useState([]);
  const hotelId = localStorage.getItem("hotel");
  let emergencyAlert = false;

  const alertRef = useRef(alert)

  useEffect(() => {
 
    hotelId && dispatch(getNotifications(hotelId));

    let audio = document.getElementById("notification-sound");

    firebase
      .firestore()
      .collection("hotels")
      .doc(hotelId)
      .collection("notifications")
      .onSnapshot(snapshot =>
        snapshot.docChanges().forEach(change => {
          if (change.type === "added") {
            let data = change.doc.data();
            if (data.type === "message") {
              setAlert([...alertRef.current, { message: data.data.message }]);
              audio.play();

              setTimeout(() => {
                firebase
                  .firestore()
                  .collection("hotels")
                  .doc(hotelId)
                  .collection("notifications")
                  .doc(change.doc.id)
                  .delete()
                  .then(() => console.log("succes remove"))
                  .catch(() => console.log("error remove"));
              }, 3000);
            }
          }

          if (change.type === "removed") {
            let data = change.doc.data();

            if (data.type === "message") {
              let auxAlert = alertRef.current;
              let index = auxAlert.indexOf({ message: data.data.message });
              auxAlert.slice(index, 1);
              setAlert(auxAlert);
            }
          }
        })
      );

    return () => false;
  }, [dispatch, hotelId]);

  const endSesion = () => {
    // const creator = auth.currentUser.uid === "g5D0rFFU35Y4AC1sk62H1NLqSAD2";

    auth
      .signOut()
      .then(() => {
        dispatch({
          type: "USER_DATA",
          payload: { isLogin: "checking", hotel: null, uid: "" }
        });

        history.push(`/auth`);
      })
      .catch(() => console.log("no se pudo cerrar sesión"));
  };

  const handleOpen = (newPlacement, type = null) => event => {
    setAnchorEl(event.currentTarget);
    type === "profile"
      ? handleProfile(prev => placement !== newPlacement || !prev)
      : handleNotification(prev => placement !== newPlacement || !prev);
    setPlacement(newPlacement);
  };

  const handleClose = (type = null) =>
    type === "profile" ? handleProfile(false) : handleNotification(false);

  const { classes } = props;

  const dropdownItem = classNames(classes.dropdownItem, classes.primaryHover);
  const managerClasses = classNames({ [classes.managerClasses]: true });
  if (emergencies.length !== 0)
    emergencyAlert = (
      <SweetAlert
        style={{ display: "block"}}
        title="Urgencia!"
        showConfirm={false}
      >
        {`${
          emergencies.length > 1
            ? "Emergecias para las habitaciones "
            : "Emergencia  para la habitación "
        }

${emergencies.map(emergency => `${emergency.room}`)}.`}
        {<p> LLamar a emergencias para : </p>}
        {emergencies.map(emergency => {
          return (
            <div>
              <Button
                style={{ marginTop: 18, marginLeft: 28 }}
                onClick={() => {
                  console.log(emergency);
                  dispatch(
                    removeService(
                      localStorage.getItem("hotel"),
                      emergency.room.toString(),
                      "Emergencia"
                    )
                  );
                  if (emergencies.length === 1) emergencyAlert = false;
                }}
                color="rose"
              >{`${emergency.room}`}</Button>
            </div>
          );
        })}
      </SweetAlert>
    );
  return (
    <div>
      <div className={managerClasses}>
        <Button
          color="transparent"
          justIcon
          aria-label="Notifications"
          aria-owns={notification ? "menu-list" : null}
          aria-haspopup="true"
          onClick={handleOpen("bottom-end")}
          className={classes.buttonLink}
        >
          <Notifications
            className={`${classes.headerLinksSvg} ${classes.links}`}
          />
          {notifications.notifications.length >= 1 ? (
            <div>
              <span className={classes.notifications}>
                {notifications.notifications.length}
              </span>
              <Hidden mdUp implementation="css">
                <span
                  onClick={handleOpen("bottom-end")}
                  className={classes.linkText}
                >
                  Notification
                </span>
              </Hidden>
            </div>
          ) : (
            ""
          )}
          {/* Notificaciones */}
        </Button>
        <Popper
          open={notification}
          transition
          anchorEl={anchorEl}
          disablePortal
          placement={placement}
          className={classNames({
            [classes.popperClose]: !notification,
            [classes.pooperResponsive]: true,
            [classes.pooperNav]: true
          })}
        >
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
              id="menu-list"
              style={{ transformOrigin: "0 0 0" }}
            >
              <Fade {...TransitionProps} timeout={350}>
                <Paper className={classes.dropdown}>
                  <ClickAwayListener onClickAway={() => handleClose()}>
                    <MenuList role="menu">
                      {notifications.notifications.length >= 1 ? (
                        notifications.notifications.map(notification => {
                          return (
                            <MenuItem onClick={() => this.handleClose()}>
                              {`La Habitación ${notification.room} ha solicitado ${notification.service}`}
                            </MenuItem>
                          );
                        })
                      ) : (
                        <MenuItem onClick={() => this.handleClose()}>
                          {`No hay ninguna Orden `}
                        </MenuItem>
                      )}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Fade>
            </Grow>
          )}
        </Popper>
      </div>
      <ClickAwayListener onClickAway={() => handleClose("profile")}>
        <Button
          color="transparent"
          justIcon
          aria-label="Person"
          aria-owns={profile ? "menu-list" : null}
          aria-haspopup="true"
          onClick={handleOpen("bottom-end", "profile")}
          className={classes.buttonLink}
        >
          <Person className={`${classes.headerLinksSvg} ${classes.links}`} />
          <Hidden mdUp implementation="css">
            <span
              className={classes.linkText}
              onClick={handleOpen("bottom-end", "profile")}
            >
              {" "}
              Profile{" "}
            </span>
          </Hidden>
        </Button>
      </ClickAwayListener>
      <Popper
        open={profile}
        anchorEl={anchorEl}
        transition
        disablePortal
        placement="bottom"
        className={classNames({
          [classes.popperClose]: !profile,
          [classes.pooperResponsive]: true,
          [classes.pooperNav]: true
        })}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            id="menu-list"
            style={{ transformOrigin: "0 0 0" }}
          >
            <Paper className={classes.dropdown}>
              <MenuList role="menu">
                <MenuItem onClick={endSesion} className={dropdownItem}>
                  Cerrar Sesión
                </MenuItem>
              </MenuList>
            </Paper>
          </Grow>
        )}
      </Popper>
      <div
        style={{
          position: "fixed",
          maxHeight: 430,
          bottom: 55,
          right: 25,
          paddingTop: 15,
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
          overflow: "hidden"
        }}
      >
        {alert.length !== 0 &&
          alert.map((a, key) => {
            return (
              <Snackbar
                key={key}
                place="br"
                color="info"
                message={a.message}
                open={true}
                closeNotification={() =>
                  setAlert(alert.filter((b, k) => k !== key))
                }
                close
                test="fixNavbar"
              />
            );
          })}
      </div>
      <audio
        controls
        style={{ position: "absolute", display: "none" }}
        id="notification-sound"
      >
        <source src={notificationSound} type="audio/ogg" />
      </audio>
      {emergencyAlert}
    </div>
  );
});
