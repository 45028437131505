import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Button from '../components/button'
import { getEmergencies, changeStatus } from 'redux/actions/webappServices'
import SweetAlert from 'react-bootstrap-sweetalert'

export default ({ classes, room }) => {
  const dispatch = useDispatch()
  const service = useSelector(state => state.services.emergency)
  const [alert, setAlert] = useState(null)
  const hotelId = localStorage.getItem('hotel')

  useEffect(() => dispatch(getEmergencies(hotelId, room, 'emergency')), [room, dispatch, hotelId])

  const { active, name, key } = service
  const item = bool => {
    return (
      <SweetAlert
        warning
        title={
          bool
            ? `Nos alegramos que se  haya podido resolver la emergencia`
            : `Realmente necesitas una Emergencia?`
        }
        onConfirm={() => {
          changeStatus(
            hotelId,
            room,
            active === 'pending' ? 'Emergencia en Curso' : name,
            key
          )
          setAlert(null)
        }}
        onCancel={() => setAlert(null)}
        confirmBtnCssClass={`${classes.button} ${classes.success}`}
        cancelBtnCssClass={`${classes.button} ${classes.danger}`}
        confirmBtnText="CONFIRMAR"
        cancelBtnText="CANCELAR"
        showCancel
      />
    )
  }
  return (
    <div>
      <Button onClick={() => setAlert(item(active))} service={service} />
      {alert}
    </div>
  )
}
