import React from "react";
import { connect } from "react-redux";

// Core Components
import GridItem from "../../../components/Grid/GridItem";
import GridContainer from "../../../components/Grid/GridContainer";
import CustomInput from "../../../components/CustomInput/CustomInput";

// Styles
import withStyles from "@material-ui/core/styles/withStyles";
import dashboardStyle from "../../../assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import customSelectStyle from "../../../assets/jss/material-dashboard-pro-react/customSelectStyle";
import NavPills from "components/NavPills/NavPills";

const styles = {
  ...dashboardStyle,
  ...customSelectStyle,
  root: {
    height: "max-content"
  }
};

class Campaign extends React.Component {
  isValidated = () =>
    this.props.option === this.props.templates.length
      ? this.props.altText.length
      : this.props.text.length;

  handleInput = e => {
    e.target.value.length <= 160 &&
      this.props.dispatch({
        type: "UPDATE_FORM",
        payload: { text: e.target.value }
      });
  };

  changeText = active => {
    this.props.dispatch({ type: "UPDATE_FORM", payload: { option: active } });
    this.props.templates.length > active &&
      this.props.dispatch({
        type: "UPDATE_FORM",
        payload: { text: this.props.templates[active].text }
      });
  };

  render() {
    const { classes, text, templates, option, dispatch, altText } = this.props;
    console.log(altText,text);
    return (
      <GridContainer justify="center" className={classes.root}>
        {templates.length > 0 && (
          <GridItem style={{ width: "60%", minWidth: 400 }}>
            <legend style={{textAlign: 'center'}}>Genere un mensaje para sus destinatarios</legend>
            <NavPills
              color="rose"
              alignCenter
              active={templates.length}
              campaigns={true}
              changeText={this.changeText}
              tabs={[
                ...templates.map((template, i) => {
                  return {
                    tabButton: `Template ${i + 1}`,
                    tabContent: (
                      <div>
                        <CustomInput
                          labelText={template.title}
                          formControlProps={{
                            fullWidth: true
                          }}
                          multiline
                          type="text"
                          onChange={e => this.handleInput(e)}
                          value={text}
                        />
                      </div>
                    )
                  };
                }),
                {
                  tabButton: "Personalizado",
                  tabContent: (
                    <div>
                      <CustomInput
                        labelText="Personalizado"
                        formControlProps={{
                          fullWidth: true
                        }}
                        type="text"
                        onChange={e =>
                          dispatch({
                            type: "UPDATE_FORM",
                            payload: { altText: e.target.value }
                          })
                        }
                        value={altText}
                      />
                    </div>
                  )
                }
              ]}
            />
            <p style={{ textAlign: "center" }}>
              Caracteres:{" "}
              {option === templates.length
                ? 160 - altText.length
                : 160 - text.length}
            </p>
          </GridItem>
        )}
      </GridContainer>
    );
  }
}

const mapStateToProps = state => ({
  text: state.campaigns.text,
  altText: state.campaigns.altText,
  templates: state.campaigns.templates,
  templateSelected: state.campaigns.templateSelected,
  option: state.campaigns.option
});

export default connect(mapStateToProps)(withStyles(styles)(Campaign));
