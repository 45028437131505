import admin from 'firebase/app'

const hotelDB = admin.firestore().collection('hotels')

export const getService = (hotelId, room, serviceKey) => dispatch => {
  let serviceDB = hotelDB.doc(hotelId).collection('services').doc(serviceKey)

  serviceDB.onSnapshot(snap => {
    let service = snap.data()
    let data = {
      status: service.status,
      image: service.rooms[room]
        ? service.imageActive
        : service.image,
      labelTop: service.labelTop,
      labelBot: service.labelBot,
      name: service.name,
      key: service.key
    }

    dispatch({ type: 'SET_SERVICE', payload: { type: serviceKey, data }})
  })
}

export const changeStatus = (id, room, name, key) => {
  let serviceDB = hotelDB.doc(id).collection('services').doc(key)
  let roomsDB = hotelDB.doc(id).collection('rooms').doc(room)

  serviceDB.get()
    .then(service => {
      let auxService = service.data().rooms
      let bool = false

      if (auxService[room]) delete auxService[room]
      else {
        auxService[room] = 'active'
        bool = true
      }

      return [auxService, bool]
    })
    .then(rooms => {
      serviceDB.update({ rooms: rooms[0] })
      roomsDB.update({
        services: rooms[1]
          ? admin.firestore.FieldValue.arrayUnion(name)
          : admin.firestore.FieldValue.arrayRemove(name)
      })
    })
}

export const getClean = (hotelId, room, serviceKey) => dispatch => {
  let serviceDB = hotelDB.doc(hotelId).collection('services').doc(serviceKey)
  
  serviceDB.onSnapshot(snap => {
    let service = snap.data()
    let data = {
      status: service.status,
      image: service.rooms[room]
        ? service.imageActive
        : service.image,
      labelTop: service.labelTop,
      labelBot: service.labelBot,
      name: service.name,
      onUse: !!service.rooms[room],
      key: service.key
    }
    console.log(data)
    dispatch({ type: 'SET_SERVICE', payload: { type: serviceKey, data }})
  })
}

export const changeCleanStatus = (id, room, service, key) => {
  let serviceDB = hotelDB.doc(id).collection('services').doc(key)
  let roomsDB = hotelDB.doc(id).collection('rooms').doc(room)
  let date = new Date();

  serviceDB.get().then(service => {
    let newService = service.data().rooms
    let bool = false

    if (newService[room]) delete newService[room]
    else {
      newService[room] = { status: 'active', orderAt: date }
      bool = true
    }
    return [newService, bool]
  })
  .then(rooms => {
    serviceDB.update({ rooms: rooms[0] })
    roomsDB.update({
        services: rooms[1]
          ? admin.firestore.FieldValue.arrayUnion(service)
          : admin.firestore.FieldValue.arrayRemove(service)
      })
  })
}

export const getEmergencies = (hotelId, room, serviceKey) => dispatch => {
  let serviceDB = hotelDB.doc(hotelId).collection('services').doc(serviceKey)

  serviceDB.onSnapshot(snap => {
    let service = snap.data()
    let data = {
      status: service.status,
      image: service.rooms[room]
        ? service.imageActive
        : service.image,
      labelTop: service.labelTop,
      labelBot: service.labelBot,
      name: service.name,
      key: service.key,
      active: service.rooms[room]
    }

    dispatch({ type: 'SET_SERVICE', payload: { type: serviceKey, data } })
  })
}