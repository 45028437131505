const initialState = {
  cleanUsers: [],
  mainUsers: [],
  userModal: false,
  edit: false,
  firstname: '',
  lastname: '',
  email: '',
  userId: '',
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case 'UPDATE_STAFF':
      return { ...state, ...payload }
    case 'RESET_STAFF':
      return { ...initialState, cleanUsers: state.cleanUsers, mainUsers: state.mainUsers }
    default:
      return state
  }
}
