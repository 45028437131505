const initialState = {
  surveySms: false,
  surveyWhatsapp: false,
  surveyEmail: false,
  surveyName: '',
  surveyNumber: '',
  surveyMail: '',
  campaignText: '',
  selectedRooms: [],
  typeMessage: 'sms',
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case 'SET_SURVEY':
      return {
        ...state,
        [payload.type]: payload.action
      }

    case 'RESET_SURVEY':
      return {
        ...initialState
      }

    default:
      return state
  }
}
