const initialState = {
	webServices: [],
}

export default (state = initialState, { type, payload }) => {
	switch (type) {
		case 'GET_WEBSERVICES':
			return {
				...state,
				webServices: payload
			}

		default:
			return state
	}
}