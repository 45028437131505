import React, { useState, useEffect } from 'react'
import moment from 'moment'
import Profile from '../wizardSteps/profile'
import { useSelector, useDispatch } from 'react-redux'

import GridContainer from '../../components/Grid/GridContainer'
import GridItem from '../../components/Grid/GridItem'
import Card from '../../components/Card/Card'
import CardHeader from '../../components/Card/CardHeader'
import CardFooter from '../../components/Card/CardFooter'
import CardIcon from '../../components/Card/CardIcon'
import Wizard from 'components/Wizard/Wizard'
import { makeStyles } from '@material-ui/styles'

import { Equalizer, Assignment } from '@material-ui/icons'

//styles
import dashboardStyle from 'assets/jss/material-dashboard-pro-react/views/dashboardStyle'
import extendedTablesStyle from 'assets/jss/material-dashboard-pro-react/views/extendedTablesStyle'
import notificationsStyle from 'assets/jss/material-dashboard-pro-react/views/notificationsStyle.js'
import sweetAlertStyle from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js'
import Table from 'components/Table/Table'
import CardBody from 'components/Card/CardBody'
import { Dialog, DialogContent, Slide } from '@material-ui/core'
import SweetAlert from 'react-bootstrap-sweetalert'
import { eraseStaff, addStaff, changeStaff, resendPassword } from 'redux/actions/staff'

const useStyles = makeStyles(theme => ({
  ...extendedTablesStyle,
  ...notificationsStyle,
  ...sweetAlertStyle,
  ...dashboardStyle,
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='down' ref={ref} {...props} />
})

const Cleaning = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const hotelId = localStorage.getItem('hotel')

  const { edit, cleanUsers, userId, firstname, lastname, email } = useSelector(state => state.staff)
  const { updateCleanRoom, updateRestRoom, updateRequest, updateDontdisturb } = useSelector(state => state.cardsTime)
  const { cleanTasks } = useSelector(state => state.tasks)
  const rooms = useSelector(state => state.rooms.rooms)
  const [modal, setModal] = useState(false)
  const [alert, setAlert] = useState(null)

  const [cleanRoomTime, setCleanRoomTime] = useState(null)
  const [restRoomTime, setRestRoomTime] = useState(null)
  const [cleanRequestTime, setCleanRequestTime] = useState(null)
  const [dontDisturbTime, setDontDisturbTime] = useState(null)

  let clean = rooms.filter(room => room.clean).length
  let notClean = rooms.filter(room => !room.clean).length
  let requests = rooms.filter(room => room.services.includes('Limpieza')).length
  let noDisturb = rooms.filter(room => room.services.includes('No Molestar')).length

  useEffect(() => {
    dispatch({ type: 'UPDATE_TIMES', payload: { updateCleanRoom: moment() } })
  }, [dispatch, clean])

  useEffect(() => {
    dispatch({ type: 'UPDATE_TIMES', payload: { updateRestRoom: moment() } })
  }, [dispatch, notClean])

  useEffect(() => {
    dispatch({ type: 'UPDATE_TIMES', payload: { updateRequest: moment() } })
  }, [dispatch, requests])

  useEffect(() => {
    dispatch({ type: 'UPDATE_TIMES', payload: { updateDontdisturb: moment() } })
  }, [dispatch, noDisturb])


  useEffect(() => {
    var cleanInterval = setInterval(() => {
      setCleanRoomTime(moment(updateCleanRoom).from(moment()))
      setRestRoomTime(moment(updateRestRoom).from(moment()))
      setCleanRequestTime(moment(updateRequest).from(moment()))
      setDontDisturbTime(moment(updateDontdisturb).from(moment()))
    }, 1000)
    return () => {
      clearInterval(cleanInterval)
    }
  })

  const cardsInfo = [{ color: 'rose', head: 'Habitaciones Limpias', amount: clean, lastUpdate: cleanRoomTime },
  { color: 'rose', head: 'Habitaciones Restantes', amount: notClean, lastUpdate: restRoomTime },
  { color: 'warning', head: 'Pedidos de Limpieza', amount: requests, lastUpdate: cleanRequestTime },
  { color: 'danger', head: 'No Molestar', amount: noDisturb, lastUpdate: dontDisturbTime },]

  const newStaff = function newStaff() {
    dispatch({ type: 'UPDATE_STAFF', payload: { edit: false } })
    setModal(true)
  }

  const resendPass = (data) => {
    const { firstname, lastname, contraseña, email } = data
    dispatch(resendPassword({ firstname, lastname, contraseña, email }))
    setAlert(
      <SweetAlert
        success
        style={{ display: 'block', marginTop: '200px' }}
        title='Email enviado!'
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes.button + ' ' + classes.success}
      >
        ¡Se ha enviado un correo para editar la contraseña!
      </SweetAlert>
    )
  }

  const editStaff = (data) => {
    dispatch({ type: 'UPDATE_STAFF', payload: { edit: true, userModal: true, firstname: data.name, lastname: data.lastName, email: data.email, userId: data.id } })
    setModal(true)
  }

  const deleteStaff = (data) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: 'block', marginTop: '200px' }}
        title='Eliminar Perfil'
        onConfirm={() => successDelete(data)}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes.button + ' ' + classes.success}
        cancelBtnCssClass={classes.button + ' ' + classes.danger}
        confirmBtnText='Eliminar'
        cancelBtnText='Cancelar'
        showCancel
      >
        ¿Seguro desea eliminar este perfil?
      </SweetAlert>
    )
  }

  const successDelete = (data) => {
    dispatch(eraseStaff(hotelId, data))
    setAlert(
      <SweetAlert
        success
        style={{ display: 'block', marginTop: '200px' }}
        title='¡Eliminado!'
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes.button + ' ' + classes.success}
      >
        ¡El perfil fue exitosamente eliminado!
      </SweetAlert>
    )
  }

  const closeModal = () => {
    setModal(false)
    dispatch({ type: 'RESET_STAFF' })
  }

  const hideAlert = () => {
    setAlert(null)
  }

  const finishButton = () => {
    edit ?
      dispatch(changeStaff(hotelId, { email, firstname, lastname, id: userId }))
      :
      dispatch(addStaff(hotelId, { email, firstname, lastname, type: 'clean' }))
    closeModal()
  }

  return (
    <div>
      {alert}
      <GridContainer>
        {cardsInfo.map((info, i) => {
          return (
            <GridItem md={3} key={i}>
              <Card
                className='card-dashboard'
                style={{ userSelect: 'none', cursor: 'pointer' }}
              >
                <CardHeader stats icon>
                  <CardIcon
                    color={info.color}
                  >
                    <Equalizer />
                  </CardIcon>
                  <p className={classes.cardCategory}>
                    {info.head}
                  </p>
                  <h3 className={classes.cardTitle}>
                    {info.amount}
                  </h3>
                </CardHeader>
                <CardFooter style={{ height: 25 }} stats>
                  <div className={classes.stats}>
                    Actualizado {info.lastUpdate}
                  </div>
                </CardFooter>
              </Card>
            </GridItem>
          )
        })}
        {cleanTasks.length !== 0 &&
          <GridItem xs={12} sm={12} md={12} lg={12} >
            <Card >
              <CardHeader color='rose' icon>
                <CardIcon color='rose'>
                  <Assignment />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>Tareas</h4>
              </CardHeader>
              <CardBody >
                <Table
                  tableHead={['#', 'Habitación', 'Desde', 'Asignado', 'Estado']}
                  tableData={cleanTasks}
                  customCellClasses={[classes.left, classes.left, classes.right]}
                  customClassesForCells={[0, 4, 5]}
                  customHeadCellClasses={[classes.left, classes.left, classes.right]}
                  customHeadClassesForCells={[0, 4, 5]}
                  cleaningTasks={true}
                  resendPass={resendPass}
                  editStaff={editStaff}
                  deleteStaff={deleteStaff}
                  buttonStyle={classes.actionButton}
                />
              </CardBody>
            </Card>
          </GridItem>
        }
        <GridItem xs={12} sm={12} md={12} lg={12} >
          <Card
            buttonright={{ text: 'Crear usuario', function: newStaff }} >
            <CardHeader color='rose' icon>
              <CardIcon color='rose'>
                <Assignment />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Perfiles</h4>
            </CardHeader>
            <CardBody >
              <Table
                tableHead={['#', 'Nombre', 'Cargo', 'Desde', 'Tarea Actual', 'Acciones']}
                tableData={cleanUsers}
                customCellClasses={[classes.left, classes.left, classes.right]}
                customClassesForCells={[0, 4, 5]}
                customHeadCellClasses={[classes.left, classes.left, classes.right]}
                customHeadClassesForCells={[0, 4, 5]}
                cleaning={true}
                resendPass={resendPass}
                editStaff={editStaff}
                deleteStaff={deleteStaff}
                buttonStyle={classes.actionButton}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <Dialog
        classes={{
          root: classes.center + ' ' + classes.modalRoot,
          paper: classes.modal
        }}
        open={modal}
        maxWidth={'lg'}
        TransitionComponent={Transition}
        keepMounted
        onClose={closeModal}
        aria-labelledby='classic-modal-slide-title'
        aria-describedby='classic-modal-slide-description'
      >
        <DialogContent
          id='classic-modal-slide-description'
          className={classes.modalBody}
        >
          <Wizard
            validate
            steps={[
              { stepName: edit ? 'Editar Perfil' : 'Nuevo Perfil', stepComponent: Profile, stepId: 'perfil' },
            ]}
            title={edit ? 'Editar Perfil' : 'Crear Perfil'}
            subtitle={`Complete la información para ${edit ? 'editar el' : 'crear un nuevo'} perfil.`}
            finishButtonClick={finishButton}
          />
        </DialogContent>
      </Dialog>
    </div>
  )
}


export default Cleaning