const initialState = {
	updateEmergency: null,
	updateChat: null,
	notViewedMessages: 0,
}

export default (state = initialState, { type, payload }) => {
	switch (type) {
	case 'UPDATE_DASHBOARD':
		return { ...state, ...payload }

	case 'NOT_VIEWED_MESSAGES':
		return { ...state, ...payload }

	default:
		return state
	}
}
