import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Button from '../components/button'
import { getService, changeStatus } from 'redux/actions/webappServices'


export default ({room}) => {
	const states = useSelector(state => state.services.events)
	const dispatch = useDispatch()
	const hotelId = localStorage.getItem('hotel')

	useEffect(() => dispatch(getService(hotelId, room, 'events')), [hotelId, room, dispatch])

	return <Button onClick={() => changeStatus(hotelId, room, states.name, states.key)} service={states} />
}