import firebase from '../../services/firebase'

import { removeService } from "./services"
const hotel = firebase.firestore().collection("hotels")


export const checkIn = (data, license, hotelId) => dispatch => {
  async function ifExist() {
    let code = Math.floor(1000 + Math.random() * 9000)
    let exist = (await hotel.doc(hotelId).collection('checkIn').doc(code.toString()).get()).exists
    if (exist) {
      throw new Error('Ya existe ese codigo')
    } else {
      return code
    }
  }

  if(license === 'lite') {
    const { name, number, email, room } = data
    ifExist()
      .then(code => {
        hotel.doc(hotelId).collection('rooms').doc(room.toString()).update({
          available: false,
          roomCode: code,
        })

        hotel.doc(hotelId).collection('checkIn').doc(code.toString()).set({
          code,
          name,
          number,
          email,
        })
      })
      .catch(err => console.log(err))
  } else {
    const { rooms, checkIn, checkOut, owner, code } = data

    rooms.map(room => hotel.doc(hotelId).collection('rooms').doc(room.number.toString()).update({
      available: false,
      roomCode: code
    }))

    hotel.doc(hotelId).collection('checkIn').doc(code.toString()).set({
      code: code,
      name: owner.name,
      lastName: owner.lastName,
      email: owner.email,
      document: owner.doc,
      phone: owner.phone,
      companions: [...owner.companions],
      in: checkIn,
      out: checkOut,
      rooms: rooms.map(room => room.number ? room.number : room)
    })
  }
}

export const checkOut = (room, type, hotelId) => async dispatch => {
  try {
    let request = await hotel.doc(hotelId).collection('services').get()
    let docs = request.docs.map(a => a.data())

    await hotel.doc(hotelId).collection('rooms').doc(room.toString()).set({
      available: true,
      number: room,
      type: type,
      services: [],
    })

    docs.forEach(service => {
      if (typeof service.rooms[room] === 'object' || typeof service.rooms[room] === 'string') {
        dispatch(removeService(hotelId, room, service.name))
      }
    })
  }
  catch
  {
    throw new TypeError('Check For HotelId parameter to be Correct')
  }
}