import React from 'react'
import { connect } from 'react-redux'

//Material Icos
import Check from '@material-ui/icons/Check'
import Checkbox from '@material-ui/core/Checkbox'
import withStyles from "@material-ui/core/styles/withStyles"
import FormControlLabel from '@material-ui/core/FormControlLabel'

//Core Components
import GridItem from '../../../components/Grid/GridItem'
import GridContainer from '../../../components/Grid/GridContainer'
import CustomInput from "../../../components/CustomInput/CustomInput"

// Styles
import regularFormsStyle from "../../../assets/jss/material-dashboard-pro-react/views/regularFormsStyle"

class Select extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      valName: '',
      valEmail: '',
      valNumber: false,
      error: ''
    }
  }

  handleToggle(value) {
    this.props.dispatch({ type: 'SET_SURVEY', payload: { type: `survey${value}`, action: !this.props[value] } })
  }

  onChange(event, type) {
    const { dispatch } = this.props
    const { value } = event.target
    return dispatch({ type: 'SET_SURVEY', payload: { type: `survey${type}`, action: value } })
  }

  isValidated() {
    const { Whatsapp, Sms, Email, number, email, name } = this.props
    if ((Whatsapp || Sms) && !Email) {
      if (number.length > 4) {
        if (name.length > 2) {
          return true
        }
      }
    }
    if ((!Whatsapp || !Sms) && Email) {
      if (email.length > 4) {
        if (name.length > 2) {
          return true
        }
      }
    }
    return false
  }

  render() {
    const { classes, Whatsapp, Sms, Email } = this.props
    return (
      <GridContainer justify='center'>
        <legend>Seleccione el medio por donde enviar la encuesta.</legend>
        <GridItem xs={12} sm={12} md={8}>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            {/* <FormControlLabel
              control={
                <Checkbox
                  tabIndex={-1}
                  onClick={() => this.handleToggle('Whatsapp')}
                  checkedIcon={
                    <Check className={classes.checkedIcon} />
                  }
                  icon={<Check className={classes.uncheckedIcon} />}
                  classes={{
                    checked: classes.checked,
                    root: classes.checkRoot
                  }}
                />
              }
              classes={{
                label: classes.label
              }}
              label='Whatsapp'
            /> */}
            <FormControlLabel
              control={
                <Checkbox
                  tabIndex={-1}
                  onClick={() => this.handleToggle('Sms')}
                  checkedIcon={
                    <Check className={classes.checkedIcon} />
                  }
                  icon={<Check className={classes.uncheckedIcon} />}
                  classes={{
                    checked: classes.checked,
                    root: classes.checkRoot
                  }}
                />
              }
              classes={{
                label: classes.label
              }}
              label='SMS'
            />
            <FormControlLabel
              control={
                <Checkbox
                  tabIndex={-1}
                  onClick={() => this.handleToggle('Email')}
                  checkedIcon={
                    <Check className={classes.checkedIcon} />
                  }
                  icon={<Check className={classes.uncheckedIcon} />}
                  classes={{
                    checked: classes.checked,
                    root: classes.checkRoot
                  }}
                />
              }
              classes={{
                label: classes.label
              }}
              label='Email'
            />
          </div>
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <CustomInput
            labelText='Nombre'
            id='enterName'
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              onChange: event =>
                this.onChange(event, 'Name'),
              type: 'text'
            }}
          />
          {
            (Whatsapp || Sms) &&
            <CustomInput
              labelText='Número'
              id='enterNumber'
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                onChange: event =>
                  this.onChange(event, 'Number'),
                type: 'number'
              }}
            />
          }
          {
            Email &&
            <CustomInput
              // success={this.state.registerEmailState === 'success'}
              // error={this.state.registerEmailState === 'error'}
              labelText='Email'
              id='enterEmail'
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                onChange: event =>
                  this.onChange(event, 'Mail'),
                type: 'email'
              }}
            />
          }
        </GridItem>
      </GridContainer>
    )
  }
}

const mapStateToProps = state => ({
  ...state,
  Whatsapp: state.survey.surveyWhatsapp,
  Sms: state.survey.surveySms,
  Email: state.survey.surveyEmail,
  name: state.survey.surveyName,
  number: state.survey.surveyNumber,
  email: state.survey.surveyMail,
})

export default connect(mapStateToProps)(withStyles(regularFormsStyle)(Select))
