import React, { Component } from 'react'
import { connect } from 'react-redux'

import { sendCampaign, getCampaignConfig } from '../../redux/actions/campaigns'

import SweetAlert from 'react-bootstrap-sweetalert'
import sweetAlertStyle from '../../assets/jss/material-dashboard-pro-react/views/sweetAlertStyle'

import Wizard from '../../components/Wizard/Wizard'
import GridItem from '../../components/Grid/GridItem'
import GridContainer from '../../components/Grid/GridContainer'

import WriteCampaign from './steps/writeCampaign'
import roomCampaigns from './steps/roomCampaigns'

import withStyles from '@material-ui/core/styles/withStyles'


class Add extends Component {
  constructor(props) {
    super(props)

    this.state = {
      alert: false,
      ready: false
    }
  }

  componentDidMount() {

    this.unblock = this.props.history.block(targetLocation => {
      if (targetLocation.pathname !== '/admin/campaigns' && this.props.altText !== '') {
        this.setState({
          alert: (
            <SweetAlert
              warning
              title='¿Seguro que quieres irte?'
              onConfirm={() => this.successDelete(targetLocation.pathname)}
              onCancel={() => this.hideAlert()}
              confirmBtnCssClass={
                this.props.classes.button + ' ' + this.props.classes.success
              }
              cancelBtnCssClass={
                this.props.classes.button + ' ' + this.props.classes.danger
              }
              confirmBtnText='ABANDONAR'
              cancelBtnText='CANCELAR'
              showCancel
            >
              ¡Perderás los cambios!
            </SweetAlert>
          )
        })
        return this.state.ready
      }
    })

   !this.props.templates.length && this.props.dispatch(getCampaignConfig())
  }

  componentWillUnmount() {
    this.props.dispatch({ type: 'RESET_CAMPAIGN' })
    this.unblock()
  }

  successSave() {
    this.props.dispatch({ type: 'RESET_CAMPAIGN' })
    this.setState({
      alert: null,
      ready: true
    }, () => this.props.history.push('/admin/rooms')
    )
  }

  hideAlert() {
    this.setState({
      alert: null
    })
  }

  successDelete(location) {
    this.setState({
      alert: null,
      ready: true
    }, () => this.props.history.push(location)
    )
  }



  render() {
    const { dispatch, classes } = this.props
    const { alert ,altText} = this.state
console.log(altText)
    return (
      <div>
        {alert}
        <GridContainer justify='center' style={{ userSelect: 'none' }}>
          <GridItem xs={12} sm={8}>
            <Wizard
              validate
              steps={[
                { stepName: 'CAMPAÑA', stepComponent: WriteCampaign, stepId: 'write' },
                { stepName: 'HABITACION', stepComponent: roomCampaigns, stepId: 'room' },
              ]}
              title='Nueva Campaña'
              subtitle='Para completar la campaña siga los pasos.'
              finishButtonClick={() => {
                this.setState({
                  alert: (
                    <SweetAlert
                      success
                      title='Felicitaciones'
                      onConfirm={() => this.successSave()}
                      onCancel={() => this.successSave()}
                      confirmBtnCssClass={
                        classes.button + ' ' + classes.success
                      }
                    >
                      ¡Su campaña ha sido enviada correctamente!
                    </SweetAlert>
                  )
                })
                dispatch(sendCampaign())
              }}
            />
          </GridItem>
        </GridContainer>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  altText: state.campaigns.altText,
  templates: state.campaigns.templates
})

export default connect(mapStateToProps)(withStyles(sweetAlertStyle)(Add))