import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'

import styles from 'assets/jss/material-dashboard-pro-react/components/accordionStyle.js'

const useStyles = makeStyles(styles)

export default props => {
  const [active, setActive] = React.useState(props.active)

  const classes = useStyles()
  const { collapses } = props

  const handleChange = panel => (event, expanded) => setActive(expanded ? panel : -1)
  console.log(typeof active === "boolean" && "")
  return (
    <div className={classes.root}>
      {collapses.map((prop, key) => {

        return (
          <ExpansionPanel
            expanded={true}
            onChange={handleChange(key)}
            key={key}
            classes={{
              root: classes.expansionPanel,
              expanded: classes.expansionPanelExpanded
            }}
          >
            <ExpansionPanelSummary
              style={{ borderBottom: '0px' }}
              classes={{
                root: classes.expansionPanelSummary,
                expanded: classes.expansionPanelSummaryExpaned,
                content: classes.expansionPanelSummaryContent,
                expandIcon: classes.expansionPanelSummaryExpandIcon
              }}
            >
              <h4 style={{ color: "black", fontWeight: 400 }}>{prop.title}</h4>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.expansionPanelDetails}>
              {prop && prop.content}
            </ExpansionPanelDetails>
          </ExpansionPanel>
        )
      })}
    </div>
  )
}
