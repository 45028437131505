const styles = {
  buttons: {
    width: 150,
    height: 100,
    border: 'none',
    background: 'transparent',
    margin: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer'
  },
  actions: {
    fontFamily: 'Lato',
    fontSize: 12,
    fontWeight: 900,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    color: '#fff',
  },
}

export default styles