import firebase from '../../services/firebase'
import admin from 'firebase/app'
import moment from 'moment'

const hotel = firebase.firestore().collection('hotels')

export const getTasks = (id) => dispatch => {
  hotel.doc(id).collection('tasks').orderBy("created").onSnapshot(snap => {
    let mainTasks = []
    snap.forEach(task => {
      mainTasks.push({ ...task.data(), id: task.id })
      dispatch({ type: 'UPDATE_TASKS', payload: { mainTasks } })
    })
  })
}

export const addTask = (id, data) => dispatch => {
  hotel.doc(id).collection('tasks').add({
    created: moment()['_d'],
    description: data.description,
    title: data.title,
    room: data.room,
    messages: [],
    status: 'pending'
  })
}

export const modifyTask = (id, data) => dispatch => {
  hotel.doc(id).collection('tasks').doc(data.id).update({
    description: data.description,
    title: data.title,
    room: data.room,
    modifyed: moment()['_d']
  })
}

export const eraseTask = (id, data) => dispatch => {
  hotel.doc(id).collection('tasks').doc(data.id).delete()
}

export const sendTaskMessage = (id, data) => dispatch => {
  hotel.doc(id).collection('tasks').doc(data.id).update({
    messages: admin.firestore.FieldValue.arrayUnion({ from: 'hotel', message: data.message, date: moment()['_d'], viewed: false })
  })
}