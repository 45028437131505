import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Button from '../components/button'
import { getService } from 'redux/actions/webappServices'
import { useHistory } from 'react-router-dom'


export default ({ room, hotel }) => {
	const history = useHistory()
	const service = useSelector(state => state.services.map)
	const dispatch = useDispatch()
	const hotelId = localStorage.getItem('hotel')

	useEffect(() => dispatch(getService(hotelId, room, 'map')), [room, dispatch, hotelId])

	return <Button onClick={() => history.push(`/${hotel}/app/${room}/map`)} service={service} />
}