import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import moment from 'moment'

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import styles from "assets/jss/material-dashboard-pro-react/components/tableStyle";
import Button from 'components/CustomButtons/Button'
import { Tooltip } from "@material-ui/core";
import { Close, Edit, Person } from "@material-ui/icons";

const useStyles = makeStyles(styles);

export default function CustomTable(props) {
  const classes = useStyles();
  const {
    tableHead,
    tableData,
    tableHeaderColor,
    hover,
    colorsColls,
    coloredColls,
    customCellClasses,
    customClassesForCells,
    striped,
    tableShopping,
    customHeadCellClasses,
    customHeadClassesForCells
  } = props;
  return (
    <div className={classes.tableResponsive}>
      <Table className={classes.table}>
        {tableHead !== undefined ? (
          <TableHead className={classes[tableHeaderColor]}>
            <TableRow className={classes.tableRow + " " + classes.tableRowHead}>
              {tableHead.map((prop, key) => {
                const tableCellClasses =
                  classes.tableHeadCell +
                  " " +
                  classes.tableCell +
                  " " +
                  cx({
                    [customHeadCellClasses[
                      customHeadClassesForCells.indexOf(key)
                    ]]: customHeadClassesForCells.indexOf(key) !== -1,
                    [classes.tableShoppingHead]: tableShopping,
                    [classes.tableHeadFontSize]: !tableShopping
                  });
                return (
                  <TableCell style={{ textAlign: 'center' }} className={tableCellClasses} key={key}>
                    {prop}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
        ) : null}
        <TableBody>
          {tableData.map((prop, key) => {
            const { name, carge, since, actual, room, created, title, assigned, status } = prop
            var rowColor = "";
            var rowColored = false;
            if (prop.color !== undefined) {
              rowColor = prop.color;
              rowColored = true;
              prop = prop.data;
            }
            const tableRowClasses = cx({
              [classes.tableRowBody]: true,
              [classes.tableRowHover]: hover,
              [classes[rowColor + "Row"]]: rowColored,
              [classes.tableStripedRow]: striped && key % 2 === 0
            });
            if (props.cleaningTasks) {
              const cleanList = []
              return (<TableRow
                key={key}
                hover={hover}
                className={classes.tableRow + " " + tableRowClasses}
              >
                {cleanList.map((clean, i) => {
                  return <TableCell
                    key={i}
                    className={classes.tableCell + " " + classes.right}
                    style={{ textAlign: 'center' }}
                  >
                    {clean}
                  </TableCell>
                })}
              </TableRow>
              )

            }
            if (props.cleaning) {
              const cleanList = [key + 1, name, carge, since, actual]

              const simpleButtons = [
                { title: 'Editar perfil', color: "info", icon: Person, onClick: () => props.editStaff(prop) },
                { title: 'Restablecer contraseña', color: "success", icon: Edit, onClick: () => props.resendPass(prop) },
                { title: 'Eliminar perfil', color: "danger", icon: Close, onClick: () => props.deleteStaff(prop) }
              ].map((prop, key) => {
                return (
                  <Button
                    color={prop.color}
                    simple
                    className={props.buttonStyle}
                    onClick={prop.onClick}
                    key={key}
                  >
                    <Tooltip title={prop.title} placement="bottom">
                      <prop.icon className={classes.icon} />
                    </Tooltip>
                  </Button>
                );
              });

              return (<TableRow
                key={key}
                hover={hover}
                className={classes.tableRow + " " + tableRowClasses}
              >
                {cleanList.map((clean, i) => {
                  return <TableCell
                    key={i}
                    className={classes.tableCell + " " + classes.right}
                    style={{ textAlign: 'center' }}
                  >
                    {clean}
                  </TableCell>
                })}
                <TableCell
                  className={classes.tableCell + " " + classes.right}
                  style={{ textAlign: 'center' }}
                >
                  {simpleButtons}

                </TableCell>
              </TableRow>
              )
            }
            if (props.cleaningTasks) {
              const cleanTasks = [key + 1, room, moment(created.toDate()).format('LLL'), assigned, status]
              return (<TableRow
                key={key}
                hover={hover}
                className={classes.tableRow + " " + tableRowClasses}
              >
                {cleanTasks.map((cleanTask, i) => {
                  return <TableCell
                    key={i}
                    className={classes.tableCell + " " + classes.right}
                    style={{ textAlign: 'center' }}
                  >
                    {cleanTask}
                  </TableCell>
                })}
                {/* <TableCell
                  className={classes.tableCell + " " + classes.right}
                  style={{ textAlign: 'center' }}
                >
                  {simpleButtons}

                </TableCell> */}
              </TableRow>
              )
            }
            if (props.maintenance) {
              const maintenanceUsers = [key + 1, name, carge, since, actual]

              const simpleButtons = [
                { title: 'Editar perfil', color: "info", icon: Person, onClick: () => props.editStaff(prop) },
                { title: 'Restablecer contraseña', color: "success", icon: Edit, onClick: () => props.resendPass(prop) },
                { title: 'Eliminar perfil', color: "danger", icon: Close, onClick: () => props.deleteStaff(prop) }
              ].map((prop, key) => {
                return (
                  <Button
                    color={prop.color}
                    simple
                    className={props.buttonStyle}
                    onClick={prop.onClick}
                    key={key}
                  >
                    <Tooltip title={prop.title} placement="bottom">
                      <prop.icon className={classes.icon} />
                    </Tooltip>
                  </Button>
                );
              });

              return (<TableRow
                key={key}
                hover={hover}
                className={classes.tableRow + " " + tableRowClasses}
              >
                {maintenanceUsers.map((main, i) => {
                  return <TableCell
                    key={i}
                    className={classes.tableCell + " " + classes.right}
                    style={{ textAlign: 'center' }}
                  >
                    {main}
                  </TableCell>
                })}
                <TableCell
                  className={classes.tableCell + " " + classes.right}
                  style={{ textAlign: 'center' }}
                >
                  {simpleButtons}

                </TableCell>
              </TableRow>
              )
            }
            if (props.maintenanceTasks) {
              const tasks = [key + 1, room, moment(created.toDate()).format('LLL'), title, assigned, status]
              const simpleButtons = [
                { title: 'Editar tarea', color: "info", icon: Person, onClick: () => props.changeTask(prop) },
                { title: 'Eliminar tarea', color: "danger", icon: Close, onClick: () => props.deleteTask(prop) }
              ].map((prop, key) => {
                return (
                  <Button
                    color={prop.color}
                    simple
                    className={props.buttonStyle}
                    onClick={prop.onClick}
                    key={key}
                  >
                    <Tooltip title={prop.title} placement="bottom">
                      <prop.icon className={classes.icon} />
                    </Tooltip>
                  </Button>
                );
              })

              return (<TableRow
                key={key}
                hover={hover}
                className={classes.tableRow + " " + tableRowClasses}
              >
                {tasks.map((task, i) => {
                  return <TableCell
                    key={i}
                    className={classes.tableCell + " " + classes.right}
                    style={{ textAlign: 'center' }}
                    onClick={() => props.openTask(prop)}
                  >
                    {task === 'pending' ? 'Pendiente'
                      : task === 'assigned' ? 'Asignado'
                        : task === 'paused' ? 'En Pausa'
                          : task === 'finished' ? 'Completado'
                            : task}
                  </TableCell>
                })}
                <TableCell
                  className={classes.tableCell + " " + classes.right}
                  style={{ textAlign: 'center' }}
                >
                  {simpleButtons}

                </TableCell>
              </TableRow>
              )
            }
            return (
              <TableRow
                key={key}
                hover={hover}
                className={classes.tableRow + " " + tableRowClasses}
              >
                {prop.map((prop, key) => {
                  const tableCellClasses =
                    classes.tableCell +
                    " " +
                    cx({
                      [classes[colorsColls[coloredColls.indexOf(key)]]]:
                        coloredColls.indexOf(key) !== -1,
                      [customCellClasses[customClassesForCells.indexOf(key)]]:
                        customClassesForCells.indexOf(key) !== -1
                    });
                  return (
                    <TableCell className={tableCellClasses} key={key}>
                      {prop}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
}

CustomTable.defaultProps = {
  tableHeaderColor: "gray",
  hover: false,
  colorsColls: [],
  coloredColls: [],
  striped: false,
  customCellClasses: [],
  customClassesForCells: [],
  customHeadCellClasses: [],
  customHeadClassesForCells: []
};

CustomTable.propTypes = {
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray"
  ]),
  tableHead: PropTypes.arrayOf(PropTypes.string),
  // Of(PropTypes.arrayOf(PropTypes.node)) || Of(PropTypes.object),
  tableData: PropTypes.array,
  hover: PropTypes.bool,
  coloredColls: PropTypes.arrayOf(PropTypes.number),
  // Of(["warning","primary","danger","success","info","rose","gray"]) - colorsColls
  colorsColls: PropTypes.array,
  customCellClasses: PropTypes.arrayOf(PropTypes.string),
  customClassesForCells: PropTypes.arrayOf(PropTypes.number),
  customHeadCellClasses: PropTypes.arrayOf(PropTypes.string),
  customHeadClassesForCells: PropTypes.arrayOf(PropTypes.number),
  striped: PropTypes.bool,
  // this will cause some changes in font
  tableShopping: PropTypes.bool
};
