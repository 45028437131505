import Background from '../../../assets/img/faena.svg'

export default {
  root: {
    position: 'fixed',
    bottom: 0,
    top: 0,
    left: 0,
    right: 0,
    display: 'flex',
    flexDirection: 'column',
    backgroundImage: `url(${Background})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  body: {
    flex: 1,
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  topSection: {
    flex: 1,
    maxHeight: 153,
    display: 'flex',
    justifyContent: 'center',
    flexFlow: 'column',
    alignItems: 'center',
  },
  midSection: {
    flex: 3,
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    flexWrap: 'wrap',
    margin: '0 30px 0 30px',
    overflow: 'auto',
  },
  title: {
    fontFamily: 'Lato',
    fontSize: 25,
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    color: '#ffffff',
    margin: 0,
  },
  subTitle: {
    margin: 0,
    fontFamily: 'Lato',
    fontSize: 20,
    fontWeight: 900,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    color: '#ffffff',
  },
  bottomSection: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    boxSizing: 'border-box'
  },
  roomNumber: {
    margin: 0,
    fontFamily: 'Lato',
    fontSize: 35,
    fontWeight: 900,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    color: '#ffffff',
  },
  buttons: {
    width: 150,
    height: 100,
    border: 'none',
    background: 'transparent',
    margin: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer'
  },
  actions: {
    fontFamily: 'Lato',
    fontSize: 12,
    fontWeight: 900,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    color: '#fff',
  },
  inputs: {
    width: '80%',
    height: 50,
    fontSize: 28,
    border: 'none',
    fontFamily: 'Lato'
  },
  refInputs: {
    margin: 0,
    color: '#fff',
    fontFamily: 'Lato',
    fontWeight: 600,
    fontSize: 20
  },
  containAlert: {
    position: 'absolute',
    top: 0,
    height: 35,
    marginBottom: 8,
    width: "100%",
    display: "flex", 
    justifyContent: "center",
    alignItems: 'center',
    backgroundColor: '#F44336',
  },
  textAlert: {
    color: '#fff',
    margin: 0,
  },
  buttonDropDown: {
    backgroundColor: 'transparent',
    borderColor: 'transparent'
  },
  buttonEndSession: {
    color: 'white',
    backgroundColor: 'none',
    borderColor: 'none'
  }
}
  