import React, { Component } from 'react'
import { connect } from 'react-redux'
import '../../../views/Chat/index.css'

import { ArrowBack } from '@material-ui/icons'
import withStyles from '@material-ui/core/styles/withStyles'
import styles from './styles'

import admin from 'firebase/app'
import moment from 'moment'

const DB = admin.firestore().collection('hotels')

class WebChat extends Component {
  constructor(props) {
    super(props)

    this.state = {
      messages: [],
      message: '',
    }
  }

  componentDidMount() {
    const { hotel, room } = this.props.match.params
    const { response } = this.props.location.state
    const profile = JSON.parse(localStorage.getItem('profile'))

    this.setState({ profile: profile ? profile : { name: '' } })
    this.setState({ license: response })

    this.getMessages(hotel, room, response, profile ? profile.name : '')
  }

  getMessages(hotel, room, license, name) {
    let DB_CHAT = DB.doc(hotel).collection('chat')
    if (license === 'lite') {
      DB_CHAT.where('room', '==', room).onSnapshot(snap => {
        if (snap.size === 1) {
          let data = snap.docs[0].data()
          return this.setState({ messages: data.messages })
        }
      })
    } else {
      DB_CHAT.where('room', '==', room).where('profile', '==', name).onSnapshot(snap => {
        if (snap.size === 1) {
          let data = snap.docs[0].data()
          return this.setState({ messages: data.messages })
        }
      })
    }

  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.messages !== prevState.messages) {
      let show = document.getElementById('chat').lastChild
      show && show.scrollIntoView(false)
    }
  }

  async  sendMessage() {
    const { hotel, room } = this.props.match.params
    const { profile, message, messages, license } = this.state
    const DB_CHAT = DB.doc(hotel).collection('chat')
    const date = moment()['_d']

    if (messages.length === 0) {
      DB_CHAT.add({
        room,
        lastMessageTime: date,
        profile: profile ? profile.name : '',
        messages: [{ from: 'client', message, date, viewed: false }]
      })
    } else {
      if (license === 'lite') {
        let chatId = (await DB_CHAT.where('room', '==', room).get()).docs[0].id
        DB_CHAT.doc(chatId).update({
          lastMessageTime: date,
          messages: admin.firestore.FieldValue.arrayUnion({ from: 'client', message, date, viewed: false })
        })
      } else {
        let chatId = (await DB_CHAT.where('room', '==', room).where('profile', '==', profile.name).get()).docs[0].id
        DB_CHAT.doc(chatId).update({
          lastMessageTime: date,
          messages: admin.firestore.FieldValue.arrayUnion({ from: 'client', message, date, viewed: false })
        })
      }
    }

    DB.doc(hotel).collection('notifications').doc().set({
      type: 'message',
      data: { message }
    })

    this.setState({ message: '' })

  }

  handleChangeMessage(event) {
    const value = event.target.value
    this.setState({ message: value })
  }

  handleBack(room, hotel) { this.props.history.push(`/${hotel}/app/${room}`) }

  render() {
    const { message, messages } = this.state
    const { room, hotel } = this.props.match.params

    return (
      <div style={styles.rootContainer}>
        <div style={styles.header}>
          <button onClick={() => this.handleBack(room, hotel)} style={styles.backButton}>
            <ArrowBack />
          </button>
          <b style={styles.textRoom}>Habitación {room}</b>
        </div>
        <div style={styles.containMessages} id='chat'>
          {
            messages.length !== 0 && messages.map((msg, key) => {
              return (
                msg.from !== 'hotel'
                  ? <div style={styles.containClientBubble} key={key}>
                    <div style={styles.clientBubble}>
                      {(key !== 0 && messages[key - 1].from === msg.from) && <div className="arrow-left" />}
                      <div style={styles.bodyBubble}>
                        <div>{msg.message}</div>
                        <div style={styles.dateMessage}>{moment(msg.date.toDate()).format('LT')}</div>
                      </div>
                    </div>
                  </div>
                  : <div style={styles.containHotelBubble} key={key}>
                    <div style={styles.hotelBubble}>
                      {(key !== 0 && messages[key - 1].from === msg.from) && <div className="arrow-right" />}
                      <div style={styles.bodyBubble}>
                        <div>{msg.message}</div>
                        <div style={styles.dateMessage}>{moment(msg.date.toDate()).format('LT')}</div>
                      </div>
                    </div>
                  </div>
              )
            })
          }
        </div>
        <div style={styles.footer}>
          <input
            placeholder="Escribe algo..."
            onChange={e => this.handleChangeMessage(e)}
            value={message}
            onSelect={() => document.getElementById('chat').scrollTop = document.getElementById('chat').scrollHeight}
            style={styles.inputText}
          />
          <button
            style={{ ...styles.sendButton, backgroundColor: message.length !== 0 ? '#ff2068' : '#9e9e9e' }}
            onClick={this.sendMessage}
            onKeyPress={e => e.charCode === 13 ? this.sendMessage() : null}
          >
            ENVIAR
          </button>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  ...state,
})

export default connect(mapStateToProps)(withStyles(styles)(WebChat))