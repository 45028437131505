import React from 'react'
import 'views/Chat/index.css'

const styles = {
  containBubble: {
    position: 'relative',
    alignSelf: 'flex-end',
    minWidth: '20%',
    maxWidth: '40%',
    padding: 10,
    borderRadius: 6,
    backgroundColor: '#2196f3',
  },
  pTime: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    minWidth: 55,
    marginLeft: 20,
    fontSize: 11,
    fontWeight: 200,
  },
  pMessage: {

  }
}

export default props => {
  const { time, msg, color, from, arrow } = props
  
  return (
    <div style={{ ...styles.containBubble, backgroundColor: color, margin: from === 'you' ? '0 10px 5px 0' : '0 0 5px 15px' }}>
      { arrow && <div className={from === 'you' ? 'arrow-left' : 'arrow-right'}></div> }
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>{msg}</div>
        <div style={styles.pTime}>{time}</div>
      </div>
  </div>
  )
}
