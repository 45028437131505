import React from 'react'
import { connect } from 'react-redux'

import {
  Select,
  MenuItem,
  FormLabel,
  RadioGroup,
  Radio,
  FormControl,
  FormControlLabel
} from '@material-ui/core'

import withStyles from '@material-ui/core/styles/withStyles'
import formStyles from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle'

class RoomCampaign extends React.Component {
  handleSend(programar) {
    this.props.dispatch({ type: 'UPDATE_FORM', payload: { programar } })
  }

  handleActions(type, value) {
    this.props.dispatch({ type: 'UPDATE_FORM', payload: { [type]: value } })
  }

  render() {
    const { classes, rooms, selectedRooms, horario, programar } = this.props

    return (
      <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <div style={{ width: '60%', marginBottom: 20 }}>
        <FormControl fullWidth className={classes.selectFormControl}>
          <FormLabel component="legend" style={{ textAlign: 'center' }}>
            Elija una o varias habitaciones o no seleccione ninguna <br /> para enviar a todas
          </FormLabel>
          <Select
            fullWidth
            multiple
            value={selectedRooms}
            onChange={e => this.handleActions('selectedRooms', e.target.value)}
            MenuProps={{ className: classes.selectMenu }}
            classes={{ select: classes.select }}
            inputProps={{
              name: "multipleSelect",
              id: "multiple-select"
            }}
          >
            <MenuItem disabled classes={{ root: classes.selectMenuItem }}>
              Seleccione
              </MenuItem>
            {rooms.filter(room => !room.available).map((room, i) => (
              <MenuItem
                classes={{
                  root: classes.selectMenuItem,
                  selected: classes.selectMenuItemSelectedMultiple
                }}
                value={room.roomNumber}
                key={i}
              >
                {room.roomNumber}
              </MenuItem>
            ))}
          </Select>
          </ FormControl>
        </div>
        <div>
          <RadioGroup aria-label="gender" name="send" value={programar} onChange={e => this.handleSend(e.target.value)}>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
              <FormControlLabel value='ahora' control={<Radio />} label="Enviar ahora" />
              <FormControlLabel value='programar' control={<Radio />} label="Programar envío" />
            </div>
          </RadioGroup>

          {programar === 'programar' &&
            <>
              <FormLabel component="legend" style={{ textAlign: 'center' }}>Horario de envío</FormLabel>
              <RadioGroup aria-label="gender" name="send" value={horario} onChange={e => this.handleActions('horario', e.target.value)}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <FormControlLabel value="mañana" control={<Radio />} label="Mañana (07:00 hs)" />
                    <FormControlLabel value="mediodia" control={<Radio />} label="Mediodía (12:00 hs)" />
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <FormControlLabel value="tarde" control={<Radio />} label="Tarde (16:00 hs)" />
                    <FormControlLabel value="noche" control={<Radio />} label="Noche (22:00 hs)" />
                  </div>
                </div>
              </RadioGroup>
            </>
          }
        </div>
      </div>
    )
  }

}

const mapStateToProps = (state) => ({
  rooms: state.rooms.rooms,
  selectedRooms: state.campaigns.selectedRooms,
  horario: state.campaigns.horario,
  programar: state.campaigns.programar
})

export default connect(mapStateToProps)(withStyles(formStyles)(RoomCampaign))