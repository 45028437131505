const initialState = {
    medic: []
}

export default (state = initialState, action = {}) => {
    switch (action.type) {

        case 'SET_MEDIC':
            return {
                ...state,
                medic: action.payload
            }

        default:
            return state
    }
}
