/*eslint-disable*/
import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

import styles from "assets/jss/material-dashboard-pro-react/components/footerStyle.js";

const useStyles = makeStyles(styles);

export default function Footer(props) {
  const classes = useStyles();
  const { white } = props;
  return (
    <footer className={classes.footer}>
      <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', height: 50, userSelect: 'none' }}>
        <p style={{ color: white ? '#fff' : '#3c4858' , margin: 0, padding: 0 }}>
          Copyright © 2019 hecho con ♥ por Lovable
        </p>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  white: PropTypes.bool,
};
