import React, { useReducer, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getRooms } from "redux/actions/rooms";

// Actions
import { checkIn } from "redux/actions/checkIn";
import Datetime from "react-datetime";

// SweetAlerts
import SweetAlert from "react-bootstrap-sweetalert";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";

// core components
import Card from "components/Card/Card";
import GridItem from "components/Grid/GridItem";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import Button from "components/CustomButtons/Button";
import Accordion from "components/Accordion/Accordion";
import GridContainer from "components/Grid/GridContainer";
import CustomInput from "components/CustomInput/CustomInput";
import Remove from "@material-ui/icons/Remove";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Check from "@material-ui/icons/Check";
import Checkbox from "@material-ui/core/Checkbox";
import InputLabel from "@material-ui/core/InputLabel";

// Styles
import withStyles from "@material-ui/core/styles/withStyles";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle";
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle";
import userProfileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles";

import firebase from "services/firebase";
import moment from "moment";
import "moment/locale/es";
moment.locale("es");

const styles = {
  ...extendedTablesStyle,
  ...customSelectStyle,
  ...userProfileStyles,
  ...sweetAlertStyle
};

const DB_HOTEL = firebase.firestore().collection('hotels')

export default withStyles(styles)(({ classes }) => {
  const initialState = {
    code: Math.floor(1000 + Math.random() * 9000),
    rooms: [],
    checkIn: moment().format("L"),
    checkOut: "",
    owner: {
      doc: "",
      name: "",
      lastName: "",
      phone: "",
      email: "",
      companions: [],
      Quantity: []
    }
  };
  const [alert, setAlert] = useState(null);
  const history = useHistory();
  const rooms = useSelector(state => state.rooms.rooms);

  const dispatch = useDispatch();
  const [roomTypes, setRoomTypes] = useState("");

  const reducer = (state, newState) => ({ ...state, ...newState });
  const [state, setState] = useReducer(reducer, initialState);
  const tomorrow = Datetime.moment().subtract(0, "day");
  const valid = function(current) {
    return current.isAfter(tomorrow);
  };

  useEffect(() => {
    dispatch(getRooms(localStorage.getItem("hotel")));
    (async () => {
      let hotel = await DB_HOTEL.doc(localStorage.getItem("hotel")).get();
      let types = hotel.data().roomTypes;
      let items = types.map(item => item.name);
      setRoomTypes(items);
    })();
  }, [dispatch]);

  const companions = number =>
    number.map((a, key) => {
      return (
        <div
          key={`${a + key}`}
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap"
          }}
        >
          <GridItem xs={12} sm={6} md={6}>
            <CustomInput
              labelText="Nombre del Acompañante"
              id="name"
              // error={state.owner.companions[key].name.length >= 1 ? !/^[A-Za-z]+$/.test(state.owner.companions[key].name) === true : '' }
              value={state.owner.companions[key].name}
              formControlProps={{
                fullWidth: true,
                onChange: ({ target }) =>
                  setState(
                    state,
                    (state.owner.companions[key].name = target.value)
                  )
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={6} md={6}>
            <CustomInput
              labelText="Apellido del Acompañante"
              id="name"
              // error={state.owner.companions[key].name.length >= 1 ? !/^[A-Za-z]+$/.test(state.owner.companions[key].name) === true : '' }
              value={state.owner.companions[key].name}
              formControlProps={{
                fullWidth: true,
                onChange: event => {
                  setState(
                    state,
                    (state.owner.companions[key].lastName = event.target.value)
                  );
                }
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={6} md={6}>
            <CustomInput
              labelText="Documento del Acompañante"
              id="name"
              error={
                state.owner.companions[key].doc.length >= 1
                  ? !/^[0-9]*$/.test(state.owner.companions[key].doc) === true
                  : ""
              }
              value={state.owner.companions[key].doc}
              formControlProps={{
                fullWidth: true,
                onChange: event => {
                  setState(
                    state,
                    (state.owner.companions[key].doc = event.target.value)
                  );
                }
              }}
            />
          </GridItem>
          <FormControlLabel
            control={
              <Checkbox
                tabIndex={-1}
                onClick={() =>
                  setState(
                    state,
                    (state.owner.companions[key].isChild = !state.owner
                      .companions[key].isChild)
                  )
                }
                checkedIcon={<Check className={classes.checkedIcon} />}
                icon={<Check className={classes.uncheckedIcon} />}
                classes={{
                  checked: classes.checked,
                  root: classes.checkRoot
                }}
              />
            }
            classes={{ label: classes.label }}
            label="Menor "
          />
          <GridItem xs={1} sm={1} md={1} style={{ alignSelf: "center" }}>
            <Button
              round
              color={"danger"}
              className={classes.actionButton + " " + classes.actionButtonRound}
              key={key}
              onClick={() => {
                setState(state, state.owner.companions.splice(key, 1));
                setState(state, state.owner.Quantity.splice(key, 1));
              }}
            >
              <Remove className={classes.icon} />
            </Button>
          </GridItem>
        </div>
      );
    });
  const handleRooms = auxRooms =>
    auxRooms.map((room, key) => {
      return (
        <div key={`${room + key}`} style={{ width: "100%", display: "flex" }}>
          <GridItem xs={12} sm={12} md={12}>
            <CustomInput
              labelText="Tipo de Habitación"
              id="type"
              value={room.type}
              formControlProps={{ fullWidth: true }}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={5}>
            <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel
                htmlFor="simple-select"
                className={classes.selectLabel}
              >
                Elegir Habitación
              </InputLabel>
              <Select
                MenuProps={{ className: classes.selectMenu }}
                classes={{ select: classes.select }}
                value={room.number}
                onChange={({ target }) =>
                  setState(state, (room.number = target.value))
                }
                inputProps={{ name: "simpleSelect", id: "simple-select" }}
              >
                <MenuItem disabled classes={{ root: classes.selectMenuItem }}>
                  Elegir Habitación
                </MenuItem>
                {rooms
                  .filter(
                    item => item.type === room.type && item.available === true
                  )
                  .map((choice, key) => {
                    return (
                      <div key={`${choice + key}`}>
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected
                          }}
                          value={choice.number}
                        >
                          {choice.number}
                        </MenuItem>
                      </div>
                    );
                  })}
              </Select>
            </FormControl>
          </GridItem>
          <GridItem xs={1} sm={1} md={1} style={{ alignSelf: "center" }}>
            <Button
              round
              color={"danger"}
              className={`${classes.actionButton} ${classes.actionButtonRound}`}
              key={key}
              onClick={() => setState(state, state.rooms.splice(key, 1))}
            >
              <Remove className={classes.icon} />
            </Button>
          </GridItem>
        </div>
      );
    });

  return (
    <div style={{ width: "100%", heigth: "100%" }}>
      <GridItem xs={12} sm={12} md={12} lg={12}>
        <Card>
          <CardHeader>
            <h4 style={{ fontSize: "35px", userSelect: 'none' }} className={classes.cardTitle}>
              Check In
            </h4>
          </CardHeader>
          <CardBody>
            <Accordion
              content={<div>asd</div>}
              active={true}
              collapses={[
                {
                  title: "Información",
                  content: (
                    <GridContainer>
                      <GridItem
                        xs={12}
                        sm={12}
                        md={6}
                        style={{ alignSelf: "center" }}
                      >
                        <CustomInput
                          labelText="Fecha de Ingreso"
                          id="type"
                          value={state.checkIn}
                          formControlProps={{ fullWidth: true }}
                        />
                      </GridItem>
                      <GridItem
                        xs={12}
                        sm={12}
                        md={6}
                        style={{ alignSelf: "center" }}
                      >
                        <Datetime
                          timeFormat={false}
                          closeOnSelect
                          isValidDate={valid}
                          value={state.checkOut}
                          inputProps={{ placeholder: "Fecha de Salida" }}
                          onChange={event => {
                            setState(
                              state,
                              (state.checkOut = event.format("L"))
                            );
                          }}
                          formControlProps={{ fullWidth: true }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          disabled
                          labelText="Codigo"
                          id="code"
                          value={state.code}
                          formControlProps={{ fullWidth: true }}
                        />
                      </GridItem>
                    </GridContainer>
                  )
                },
                {
                  title: "Habitación",
                  content: (
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={5}>
                        <div style={{ alignSelf: "center" }}>
                          <FormControl
                            fullWidth
                            className={classes.selectFormControl}
                          >
                            <InputLabel
                              htmlFor="simple-select"
                              className={classes.selectLabel}
                            >
                              Agregar Habitación
                            </InputLabel>
                            <Select
                              value=""
                              MenuProps={{ className: classes.selectMenu }}
                              classes={{ select: classes.select }}
                              inputProps={{
                                name: "simpleSelect",
                                id: "simple-select"
                              }}
                            >
                              <MenuItem
                                style={{ alignSelf: "center" }}
                                disabled
                                classes={{ root: classes.selectMenuItem }}
                              >
                                Nueva Habitación
                              </MenuItem>
                              {roomTypes.length >= 1 &&
                                roomTypes.map((service, key) => {
                                  console.log(service);
                                  return (
                                    <div key={`${service + key}`}>
                                      <MenuItem
                                        classes={{
                                          root: classes.selectMenuItem,
                                          selected:
                                            classes.selectMenuItemSelected
                                        }}
                                        value={service}
                                        onClick={event => {
                                          setState(
                                            state,
                                            state.rooms.push({
                                              type: event.target.outerText,
                                              number: ""
                                            })
                                          );
                                        }}
                                      >
                                        {service}
                                      </MenuItem>
                                    </div>
                                  );
                                })}
                            </Select>
                          </FormControl>
                        </div>
                      </GridItem>
                      {state.rooms.length >= 1 ? handleRooms(state.rooms) : ""}
                    </GridContainer>
                  )
                },
                {
                  title: "Cliente",
                  content: (
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Nombre del Titular"
                          id="name"
                          error={
                            state.owner.name.length >= 1
                              ? !/^[A-Za-z]+$/.test(state.owner.name) === true
                              : false
                          }
                          value={state.owner.name}
                          formControlProps={{
                            fullWidth: true,
                            onChange: event => {
                              setState(
                                state,
                                (state.owner.name = event.target.value)
                              );
                            }
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Apellido del Titular"
                          id="lastName"
                          error={
                            state.owner.lastName.length >= 1
                              ? !/^[A-Za-z]+$/.test(state.owner.lastName) ===
                                true
                              : false
                          }
                          value={state.owner.lastName}
                          formControlProps={{
                            fullWidth: true,
                            onChange: event => {
                              setState(
                                state,
                                (state.owner.lastName = event.target.value)
                              );
                            }
                          }}
                        />
                      </GridItem>

                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Documento"
                          id="document"
                          error={
                            state.owner.doc.length >= 1
                              ? !/^[0-9]*$/.test(state.owner.doc) === true
                              : false
                          }
                          value={state.owner.doc}
                          formControlProps={{
                            fullWidth: true,
                            onChange: event => {
                              setState(
                                state,
                                (state.owner.doc = event.target.value)
                              );
                            }
                          }}
                        />
                      </GridItem>

                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Correo Electronico"
                          id="email"
                          value={state.owner.email}
                          error={
                            state.owner.email.length >= 1
                              ? !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(
                                  state.owner.email
                                ) === true
                              : false
                          }
                          formControlProps={{
                            fullWidth: true,
                            onChange: event => {
                              setState(
                                state,
                                (state.owner.email = event.target.value)
                              );
                            }
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Teléfono Celular"
                          id="phone"
                          error={
                            state.owner.phone.length >= 1
                              ? !/^[0-9]*$/.test(state.owner.phone) === true
                              : false
                          }
                          formControlProps={{
                            fullWidth: true,
                            onChange: event => {
                              setState(
                                state,
                                (state.owner.phone = event.target.value)
                              );
                            }
                          }}
                        />
                      </GridItem>
                      <GridItem
                        xs={12}
                        sm={12}
                        md={7}
                        style={{
                          display: "flex",
                          marginBottom: 20,
                          marginTop: 20
                        }}
                      >
                        <Button
                          color="info"
                          onClick={() => {
                            setState(state, state.owner.Quantity.push([]));
                            setState(
                              state,
                              state.owner.companions.push({
                                name: "",
                                doc: "",
                                isChild: false
                              })
                            );
                          }}
                          className={classes.updateProfileButton}
                        >
                          Agregar Acompañantes
                        </Button>
                      </GridItem>
                    </GridContainer>
                  )
                },
                state.owner.Quantity.length >= 1 && {
                  title: "Acompañantes",
                  content: (
                    <GridContainer>
                      {companions(state.owner.Quantity)}
                    </GridContainer>
                  )
                }
              ]}
            />
            {
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center"
                }}
              >
                <Button
                  disabled={
                    state.rooms.length >= 1 &&
                    state.checkOut !== "" &&
                    state.rooms[0].number !== ""
                      ? false
                      : true
                  }
                  onClick={() => {
                    dispatch(checkIn(state, localStorage.getItem("hotel")));
                    setAlert(
                      <SweetAlert
                        success
                        style={{ display: "block", marginTop: "-200px" }}
                        title="Felicitaciones"
                        onConfirm={() => history.push("/admin/rooms")}
                        onCancel={() => setAlert(null)}
                        confirmBtnCssClass={
                          classes.button + " " + classes.success
                        }
                      >
                        ¡Se ha registrado exitosamente!
                      </SweetAlert>
                    );
                  }}
                  color="rose"
                  className={classes.updateProfileButton}
                >
                  Realizar CheckIn
                </Button>
              </div>
            }
          </CardBody>
        </Card>
      </GridItem>
      {alert}
    </div>
  );
});
