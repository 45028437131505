import React, { useState, useEffect, useRef } from 'react'
import { Switch, Route, useHistory } from 'react-router-dom'
import firebase from 'firebase/app'

// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import { useSelector, useDispatch } from "react-redux";
import { isLogin } from "redux/actions/auth";
import { getRooms } from "redux/actions/rooms";
import { getStaff } from "redux/actions/staff";
import CircularProgress from "@material-ui/core/CircularProgress";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import AdminNavbar from "components/Navbars/AdminNavbar";
import Footer from "components/Footer/Footer";
import Sidebar from "components/Sidebar/Sidebar";

import {
  dashRoutes,
  creatorRoutes,
  onCreationRoute,
  liteRoutes,
  graduatesRoutes,
} from "routes.js";

import appStyle from "assets/jss/material-dashboard-pro-react/layouts/adminStyle";
import LovableHotel from "assets/img/logo-lovablehotels.png";
import imagine1 from "assets/img/sidebar-1.jpg";

const DB_HOTEL = firebase.firestore().collection("hotels");

var ps;

export default withStyles(appStyle)(props => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [miniActive, setMiniActive] = useState(false);

  const { location } = props;
  const creator = location.pathname.split("/").filter(str => str === "creator");
  const hotelInfo = useSelector(state => state.hotel.hotelInfo);
  const ref = useRef("mainPanel");
  let onCreation = useRef(hotelInfo ? hotelInfo.onCreation : false);

  useEffect(() => dispatch(isLogin(history)), [dispatch, history]);


  // useEffect(() => {
  //   hotelInfo.hotel && (
  //     DB_HOTEL.doc(hotelInfo.hotel).collection('chat').onSnapshot(snap => {
  //       let counter = 0
  //       if (!snap.empty) {
  //         snap.forEach(chat => {
  //           let messages = chat.data().messages
  //           let notViewedMsgs = messages.filter(e => !e.viewed )
  //           counter = counter + notViewedMsgs.length
  //           dispatch({ type: 'NOT_VIEWED_MESSAGES', payload: { notViewedMessages: counter }})
  //         })
  //       }
  //     })
  //   )
  // }, [hotelInfo])

  // }, [dispatch, hotelInfo, onCreation]);

  useEffect(
    () => {
      if (navigator.platform.indexOf("Win") > -1) {
        ps = new PerfectScrollbar(props.refs.mainPanel, {
          suppressScrollX: true,
          suppressScrollY: false
        });
        document.body.style.overflow = "hidden";
      }
      window.addEventListener("resize", resizeFunction);

      if (props.history.location.pathname !== props.location.pathname) {
        console.log(props.refs)
        props.refs.mainPanel.scrollTop = 0;
        mobileOpen && setMobileOpen(false);
      }

      dispatch(getRooms(localStorage.getItem("hotel")));
      dispatch(getStaff(localStorage.getItem("hotel")));

      if (hotelInfo) onCreation.current = hotelInfo.onCreation;

      return () => {
        navigator.platform.indexOf("Win") > -1 && ps.destroy();
        window.removeEventListener("resize", resizeFunction);
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, hotelInfo, onCreation]
  );

  const resizeFunction = () => window.innerWidth >= 960 && setMobileOpen(false);
  const handleDrawerToggle = () => setMobileOpen(!mobileOpen);
  const getRoute = () => {
    return props.location.pathname !== "/admin/full-screen-maps";
  };
  const getActiveRoute = routes => {
    let activeRoute = "";

    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = this.getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) return collapseActiveRoute;
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        )
          return routes[i].name;
      }
    }
    return activeRoute;
  };

  const getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.collapse) return this.getRoutes(prop.views);
      if (prop.layout === "/admin")
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      return null;
    });
  };
  const sidebarMinimize = () => setMiniActive(!miniActive);
  const { classes, ...rest } = props;
  const mainPanel = `${classes.mainPanel}`;

  return hotelInfo !== "default" ? (
    <div className={classes.wrapper}>
      <Sidebar
        routes={
          creator[0] === "creator"
            ? creatorRoutes
            : onCreation.current
              ? onCreationRoute
              : hotelInfo.license === "lite"
                ? liteRoutes
                : hotelInfo.license === "graduates" ? graduatesRoutes
                  : dashRoutes
        }
        logoText={"lovable Hotels"}
        logo={LovableHotel}
        image={imagine1}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color={"#ff2068"}
        bgColor={"white"}
        miniActive={miniActive}
        {...rest}
      />
      <div className={mainPanel} ref={ref}>
        <AdminNavbar
          sidebarMinimize={sidebarMinimize}
          miniActive={miniActive}
          brandText={getActiveRoute(
            creator[0] === "creator"
              ? creatorRoutes
              : onCreation.current
                ? onCreationRoute
                : hotelInfo.license === "lite"
                  ? liteRoutes
                  : hotelInfo.license === "graduates" ? graduatesRoutes
                    : dashRoutes
          )}
          handleDrawerToggle={handleDrawerToggle}
          {...rest}
        />

        {getRoute() ? (
          <div className={classes.content}>
            <div className={classes.container}>
              <Switch>
                {getRoutes(
                  creator[0] === "creator"
                    ? creatorRoutes
                    : onCreation.current
                      ? onCreationRoute
                      : hotelInfo.license === "lite"
                        ? liteRoutes
                        : hotelInfo.license === "graduates" ? graduatesRoutes
                          : dashRoutes
                )}
              </Switch>
            </div>
          </div>
        ) : (
            <div className={classes.map}>
              <Switch>
                {getRoutes(
                  creator[0] === "creator"
                    ? creatorRoutes
                    : onCreation.current
                      ? onCreationRoute
                      : hotelInfo.license === "lite"
                        ? liteRoutes
                        : hotelInfo.license === "graduates" ? graduatesRoutes
                          : dashRoutes
                )}
              </Switch>
            </div>
          )}
        {getRoute() ? <Footer fluid /> : null}
      </div>
    </div>
  ) : (
      <div
        style={{
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <CircularProgress size={50} style={{ color: "#ff2068" }} />
      </div>
    );
});
