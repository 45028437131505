const initialState = {
  mainTasks: [],
  cleanTasks: [],
  taskModal: false,
  editTask: false,
  room: '',
  title: '',
  description: '',
  taskId: ''
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case 'UPDATE_TASKS':
      return { ...state, ...payload }
    case 'RESET_TASKS':
      return { ...initialState, mainTasks: state.mainTasks, cleanTask: state.cleanTask }
    default:
      return state
  }
}

