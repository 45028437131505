import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Button from '../components/button'
import { getService } from 'redux/actions/webappServices'

export default ({ room }) => {
	const dispatch = useDispatch()
	const service = useSelector(state => state.services.survey)
	const hotelId = localStorage.getItem('hotel')

	useEffect(() => dispatch(getService(hotelId, room, 'survey')), [room, dispatch, hotelId])
	
	const onClick = () => window.open('https://lovable.typeform.com/to/Y9pVsV')

	return <Button onClick={onClick} service={service} />
}
