import moment from 'moment'

const initialState = {
  updateEmergency: moment(),
  updateChat: moment(),
  updateCleanRoom: moment(),
  updateRestRoom: moment(),
  updateRequest: moment(),
  updateDontdisturb: moment(),
  updatePending: moment(),
  updateDue: moment(),
  updateSolved: moment(),
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case 'UPDATE_TIMES':
      return { ...state, ...payload }

    default:
      return state
  }
}
