import React from 'react'
import styles from './styles'

export default props => {
	const { image, labelBot, labelTop, status } = props.service
	return (
		<button onClick={() => props.onClick()} style={{ ...styles.buttons, display: status ? 'flex' : 'none' }}>
			<div>
				<img alt="" src={image} style={{ height: 50, width: 100 }} />
			</div>
			<div>
				<p style={styles.actions}>{labelTop}<br />{labelBot}</p>
			</div>
		</button>
	)
}