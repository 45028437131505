import React from 'react'
import { Provider } from 'react-redux'
import { CssBaseline } from '@material-ui/core'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'

import { store } from 'redux/store.js'

// Layouts
import AuthLayout from 'layouts/Auth.jsx'
import AdminLayout from 'layouts/Admin.jsx'
// Hotel creation
import Creator from 'adminPanel/index'
// Web App
import AppWeb from 'webapp/views/Home'
import AppChat from 'webapp/views/Chat'
import Information from 'webapp/views/Information'
import RoomService from 'webapp/views/RoomService'
import LoginAppWeb from 'webapp/views/Login'
import ProfileAppWeb from 'webapp/views/Profiles'

import 'assets/scss/material-dashboard-pro-react.scss?v=1.5.0'
import LogRocket from 'logrocket';
LogRocket.init('6pslwo/lovable-hotesl');
LogRocket.identify('THE_USER_ID_IN_YOUR_APP', {
  name: 'James Morrison',
  email: 'jamesmorrison@example.com',

  // Add your own custom user variables here, ie:
  subscriptionType: 'pro'
});
/*default material-ui theme generation*/
const theme = createMuiTheme()

export default () => {
  return (
    <Provider store={store}>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <Router>
          <Switch>
            <Route path="/admin" component={AdminLayout} />
            {/* Hotel Creation */}
            <Route exact path="/admin/creator" component={Creator} />
            {/* WebApp */}
            <Redirect exact from="/:hotel/app" to="/:hotel/app/auth" component={LoginAppWeb} />
            <Route exact path="/:hotel/app/auth" component={LoginAppWeb} />
            <Route exact path="/:hotel/app/profile" component={ProfileAppWeb} />
            <Route exact path="/:hotel/app/home" component={AppWeb} />
            <Route exact path="/:hotel/app/chat" component={AppChat} />
            <Route exact path="/:hotel/app/:room/information" component={Information} />
            <Route exact path="/:hotel/app/:room/menu" component={RoomService} />
            {/* Auth */}
            <Route exact path="/:hotelId" component={AuthLayout} />
            <Redirect from="/" to="/auth" component={AuthLayout} />
          </Switch>
        </Router>
      </MuiThemeProvider>
    </Provider>
  )
}
