import firebase from '../../services/firebase'
import axios from 'axios'

const hotel = firebase.firestore().collection('hotels')

export const getStaff = id => dispatch => {
  hotel.doc(id).collection('maintenanceUsers').orderBy('lastName').onSnapshot(snap => {
    let cleanUsers = []
    let mainUsers = []
    snap.forEach(user => {
      if (user.data().type === 'clean') {
        cleanUsers.push({ ...user.data(), id: user.id })
        dispatch({ type: 'UPDATE_STAFF', payload: { cleanUsers } })
      }
      if (user.data().type === 'maintenance'){
        mainUsers.push({ ...user.data(), id: user.id })
        dispatch({ type: 'UPDATE_STAFF', payload: { mainUsers } })
      }
    })
  })
}

export const addStaff = (id, data) => () => {
    hotel.doc(id).collection('maintenanceUsers').add({
        name: data.firstname,
        lastName: data.lastname,
        email: data.email,
        type: data.type,
        contraseña: Math.floor(Math.random() * 999999)
    })
}

export const changeStaff = (id, data) => () => {
    hotel.doc(id).collection('maintenanceUsers').doc(data.id).update({
        name: data.firstname,
        lastName: data.lastname,
        email: data.email
    })
}

export const eraseStaff = (id, data) => () => {
    hotel.doc(id).collection('maintenanceUsers').doc(data.id).delete()
}

export const resendPassword = data => () => {
    axios.post('https://hotels-prod-a5b59.firebaseapp.com/api/hotel/resendpassword', data)
    .then(() =>{

    })
    .catch(err => console.log(err))
}