const initialState = {
	notifications: []
}


export default (state = initialState, { type, payload }) => {

	switch (type) {
		case 'GET_NOTIFICATIONS':
			return {
				...state,
				notifications: payload
			}

		default:
			return state
	}
}