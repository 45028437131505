const initialState = {
  room: '',
  checkinCode: '',
  profiles: [],
  profile: '',
}

export default (state = initialState, { type, payload }) => {
	switch (type) {
		case 'SET_PROFILES':
			return {
				...state,
				...payload
			}

		default:
			return state
	}
}