const initialState = {
  mainUsers: [],
  tasks: [],
  userModal: false,
  taskModal:false,
  edit: false,
  updatePending: null,
  updateDue: null,
  updateSolved: null,
  selectedRooms: '',
  title: '',
  description: ''
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case 'UPDATE_MAINTENANCE':
      return {
        ...state,
        ...payload
      }

    default:
      return state
  }
}
