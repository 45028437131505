import React, { useEffect, useReducer } from "react";
import { useDispatch, } from "react-redux"

import firebase from '../services/firebase'
// Actions
import { userCreation } from '../redux/actions/hotelCreation'
// Icons&Core

import Check from "@material-ui/icons/Check";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

// Core Components
import Card from "../components/Card/Card";
import GridItem from "../components/Grid/GridItem";
import CardBody from "../components/Card/CardBody";
import CardHeader from "../components/Card/CardHeader";
import Button from "../components/CustomButtons/Button";
import Accordion from '../components/Accordion/Accordion'
import GridContainer from "../components/Grid/GridContainer";
import CustomInput from "../components/CustomInput/CustomInput";


// Styles
import withStyles from '@material-ui/core/styles/withStyles'
import sweetAlertStyle from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle'
import extendedTablesStyle from 'assets/jss/material-dashboard-pro-react/views/extendedTablesStyle'
import customSelectStyle from 'assets/jss/material-dashboard-pro-react/customSelectStyle'
import userProfileStyles from 'assets/jss/material-dashboard-pro-react/views/userProfileStyles'

const styles = {
	...extendedTablesStyle,
	...customSelectStyle,
	...userProfileStyles,
	...sweetAlertStyle
}

function getRandomInt(min, max) {
	min = Math.ceil(min);
	max = Math.floor(max);
	return Math.floor(Math.random() * (max - min)) + min
}
const dbHotel = firebase.firestore().collection('hotels')
export default withStyles(styles)(props => {
	const classes = props.classes
	const dispatch = useDispatch()
	const initialState = {
		code: getRandomInt(100000, 999999),
		email: "",
		password: "lovable123",
		license: { lite: false, graduates: false, full: true },
		settings:
		{
			campains: false,
			chat: false,
			checkIn: false,
			clean: false,
			emergency: false,
			roomService: false,
			surveys: false,
			transport: false
		}
		,
		services:
		{
			alarm: false,
			chat: false,
			clean: false,
			disturb: false,
			emergency: false,
			events: false,
			food: false,
			information: false,
			map: false,
			medic: false,
			reservation: false,
			ski: false,
			surveys: false,
			towels: false
		}


	}

	const reducer = (state, newState) => ({ ...state, ...newState })
	const [state, setState] = useReducer(reducer, initialState)
	const { settings, services, email, password, code, license } = state

	useEffect(() => {
		//Create an hotel ID for the user or linked for the user
		dbHotel.doc(code.toString()).get().then(room => room.exists ?
			setState(state, state.code = getRandomInt(100000, 999999))
			:
			console.log(`valid hotel name ${code}`))

	}, [state, code])


	const Items = (ListOfItems, option) => Object.keys(ListOfItems).map(item => {

		return (
			<div style={{ width: "100%", display: "flex" }}>
				<GridItem xs={12} sm={6} md={5}>
					<FormControlLabel
						control={
							<Checkbox
								tabIndex={-1}
								checked={state[option][item]}
								onClick={() => {
									if (item === "lite") {
										setState(state, state[option][item] = !state[option][item])
										setState(state, state.settings = {
											campains: true,
											chat: true,
											checkIn: false,
											clean: true,
											emergency: true,
											roomService: true,
											surveys: true,
											transport: false
										})
										setState(state, state.services =
										{
											alarm: false,
											chat: true,
											clean: true,
											disturb: true,
											emergency: true,
											events: false,
											food: true,
											information: false,
											map: false,
											medic: false,
											reservation: false,
											ski: false,
											surveys: false,
											towels: false
										})

									}
									else setState(state, state[option][item] = !state[option][item])

								}}
								checkedIcon={<Check className={classes.checkedIcon} />}
								icon={<Check className={classes.uncheckedIcon} />}
								classes={{
									checked: classes.checked,
									root: classes.checkRoot
								}}
							/>
						}
						classes={{ label: classes.label }}
						label={`${item}`}
					/>
				</GridItem>

			</div>
		)
	})

	return (
		<div style={{ width: '100%', heigth: '100%' }}>
			<GridItem xs={12} sm={12} md={6} lg={12}>
				<Card>
					<CardHeader style={{ display: "flex", justifyContent: "center" }}>
						<h4 style={{ fontSize: '35px' }} className={classes.cardTitle}>Creacion De Hoteles</h4>
					</CardHeader>
					<CardBody>
						<Accordion
							active={true}
							collapses={[
								{
									title: "Usuario",
									content:
										<GridContainer>
											<GridItem xs={12} sm={12} md={6}>
												<CustomInput
													labelText="Correo Electronico del Hotel"
													id="type"
													value={email}
													formControlProps={{
														fullWidth: true,
														onChange: event => {
															setState(state, state.email = event.target.value)
														}
													}}
												/>
											</GridItem>
											<GridItem xs={12} sm={12} md={6}>
												<CustomInput
													labelText="Contraseña "
													id="code"
													value={password}
													formControlProps={{
														fullWidth: true,
														onChange: event => {
															setState(state, state.password = event.target.value)
														}
													}}
												/>
											</GridItem>
										</GridContainer>
								}, {

									title: "Licencias Vigentes",
									content:
										<div style={{ display: "flex", flexDirection: "column" }}>
											{Items(license, "license")}
										</div>

								},
								{
									title: "Productos",
									content:
										<div style={{ display: "flex", flexDirection: "column" }}>
											{Items(settings, "settings")}
										</div>
								},
								{
									title: "Servicios",
									content:
										<div style={{ display: "flex", flexDirection: "column" }}>
											{Items(services, "services")}
										</div>
								}
							]}
						/>
						<div style={{ display: "flex", width: "100%", justifyContent: "center", marginTop: 30 }}>

							<Button
								onClick={() => {

									dispatch(userCreation(email, password, settings, services, code, license["lite"] ? "lite" : license["graduates"] ? "graduates" : "full"))
								}}
								color="rose"
								className={classes.updateProfileButton}

							>
								Crear Hotel
	              </Button>
						</div>
					</CardBody>
				</Card>
			</GridItem>
		</div >
	)

})


