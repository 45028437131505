import firebase from '../../services/firebase'

const hotel = firebase.firestore().collection('hotels')

export const getNotifications = hotelId => dispatch => {
	hotel.doc(hotelId).collection('rooms').onSnapshot(rooms => {
		let notifications = []
		let emergencies = []

		return rooms.docs.forEach(room => {
			room.data().services.map(service => (
				service === 'Emergencia'
					? emergencies.push({ room: room.data().number, service: service })
					: notifications.push({ room: room.data().number, service: service })
			))

			dispatch({ type: 'SET_EMERGENCIES', payload: emergencies })
			dispatch({ type: 'GET_NOTIFICATIONS', payload: notifications })
		})
	})
}
