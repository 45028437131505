import React from 'react'
export default function FoodsCard(props) {

	const services = Object.keys(props).map(category => category)
	return (
		<div style={{ display: "flex", justifyContent: "center", flex: 1, flexDirection: "column", overflow: "auto", padding: "0 30px 0 30px" }}>
			<h1 style={{
				width: 113,
				alignSelf: "center", height: 34,
				fontFamily: "Lato",
				fontSize: 18,
				fontWeight: 900,
				fontStretch: "normal",
				fontStyle: "normal",
				lineHeight: 2.11,
				letterSpacing: "normal",
				color: "black",
			}}>
				Room Service
            </h1>
			{
				services.map((service, key) => {
					return (<div key={key} style={{ flex: 1 }}>
						<h2 style={{
							marginTop: 30,

							alignSelf: "center", height: 7,
							fontFamily: "Lato",
							fontSize: 16,
							fontWeight: 900,
							color: "black",
						}}>{service}</h2>
						<div style={{ display: "flex", flexDirection: "column" }}>
							{props[service].map((food, key) => {

								return (
									<div style={{ flexDirection: "row", display: "flex", borderBottomWidth: "100%", borderBottom: "2px solid #e4e4e4e4" }}>
										<div style={{ width: 100, height: 85 }}>
											<img alt='Logo' src={food.img} style={{ width: "100%" }} />
										</div>
										<div style={{ display: "flex", flex: 4, justifyContent: "space-between", alignItems: "center" }}>
											<div style={{ padding: 10 }}>


												<p style={{
													margin: 0,

													alignSelf: "center", fontFamily: "Lato",
													fontSize: 13,
													fontWeight: 900,
													fontStretch: "normal",
													fontStyle: "normal",
													lineHeight: 1.5,
													maxWidth: 80,
													letterSpacing: "normal",
													color: "black",
												}}>{food.name}</p>
											</div>

											<div style={{ padding: 10 }} >

												<p style={{
													margin: 0,

													fontFamily: "Lato",
													fontSize: 13,
													fontStretch: "normal",
													fontStyle: "normal",
													lineHeight: 1.5,
													letterSpacing: "normal",
													color: "#000000",
												}}>{food.price}</p>
											</div>

										</div>
									</div>
								)
							})}


						</div>

					</div>)
				})
			}
		</div >
	)
}
