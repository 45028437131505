import React from 'react'
import { useHistory } from 'react-router-dom'

// @material-ui/icons
import { ArrowBack } from '@material-ui/icons'
import Info from '@material-ui/icons/Info'
import HelpOutline from '@material-ui/icons/HelpOutline'

import withStyles from '@material-ui/core/styles/withStyles'

// core components
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import NavPills from 'components/NavPills/NavPills.js'
import Card from 'components/Card/Card.js'
import CardHeader from 'components/Card/CardHeader.js'
import CardBody from 'components/Card/CardBody.js'

const styles = {
	pageSubcategoriesTitle: {
		color: '#3C4858',
		textDecoration: 'none',
		textAlign: 'center',
	},
	cardCategory: {
		margin: '0',
		color: '#999',
	},
	buttonBack: {
		display: 'block',
		paddingTop: 10,
		paddingLeft: 10
	}
}

const services = [
	'Amenities',
	'Frigobar',
	'Gimnasio',
	'Aire acondicionado',
	'Bañera',
	'Bar',
	'Batas(Únicamente Suite)',
	// 'Business Center',
	// 'Caja de seguridad',
	// 'DDI y DDN',
	// 'Desayuno buffet americano(Lunes a viernes 06: 30 a 10: 30 / Sábado, domingo y feriados 07: 00 a 11: 00)',
	// 'Estacionamiento propio',
	// 'Grupo electrógeno con cobertura a todo el equipamiento del hotel',
	// 'Habitaciones para personas con capacidad diferente.',
	// 'Restaurante',
	// 'Room Service',
	// 'Sala de reuniones',
	// 'Salón para Eventos',
	// 'Sauna',
	// 'Secador de pelo',
	// 'Servicio de lavandería',
	// 'Sistema de aislamiento acústico, con vidrios de doble grosor',
	// 'TV LCD por cable',
	'WIFI'
]

const Information = ({ room, classes }) => {
	const hotel = localStorage.getItem('hotel')
	const history = useHistory()

	return (
		<div style={{ backgroundRepeat: 'no-repeat', display: 'flex', flexDirection: 'column' }}>
			<div onClick={() => history.push(`/${hotel}/app/${room}`)} style={styles.buttonBack}>
				<ArrowBack />
			</div>
			<GridContainer justify="center" >
				<GridItem xs={10} sm={10} md={10}>
					<h3 className={classes.pageSubcategoriesTitle}>
						SeaSide Beach Hotel
					</h3>
					<NavPills
						color="primary"
						alignCenter
						tabs={[
							{
								tabButton: 'Servicios',
								tabIcon: HelpOutline,
								tabContent: (
									<Card>
										<CardHeader>
											<h4 className={classes.cardTitle}>
												Ofrecemos los Siguientes Servicios:
											</h4>
										</CardHeader>
										<CardBody>
											<lo>
												{
													services.map((service, key) => {
														return <li key={key}>{service}</li>
													})
												}
											</lo>
										</CardBody>
									</Card>
								)
							},
							{
								tabButton: 'Sobre Nosotros',
								tabIcon: Info,
								tabContent: (
									<Card>
										<CardHeader>
											<h4 className={classes.cardTitle}>El Hotel</h4>
										</CardHeader>
										<CardBody>
											SeaSide Beach Hotel es un hotel en Buenos Aires de categoría 5 estrellas.
											Está ubicado en pleno corazón del centro porteño, a sólo 200 metros de la calle Florida y a 400 metros de Puerto Madero.
											SeaSide Beach Hotel cuenta con 152 habitaciones, salones para eventos y convenciones y ofrece todos los servicios y comodidades orientados a satisfacer las necesidades de sus huéspedes en sus viajes de negocios o placer.
											{/* Además, SeaSide Beach Hotel en Buenos Aires tiene en su desayunador con alimentos aptos para celíacos. */}
										</CardBody>
									</Card>
								)
							}
						]}
					/>
				</GridItem>
			</GridContainer>
		</div>
	)
}

export default withStyles(styles)(Information)

