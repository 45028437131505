import React from 'react'
import { useHistory } from "react-router-dom"
import FoodCard from "../../components/foodCard"
import { ArrowBack } from '@material-ui/icons'
import Cafe from "./menus/cafe.svg"
import Tostado from "./menus/tostado.svg"
import Hamburguesa from "./menus/hamburguesa.svg"
import Jugo from "./menus/smoothie.svg"

const RoomService = props => {
  const history = useHistory()
  const { match } = props
  const hotel = localStorage.getItem("hotel")
  const room = match.params.room
  const foods = {
    Sandwiches: [{
      img: Tostado,
      name: "Hamburgesa Completa ",
      price: "$300"
    },
    {
      img: Tostado,
      name: "Hamburgesa Completa ",
      price: "$300"
    },
    {
      img: Tostado,
      name: "Hamburgesa Completa ",
      price: "$300"
    },
    {
      img: Tostado,
      name: "Hamburgesa Completa ",
      price: "$300"
    },
    {
      img: Tostado,
      name: "Hamburgesa Completa ",
      price: "$300"
    }]
    , Cafeteria: [
      {
        img: Cafe,
        name: "Hamburgesa Completa ",
        price: "$300"
      }, {
        img: Cafe,
        name: "Hamburgesa Completa ",
        price: "$300"
      }, {
        img: Cafe,
        name: "Hamburgesa Completa ",
        price: "$300"
      }, {
        img: Cafe,
        name: "Hamburgesa Completa ",
        price: "$300"
      }
    ],
    Carnes: [
      {
        img: Hamburguesa,
        name: "Hamburgesa Completa ",
        price: "$300"
      }, {
        img: Hamburguesa,
        name: "Hamburgesa Completa ",
        price: "$300"
      }, {
        img: Hamburguesa,
        name: "Hamburgesa Completa ",
        price: "$300"
      }, {
        img: Hamburguesa,
        name: "Hamburgesa Completa ",
        price: "$300"
      }],
    Vinos: [
      {
        img: Jugo,
        name: "Hamburgesa Completa ",
        price: "$300"
      }, {
        img: Jugo,
        name: "Hamburgesa Completa ",
        price: "$300"
      }, {
        img: Jugo,
        name: "Hamburgesa Completa ",
        price: "$300"
      }, {
        img: Jugo,
        name: "Hamburgesa Completa ",
        price: "$300"
      }]
  }

  return (
    <div>
      <button
        onClick={() => history.push(`/${hotel}/app/${room}`)}
        style={{ border: 'none', background: 'none', marginLeft: 10, marginTop: 16, color: 'black' }}
      >
        <ArrowBack />
      </button>
      <FoodCard {...foods} />
    </div>
  )
}


export default RoomService