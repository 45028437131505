import React, { useState, useReducer } from "react";
import { useDispatch, useSelector, } from "react-redux"
import { useHistory } from "react-router-dom"
import { hotelCreation } from "../redux/actions/hotelCreation"
import { isLogin } from "../redux/actions/auth"
// Actions


// Core Components
import SweetAlert from "react-bootstrap-sweetalert";
import Card from "../components/Card/Card";
import GridItem from "../components/Grid/GridItem";
import CardBody from "../components/Card/CardBody";
import CardHeader from "../components/Card/CardHeader";
import Button from "../components/CustomButtons/Button";
import Accordion from '../components/Accordion/Accordion'
import GridContainer from "../components/Grid/GridContainer";
import CustomInput from "../components/CustomInput/CustomInput";
import { SketchPicker } from 'react-color';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Check from "@material-ui/icons/Check";
import Checkbox from "@material-ui/core/Checkbox";

// Styles
import withStyles from '@material-ui/core/styles/withStyles'
import sweetAlertStyle from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle'
import extendedTablesStyle from 'assets/jss/material-dashboard-pro-react/views/extendedTablesStyle'
import customSelectStyle from 'assets/jss/material-dashboard-pro-react/customSelectStyle'
import userProfileStyles from 'assets/jss/material-dashboard-pro-react/views/userProfileStyles'

const styles = {
  ...extendedTablesStyle,
  ...customSelectStyle,
  ...userProfileStyles,
  ...sweetAlertStyle
}


let currenTypeTitle = ""

export default withStyles(styles)(({ classes }) => {
  const initialState = {
    hotelName: "",
    email: "",
    password: "",
    phone: "",
    direction: "",
    primaryColor: "ff2068",
    fontColor: "black",
    backgroundColor: "white",
    rooms: [],
  }
  const dispatch = useDispatch()
  const history = useHistory()
  const [newRooms, setNewRooms] = useState("")
  const [pages, setPages] = useState([[true], [], []])
  const [newType, setNewType] = useState("")
  const [currentT, setCurrentT] = useState("")

  const [alert, setAlert] = useState(false)
  const reducer = (state, newState) => ({ ...state, ...newState })
  const [state, setState] = useReducer(reducer, initialState)

  const hotelId = useSelector(state => state.auth.hotel)
  const { hotelName, email, password, phone, direction } = state
  const [premiunRooms, setPremiunRooms] = useState(false)
  const TypesItems = arr => arr.map(type => {
    return (
      <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
        <Button
          onClick={() => {
            for (let i = 0; i < state.rooms.length; i++) {
              if (state.rooms[i].name === type.name) {
                currenTypeTitle = type.name
                setCurrentT(type.rooms)
              }
            }
          }}
          style={{ maxWidth: "25%", height: "70%" }}
          color="rose"
          className={classes.updateProfileButton}
        >
          <p> {type.name}</p>
        </Button>
        <Button
          onClick={() => {
            for (let i = 0; i < state.rooms.length; i++) {
              if (state.rooms[i].name === type.name) {
                if (currenTypeTitle === type.name) {
                  setState(state, state.rooms.splice(i, 1))
                  currenTypeTitle = ""
                  setCurrentT("")
                }

              } else setState(state, state.rooms.splice(i, 1))
            }
          }}
          style={{ maxWidth: "25%", height: "70%" }}
          color={`primary`}
          className={classes.updateProfileButton}
        >
          <p> {`Eliminar ${type.name}`}</p>
        </Button>

      </div>)
  })
  const AddRooms = () => {
    for (let i = 0; i <= state.rooms.length - 1; i++) {
      if (currenTypeTitle === state.rooms[i].name) {
        let splitted = newRooms.trim().split(",")

        for (let j = 0; j <= splitted.length - 1; j++) {
          if (splitted[j].length > 1) {
            for (let x = 0; x <= splitted[j].trim().split(" ").length - 1; x++) {

              splitted[j].split(" ")[x] &&
                setState(state, state.rooms[i].rooms.push(
                  {
                    premiun: premiunRooms, number: splitted[j].split(" ")[x]
                  }))
            }
          }
          else if (splitted[j]) setState(state, state.rooms[i].rooms.push(
            {
              premiun: premiunRooms, number: splitted[j]
            }
          ))
        }
        setPremiunRooms(false)
        setNewRooms("")
        break
      }

    }

  }

  const showRooms = (rooms) => rooms.map((room) => {

    return (
      <Button
        onClick={() => {
          for (let i = 0; i <= state.rooms.length - 1; i++) {
            if (currenTypeTitle === state.rooms[i].name) {
              setState(state, state.rooms[i].rooms.splice(state.rooms[i].rooms.indexOf(room), 1))
              break
            }
          }

        }}
        style={{ margin: 10 }}
        color="white"
        className={classes.updateProfileButton}
      >
        <p style={{ fontSize: "100%", fontFamily: "sans-serif", color: "black" }}> {room.number ? room.number : room}</p>
      </Button>
    )

  })

  return (
    <div style={{ width: '100%', heigth: '100%' }}>
      <GridItem xs={12} sm={12} md={6} lg={12}>
        <Card>
          <CardHeader style={{ display: "flex", justifyContent: "center" }}>
            <h4 style={{ fontSize: '35px' }} className={classes.cardTitle}>Creación De Hotel</h4>
          </CardHeader>
          <CardBody>
            <Accordion
              active={true}
              collapses={
                pages[0][0] === true ?
                  [
                    {
                      title: "Información Del Hotel",
                      content:
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={6}>
                            <CustomInput
                              labelText="Correo Electronico del Hotel"
                              id="type"
                              value={email}
                              formControlProps={{
                                fullWidth: true,
                                onChange: event => {
                                  setState(state, state.email = event.target.value)
                                }
                              }}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={6}>
                            <CustomInput
                              labelText="Contraseña "
                              id="code"
                              value={password}
                              formControlProps={{
                                fullWidth: true,
                                onChange: event => {
                                  setState(state, state.password = event.target.value)
                                }
                              }}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={6}>
                            <CustomInput
                              labelText="Nombre del Hotel"
                              id="code"
                              value={hotelName}
                              formControlProps={{
                                fullWidth: true,
                                onChange: event => {
                                  setState(state, state.hotelName = event.target.value)
                                }
                              }}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={6}>
                            <CustomInput
                              labelText="Telefono"
                              id="code"
                              value={phone}
                              formControlProps={{
                                fullWidth: true,
                                onChange: event => {
                                  setState(state, state.phone = event.target.value)
                                }
                              }}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={6}>
                            <CustomInput
                              labelText="Dirección "
                              id="code"
                              value={direction}
                              formControlProps={{
                                fullWidth: true,
                                onChange: event => {
                                  setState(state, state.direction = event.target.value)
                                }
                              }}
                            />
                          </GridItem>
                        </GridContainer>
                    },


                  ] : pages[1][0] === true ?
                    [{
                      title: "Diseño Del Hotel",
                      content:
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={6}>
                            <p style={{ color: "#AAAAAA " }}>Color Primario</p>
                            <SketchPicker
                              color={state.primaryColor}
                              onChangeComplete={(color) => setState(state, state.primaryColor = color)}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={6}>
                            <p style={{ color: "#AAAAAA " }}>Color De Letras</p>
                            <SketchPicker
                              color={state.fontColor}
                              onChangeComplete={(color) => setState(state, state.fontColor = color)}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={6}>
                            <p style={{ color: "#AAAAAA " }}>Color De Fondo</p>
                            <SketchPicker
                              color={state.backgroundColor}
                              onChangeComplete={(color) => setState(state, state.backgroundColor = color)}
                            />

                          </GridItem>

                        </GridContainer>
                    }]
                    :
                    [
                      {
                        title: "Creación de Habitaciones",
                        content:
                          <GridContainer>
                            <GridItem xs={12} sm={12} md={6}>
                              <CustomInput
                                labelText="Agregar un Tipo de Habitación"
                                id="type"
                                value={newType}
                                formControlProps={{
                                  fullWidth: true,
                                  onChange: event => {

                                    setNewType(event.target.value)
                                  }
                                }}
                              />
                            </GridItem>
                            <div style={{ marginTop: 30 }}>
                              <Button
                                style={{ bottom: "25%" }}
                                color="rose"
                                className={classes.updateProfileButton}
                                disabled={!newType.length}
                                onClick={() => {
                                  setState(state, state.rooms.push({
                                    name: newType.trim(),
                                    rooms: []
                                  }))
                                  setNewType("")
                                }}>
                                Agregar Nuevo Tipo
                              </Button>

                            </div>
                            {state.rooms.length >= 1 ?
                              TypesItems(state.rooms)
                              : null
                            }

                          </GridContainer>
                      },
                      currenTypeTitle.length ?
                        {
                          title: currenTypeTitle.toUpperCase(),
                          content:
                            <GridContainer>
                              <GridItem xs={12} sm={12} md={6}>
                                <CustomInput
                                  labelText="Agregar Habitaciones"
                                  id="code"
                                  value={newRooms}
                                  formControlProps={{
                                    fullWidth: true,
                                    onChange: event => {
                                      setNewRooms(event.target.value)
                                    }
                                  }}
                                />
                              </GridItem>
                              <div style={{ marginTop: 30, display: "flex" }}>
                                <div>

                                  <Button
                                    style={{ bottom: "25%" }}
                                    color="rose"
                                    className={classes.updateProfileButton}
                                    onClick={() => AddRooms()}
                                    value={"Agregar Habitaciones"}
                                  > Agregar Habitaciones</Button>
                                </div>
                                <div style={{ marginLeft: 20 }}>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        tabIndex={-1}
                                        checked={premiunRooms}
                                        onClick={() => setPremiunRooms(true)}
                                        checkedIcon={<Check className={classes.checkedIcon} />}
                                        icon={<Check className={classes.uncheckedIcon} />}
                                        classes={{
                                          checked: classes.checked,
                                          root: classes.checkRoot
                                        }}
                                      />
                                    }
                                    classes={{ label: classes.label }}
                                    label="Premiun"
                                  />

                                </div>
                              </div>
                              <div style={{ display: "flex", flexDirection: "row", padding: "initial", width: "100%", margin: "1vh", flexWrap: "wrap" }}>
                                {showRooms(currentT)}
                              </div>

                            </GridContainer>
                        }
                        : {
                          title: "",
                          content: " "
                        }

                    ]}
            />
            {
              pages[0][0] === true ?
                <div style={{ display: "flex", width: "100%", justifyContent: "flex-end", marginTop: 30 }}>
                  <Button
                    onClick={() => {
                      setPages([[], [], [true]])

                    }}
                    color="rose"
                    className={classes.updateProfileButton}

                  >
                    Siguiente
                     </Button>

                </div>
                : pages[1][0] === true ?
                  <div style={{ display: "flex" }}>
                    <div style={{ width: "100%", justifyContent: "flex-start", marginTop: 30 }}>
                      <Button
                        onClick={() => {
                          setPages([[true], [], []])

                        }}
                        color="rose"
                        className={classes.updateProfileButton}

                      >
                        Anterior
                       </Button>
                    </div>

                    <div style={{ width: "100%", justifyContent: "flex-end", marginTop: 30 }}>
                      <Button
                        onClick={() => {
                          setPages([[], [], [true]])

                        }}
                        color="rose"
                        className={classes.updateProfileButton}

                      >
                        Siguiente
                    </Button>
                    </div>
                  </div>
                  :
                  <div style={{ display: "flex" }}>
                    <div style={{ width: "100%", justifyContent: "flex-start", marginTop: 30 }}>
                      <Button
                        onClick={() => {
                          setPages([[true], [], []])

                        }}
                        color="rose"
                        className={classes.updateProfileButton}

                      >
                        Anterior
                       </Button>
                    </div>

                    <div style={{ width: "100%", justifyContent: "flex-end", marginTop: 30 }}>
                      <Button
                        onClick={() => {

                          dispatch(hotelCreation(hotelId, state))
                          // is necessary to relog the info of the client to make a good performance  of the info of the "OnCreation"
                          setAlert(<SweetAlert
                            warning
                            style={{ display: 'block', marginTop: '-200px' }}
                            title='Felicidades ya tu hotel esta Creado!'
                            onConfirm={() => {
                              dispatch(isLogin(history))
                              setAlert(false)
                            }}
                            confirmBtnCssClass={`${classes.button} ${classes.success}`}
                            cancelBtnCssClass={`${classes.button} ${classes.danger}`}
                            confirmBtnText='IR A TU HOTEL!'
                          />

                          )
                        }}

                        color="rose"
                        className={classes.updateProfileButton}
                      >
                        Finalizar Hotel
                          </Button>
                    </div>
                    {alert}
                  </div>
            }
          </CardBody>
        </Card>
      </GridItem>
    </div >
  )
})


