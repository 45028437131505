import * as firebase from 'firebase'
import 'firebase/auth'
import 'firebase/firestore'

const config = {
  apiKey: "AIzaSyDIPo4-pU3DjBXS9HIzXePOB23IGgeausM",
  authDomain: "hotels-prod-a5b59.firebaseapp.com",
  databaseURL: "https://hotels-prod-a5b59.firebaseio.com",
  projectId: "hotels-prod-a5b59",
  storageBucket: "hotels-prod-a5b59.appspot.com",
  messagingSenderId: "876261457966",
  appId: "1:876261457966:web:637c234559dcdb6c5d9fbf"
}

const service = firebase.initializeApp(config)

export default service
