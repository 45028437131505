const initialState = {
  hotelInfo: 'default'
}

export default (state = initialState, action = {}) => {
  switch (action.type) {

    case 'SET_HOTEL':
      return {
        ...state,
        hotelInfo: action.payload
      }

    default:
      return state
  }
}
