import firebase from 'services/firebase'
import admin from "firebase/app"

const db = firebase.firestore().collection('hotels')

export const getRoomService = (roomNumber, hotelId) => dispatch => {
  db
    .doc(hotelId)
    .collection('rooms')
    .doc(roomNumber.toString())
    .onSnapshot(room => {
      if (room.data()) dispatch({ type: 'GET_WEBSERVICES', payload: room.data().services })
    })
}

export const liteEmergency = (hotelId, room) => dispatch => {
  let emergencyDB = db.doc(hotelId).collection('services').doc('emergency')

  emergencyDB.get()
    .then(emer => {
      let oldEmer = emer.data().rooms
      delete oldEmer[room.roomNumber]
      emergencyDB.update({ rooms: oldEmer })
    })
  
  db.doc(hotelId).collection('rooms').doc(room.roomNumber).update({
    services: admin.firestore.FieldValue.arrayRemove('Emergencia en Curso')
  })
}