import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardFooter from "components/Card/CardFooter";
import CardIcon from "components/Card/CardIcon";
import CardBody from "components/Card/CardBody";
import { Timeline, Equalizer } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core";
import chartsStyle from "assets/jss/material-dashboard-pro-react/views/chartsStyle";
import ChartistGraph from "react-chartist";
import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle";
import notificationsStyle from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.js";

const useStyle = makeStyles({
  ...chartsStyle,
  ...extendedTablesStyle,
  ...notificationsStyle,
  ...dashboardStyle
});

export default function Dashboard() {
  const dispatch = useDispatch();
  const classes = useStyle();
  const { updateEmergency, updateChat, notViewedMessages } = useSelector(
    state => state.dashboard
  );
  const { rooms } = useSelector(state => state.rooms);
  const { mainTasks } = useSelector(state => state.tasks);
  const { services } = useSelector(state => state.services);

  let emergencies = rooms.filter(room => room.services.includes("Emergencia"))
    .length;

  let chats = [];

  const [chatTime, setChatTime] = useState(moment(updateChat).from(moment()));
  const [emergencyTime, setEmergencyTime] = useState(
    moment(updateEmergency).from(moment())
  );

  useEffect(() => {
    !updateEmergency &&
      dispatch({
        type: "UPDATE_TIMES",
        payload: { updateEmergency: moment() }
      });
  }, [dispatch, emergencies, updateEmergency]);

  useEffect(() => {
    !updateChat &&
      dispatch({ type: "UPDATE_TIMES", payload: { updateChat: moment() } });
  }, [dispatch, chats.length, updateChat]);

  useEffect(() => {
    var chatInterval = setInterval(() => {
      setChatTime(moment(updateChat).from(moment()));
    }, 1000);
    var emergencyInterval = setInterval(() => {
      setEmergencyTime(moment(updateEmergency).from(moment()));
    }, 1000);
    return () => {
      clearInterval(chatInterval);
      clearInterval(emergencyInterval);
    };
  });

  let roomsInfo = [];
  let freeRooms = rooms.filter(room => room.available).length;
  let busyRooms = rooms.filter(room => !room.available).length;
  let bookedRooms = rooms.filter(room => room.booked).length;
  if (freeRooms) roomsInfo[0] = freeRooms;
  if (busyRooms) roomsInfo[1] = busyRooms;
  if (bookedRooms) roomsInfo[2] = bookedRooms;

  let cleanRooms = [];
  let noDisturb = rooms.filter(room => room.services.includes("No Molestar"))
    .length;
  let roomNotClean = rooms.filter(room => !room.clean).length;
  let cleanCall = rooms.filter(room => room.services.includes("Limpieza"))
    .length;
  if (noDisturb) cleanRooms[0] = noDisturb;
  if (roomNotClean) cleanRooms[1] = roomNotClean;
  if (cleanCall) cleanRooms[2] = cleanCall;

  let maintaneceTasks = [];
  let delayedTasks = mainTasks.filter(task =>
    moment().isAfter(moment(task.created).add(24, "hours"))
  ).length;
  let pendingTasks = mainTasks.filter(task => task.status === "pending").length;
  if (delayedTasks) maintaneceTasks[0] = delayedTasks;
  if (pendingTasks) maintaneceTasks[1] = pendingTasks;

  let roomService = [];
  let pendingServices = services.filter(service => service.status === "pending")
    .length;
  let completedServices = services.filter(
    service => service.status === "completed"
  ).length;
  if (pendingServices) roomService[0] = pendingServices;
  if (completedServices) roomService[1] = completedServices;

  const checksInfo = [
    {
      head: "Arrivos",
      color: "danger",
      data: { labels: ["4", "6"], series: [40, 60] },
      options: { height: "120px" },
      legends: ["Arrivos Pendientes", "Reservas Realizadas"]
    },
    {
      head: "Salidas",
      color: "warning",
      data: { labels: ["2", "2"], series: [2, 2] },
      options: { height: "120px" },
      legends: ["Pendientes", "Realizadas"]
    },
    {
      head: "Habitaciones",
      color: "warning",
      data: { labels: roomsInfo, series: roomsInfo },
      options: { height: "120px" },
      legends: ["Ocupadas", "Libres", "Reservadas"]
    },
    {
      head: "Habitaciones Limpias",
      color: "warning",
      data: { labels: cleanRooms, series: cleanRooms },
      options: { height: "120px" },
      legends: ["Pendientes", "No Molestar", "Pedidos limpieza"]
    },
    {
      head: "Mantenimiento",
      color: "danger",
      data: { labels: maintaneceTasks, series: maintaneceTasks },
      options: { height: "120px" },
      legends: ["Pendientes", "Retrasadas"]
    },
    {
      head: "Room Service",
      color: "danger",
      data: { labels: roomService, series: roomService },
      options: { height: "120px" },
      legends: ["Confirmar", "Confirmados"]
    }
  ];

  const cardsInfoEmergency = [
    {
      color: "rose",
      head: "Emergencias Actuales",
      amount: emergencies,
      lastUpdate: emergencyTime
    },
    {
      color: "rose",
      head: "Mensajes Sin Leer",
      amount: notViewedMessages,
      lastUpdate: chatTime
    }
  ];

  return (
    <div>
      <GridContainer>
        {rooms.length !== 0 &&
          cardsInfoEmergency.map((info, i) => {
            return (
              <GridItem md={6} key={i}>
                <Card
                  className="card-dashboard"
                  style={{ userSelect: "none", cursor: "default" }}
                >
                  <CardHeader stats icon>
                    <CardIcon color={info.color}>
                      <Equalizer />
                    </CardIcon>
                    <p className={classes.cardCategory}>{info.head}</p>
                    <h3 className={classes.cardTitle}>{info.amount}</h3>
                  </CardHeader>
                  <CardFooter style={{ height: 25 }} stats>
                    <div className={classes.stats}>
                      Actualizado {info.lastUpdate}
                    </div>
                  </CardFooter>
                </Card>
              </GridItem>
            );
          })}
        <br />
        {rooms.length !== 0 &&
          checksInfo.map((info, i) => {
            return (
              <GridItem md={4} key={i}>
                <Card
                  className="card-dashboard"
                  style={{ userSelect: "none", cursor: "default" }}
                >
                  <CardHeader color={info.color} icon>
                    <CardIcon color={info.color}>
                      <Timeline />
                    </CardIcon>
                    <h4 className={classes.cardIconTitle}>{info.head}</h4>
                  </CardHeader>
                  <CardBody>
                    <ChartistGraph
                      data={info.data}
                      type="Pie"
                      options={info.options}
                    />
                  </CardBody>
                  <CardFooter
                    style={{
                      height: "auto",
                      marginTop: 0,
                      marginBottom: 0,
                      paddingTop: 0
                    }}
                    stats
                    className={classes.cardFooter}
                  >
                    <div style={{ display: "flex" }}>
                      {info.legends.map((legend, i) => {
                        return (
                          <div
                            key={i}
                            style={{
                              display: "flex",
                              marginRight: "10px",
                              alignItems: "center",
                              padding: "5px 0"
                            }}
                          >
                            <i
                              className={
                                "fas fa-circle " +
                                (i === 0
                                  ? classes.danger
                                  : i === 1
                                  ? classes.info
                                  : classes.warning)
                              }
                              style={{ position: "inherit", fontSize: 20 }}
                            />
                            <p style={{ margin: 0 }}>
                              {legend} {` `}
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  </CardFooter>
                </Card>
              </GridItem>
            );
          })}
      </GridContainer>
    </div>
  );
}
