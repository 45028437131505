import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Button from '../components/button'
import { getService, changeStatus } from 'redux/actions/webappServices'

export default ({room}) => {
	const service = useSelector(state => state.services.medic)
	const dispatch = useDispatch()
	const hotelId = localStorage.getItem('hotel')
	const { name, key } = service

	useEffect(() => dispatch(getService(hotelId, room, 'medic')), [room, dispatch, hotelId])

	return <Button onClick={() => changeStatus(hotelId, room, name, key)} service={service} />
}