import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Switch, Route, useHistory } from "react-router-dom";
import { isLogin } from "redux/actions/auth";

import firebase from "services/firebase";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import CircularProgress from "@material-ui/core/CircularProgress";

// core components
import AuthNavbar from "components/Navbars/AuthNavbar";
import Footer from "components/Footer/Footer";
import LoginPage from "views/Pages/LoginPage";

import { dashRoutes, creatorRoutes } from "routes.js";

import pagesStyle from "assets/jss/material-dashboard-pro-react/layouts/authStyle";

import defaultBackground from "assets/img/login.jpeg";

const db = firebase.firestore().collection("hotels");

export default withStyles(pagesStyle)(props => {
  const history = useHistory();
  const dispatch = useDispatch();

  const hotelInfo = useSelector(state => state.hotel.hotelInfo);
  const login = useSelector(state => state.auth.isLogin);
  const { location } = props;
  const creator = location.pathname.split("/").filter(str => str === "creator");
  const [background, setBackground] = useState("");
  const { hotelId } = props.match.params;

  useEffect(() => {
    console.log(hotelId)
    hotelId !== "auth"
      ? db
        .doc(hotelId)
        .get()
        .then(data => {
          setBackground(data.data().styles.backgroundDashboard);
          dispatch({ type: "SET_HOTEL", payload: hotelId });
        })
        .catch(err => console.log(err))
      : dispatch({ type: "SET_HOTEL", payload: "default" });

    return () => false;
  }, [props.match.params, dispatch]);

  useEffect(() => {
    dispatch(isLogin(history));
    return () => false;
  }, [dispatch, history]);

  useEffect(() => {
    document.body.style.overflow = "unset";
    return () => false;
  }, []);

  const getRoutes = routes => {
    return routes.map((route, key) => {
      const { collapse, views, layout, path, component } = route;

      if (collapse) return getRoutes(views);

      if (layout === "/auth")
        return <Route path={layout + path} component={component} key={key} />;

      return null;
    });
  };

  const ref = useRef("wrapper");
  const { classes, ...rest } = props;
  let onCreation = hotelInfo ? hotelInfo.onCreation : null;

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        maxHeight: "100vh",
        overflow: "auto"
      }}
    >
      {hotelInfo === null || login === ("checking" || "active") ? (
        <div
          style={{
            position: "fixed",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <CircularProgress size={26} />
        </div>
      ) : (
          <div>
            <AuthNavbar {...rest} />
            <div className={classes.wrapper} ref={ref}>
              <div
                className={classes.fullPage}
                style={{
                  backgroundImage: `url(${
                    background ? background : defaultBackground
                    })`
                }}
              >
                <Switch>
                  <Route path="/:hotelId" component={LoginPage} />
                  {getRoutes(
                    creator[0] === "creator"
                      ? creatorRoutes
                      : onCreation
                        ? [dashRoutes[1]]
                        : dashRoutes
                  )}
                </Switch>
                <Footer white />
              </div>
            </div>
          </div>
        )}
    </div>
  );
});
