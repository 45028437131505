import React, { useReducer, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getRooms } from "redux/actions/rooms";

// Actions
import { checkIn } from "redux/actions/checkIn";
import Datetime from "react-datetime";

// SweetAlerts
import SweetAlert from "react-bootstrap-sweetalert";

// core components
import Card from "components/Card/Card";
import GridItem from "components/Grid/GridItem";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import Button from "components/CustomButtons/Button";
import Accordion from "components/Accordion/Accordion";
import GridContainer from "components/Grid/GridContainer";
import CustomInput from "components/CustomInput/CustomInput";


// Styles
import withStyles from "@material-ui/core/styles/withStyles";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle";
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle";
import userProfileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles";

//import firebase from "services/firebase";
import moment from "moment";
import "moment/locale/es";
moment.locale("es");

const styles = {
  ...extendedTablesStyle,
  ...customSelectStyle,
  ...userProfileStyles,
  ...sweetAlertStyle
};

//const DB_HOTEL = firebase.firestore().collection("hotels");

export default withStyles(styles)(({ classes }) => {
  const initialState = {
    code: Math.floor(1000 + Math.random() * 9000),
    rooms: [],
    checkIn: moment().format("L"),
    checkOut: "",
  };
  const [alert, setAlert] = useState(null);
  const history = useHistory();
  const rooms = useSelector(state => state.rooms.rooms);
  const dispatch = useDispatch();


  const reducer = (state, newState) => ({ ...state, ...newState });
  const [state, setState] = useReducer(reducer, initialState);
  const [currentRoom, setCurrentRoom] = useState("")

  const tomorrow = Datetime.moment().subtract(0, "day");
  const valid = function (current) {
    return current.isAfter(tomorrow);
  };

  useEffect(() => {
    dispatch(getRooms(localStorage.getItem("hotel")));
    // (async () => {
    //   let hotel = await DB_HOTEL.doc(localStorage.getItem("hotel")).get();
    //   let types = hotel.data().roomTypes;
    //   let items = types.map(item => item.name);
    //   setRoomTypes(items);
    // })();
  }, [dispatch]);



  const showRooms = (rooms) => rooms.map((room) => {
    return (
      <Button
        onClick={() => setCurrentRoom(room)}
        style={{ margin: 10 }}
        color="white"
        className={classes.updateProfileButton}
      >
        <p style={{ fontSize: "100%", fontFamily: "sans-serif", color: "black" }}> {room.number}</p>
      </Button>
    )
  })

  const showGraduates = (graduates) => graduates.map((graduate, key) => {
    return (
      <div
        key={`${graduate.email + key}`}
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap"
        }}
      >
        <GridItem xs={12} sm={12} md={8}>
          <CustomInput
            labelText="Correo del Egresado"
            id={`${key + "email"}`}
            // error={state.owner.companions[key].name.length >= 1 ? !/^[A-Za-z]+$/.test(state.owner.companions[key].name) === true : '' }
            value={graduate.email}
          // formControlProps={{
          //   fullWidth: true,
          //   onChange: ({ target }) =>
          //     setState(
          //       state,
          //       (state.owner.companions[key].name = target.value)
          //     )
          // }}
          />
        </GridItem>
        <GridItem xs={12} sm={6} md={4}>
          <CustomInput
            labelText="Documento del Egresado"
            id={`${key + "document"}`}
            // error={state.owner.companions[key].name.length >= 1 ? !/^[A-Za-z]+$/.test(state.owner.companions[key].name) === true : '' }
            value={graduate.doc}
            formControlProps={{
              fullWidth: true,
              // onChange: event => {
              //   setState(
              //     state,
              //     (state.owner.companions[key].lastName = event.target.value)
              //   );
              // }
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={3}>
          <CustomInput
            labelText="Contraseña"
            id={`${key + "password"}`}
            // error={state.owner.companions[key].name.length >= 1 ? !/^[A-Za-z]+$/.test(state.owner.companions[key].name) === true : '' }
            value={graduate.password}
            formControlProps={{
              fullWidth: true,
              // onChange: event => {
              //   setState(
              //     state,
              //     (state.owner.companions[key].lastName = event.target.value)
              //   );
              // }
            }}
          />
        </GridItem>
      </div>
    )
  })

  return (
    <div style={{ width: "100%", heigth: "100%" }}>
      <GridItem xs={12} sm={12} md={12} lg={12}>
        <Card>
          <CardHeader>
            <h4 style={{ fontSize: "35px" }} className={classes.cardTitle}>
              Check In de Graduados
            </h4>
          </CardHeader>
          <CardBody>
            <Accordion
              content={<div>asd</div>}
              active={true}
              collapses={[
                {
                  title: "Información",
                  content: (
                    <GridContainer>
                      <GridItem
                        xs={12}
                        sm={12}
                        md={6}
                        style={{ alignSelf: "center" }}
                      >
                        <CustomInput
                          labelText="Fecha de Ingreso"
                          id="type"
                          value={state.checkIn}
                          formControlProps={{ fullWidth: true }}
                        />
                      </GridItem>
                      <GridItem
                        xs={12}
                        sm={12}
                        md={6}
                        style={{ alignSelf: "center" }}
                      >
                        <Datetime
                          timeFormat={false}
                          closeOnSelect
                          isValidDate={valid}
                          value={state.checkOut}
                          inputProps={{ placeholder: "Fecha de Salida" }}
                          onChange={event => {
                            setState(
                              state,
                              (state.checkOut = event.format("L"))
                            );
                          }}
                          formControlProps={{ fullWidth: true }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          disabled
                          labelText="Codigo"
                          id="code"
                          value={state.code}
                          formControlProps={{ fullWidth: true }}
                        />
                      </GridItem>
                    </GridContainer>
                  )
                },
                {
                  title: "Habitaciones",
                  content: (
                    <div style={{ display: "flex", flexDirection: "row", padding: "initial", width: "100%", margin: "1vh", flexWrap: "wrap" }}>

                      {showRooms(rooms)}
                    </div>
                  )
                },
                currentRoom ?
                  {
                    title: `Habitación ${currentRoom.type} nummero ${currentRoom.number}`,
                    content: (
                      <div>


                        <Button
                          color="info"
                          onClick={() => {
                            // const changingRoos=
                            // currentRoom.graduates&&setState(
                            //   state
                            // )
                            const addingGraduates = currentRoom.graduates || null
                            addingGraduates && addingGraduates.push({
                              email: "",
                              doc: "",
                              password: "123"
                            })

                            currentRoom.graduates ?
                              setCurrentRoom(prevState => {
                                return {
                                  ...prevState, graduates: addingGraduates
                                }
                              })
                              :
                              setCurrentRoom(prevState => {
                                return {
                                  ...prevState, graduates: [
                                    {
                                      email: "",
                                      doc: "",
                                      password: "123"
                                    }
                                  ]
                                }
                              })

                          }}
                          className={classes.updateProfileButton}
                        >
                          Agregar Egresados
                    </Button>
                        <div style={{ width: "100%" }}>
                          {currentRoom.graduates ?
                            showGraduates(currentRoom.graduates) :
                            null

                          }
                        </div>
                      </div>
                    )
                  } : {
                    title: "",
                    content: ""
                  }



              ]}
            />
            {
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center"
                }}
              >
                <Button
                  disabled={
                    state.rooms.length >= 1 &&
                      state.checkOut !== "" &&
                      state.rooms[0].number !== ""
                      ? false
                      : true
                  }
                  onClick={() => {

                    dispatch(checkIn(state, localStorage.getItem("hotel")));
                    setAlert(
                      <SweetAlert
                        success
                        style={{ display: "block", marginTop: "-200px" }}
                        title="Felicitaciones"
                        onConfirm={() => history.push("/admin/rooms")}
                        onCancel={() => setAlert(null)}
                        confirmBtnCssClass={
                          classes.button + " " + classes.success
                        }
                      >
                        ¡Se ha registrado exitosamente!
                      </SweetAlert>
                    );
                  }}
                  color="rose"
                  className={classes.updateProfileButton}
                >
                  Realizar CheckIn
                </Button>
              </div>
            }
          </CardBody>
        </Card>
      </GridItem>
      {alert}
    </div>
  );
});