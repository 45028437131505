const initialState = {
	text: '',
	altText: '',
	option: 2,
	horario: 'mañana',
	templates: [],
	selectedRooms: [],
	programar: 'ahora',
	whatsapp: false,
}

export default (state = initialState, { type, payload }) => {
	switch (type) {
		case 'SET_TEXT':
			return {
				...state,
				...payload
			}

		case 'UPDATE_FORM':
			return {
				...state,
				...payload
			}

		case 'RESET_CAMPAIGN':
			const templates = state.templates
			return {
				...initialState,
				templates
			}

		default:
			return state
	}
}