import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { getService } from 'redux/actions/webappServices'
import Button from '../components/button'

export default ({ room }) => {
	const state = useSelector(state => state.services.information)
	const dispatch = useDispatch()
	const history = useHistory()
	const hotelId = localStorage.getItem('hotel')

	useEffect(() => dispatch(getService(hotelId, room, 'information')), [dispatch, hotelId, room])

	return (
		<Button
			onClick={() => history.push(`/${hotelId}/app/${room}/information`)}
			service={state}
		/>
	)
}

