import firebase from '../../services/firebase'
import axios from 'axios'

const hotel = firebase.firestore().collection("hotels")
const API = 'https://hotels-prod-a5b59.firebaseapp.com'

export const getCampaignConfig = () => (dispatch) => {
  hotel.doc(localStorage.getItem('hotel')).get()
    .then(resp => {
      const data = resp.data()
      dispatch({ type: 'UPDATE_FORM', payload: { whatsapp: data.whatsapp, templates: data.campaignsTemplates } })
    })
}


export const sendCampaign = () => (dispatch, getState) => {
  const { rooms } = getState().rooms
  const { text,altText, whatsapp, selectedRooms } = getState().campaigns
  console.log(text,altText)

  let toSend = []
  if (selectedRooms.length === 0) {
    return toSend = rooms.filter(room => room.status === 'in')
  } else {
    selectedRooms.map(number => {
      return toSend.push(rooms.filter(room => room.roomNumber === number)[0])
    })
  }
  // console.log(toSend)
  toSend.map(room => (
    hotel.doc(localStorage.getItem('hotel')).collection("checkIn").doc(room.roomCode.toString()).get()
      .then(user => {
        console.log(user.data())
        axios.post(`${API}/api/hotel/sendcampaign`,
          {
            number: user.data().number,
            message: text?text:altText,
            whatsapp
          }
        )
          .then(() => console.log('sms enviado con exito'))
          .catch(() => console.log('sms no enviado'))
      }
      )
      .catch(() => console.log('error 1'))
  ))
}