import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import axios from 'axios'

// Actions
import { checkIn, checkOut } from 'redux/actions/checkIn' 

// Icons&Cores
import People from '@material-ui/icons/People'
import { PriorityHigh } from '@material-ui/icons/'
import AddCircle from '@material-ui/icons/PersonAdd'
import Transfer from '@material-ui/icons/TransferWithinAStation'
import SweetAlert from 'react-bootstrap-sweetalert'

// Core Components
import Card from 'components/Card/Card'
import CardIcon from 'components/Card/CardIcon'
import GridItem from 'components/Grid/GridItem'
import CardHeader from 'components/Card/CardHeader'
import CardFooter from 'components/Card/CardFooter'
import GridContainer from 'components/Grid/GridContainer'

// Styles
import sweetAlertStyle from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle'
import withStyles from '@material-ui/core/styles/withStyles'
import dashboardStyle from 'assets/jss/material-dashboard-pro-react/views/dashboardStyle'

// Moment.js
import moment from 'moment'
import 'moment/locale/es.js'
import { liteEmergency } from 'redux/actions/webApp'
moment.locale('es')

const styles = {
  ...sweetAlertStyle,
  ...dashboardStyle,
}

export default withStyles(styles)(props => {
  const history = useHistory()
  const dispatch = useDispatch()
  const license = useSelector(state => state.hotel.hotelInfo.license)
  const rooms = useSelector(state => state.rooms.rooms)
  const [alertCheckIn, setAlertCheckIn] = useState(false)
  const [alertCheckOut, setAlertCheckOut] = useState(false)
  const [alertEmergency, setAlertEmergency] = useState(null);
  const [roomSelected, setRoomSelected] = useState('')
  const [roomType, setRoomType] = useState('')
  const [name, setName] = useState('')
  const [number, setNumber] = useState('')
  const [email, setEmail] = useState('')
  const hotelId = localStorage.getItem('hotel')
  const [QR, getQR] = useState(null)

  // useEffect(() => {
  //   axios({
  //     type: 'GET',
  //     url:'https://chart.googleapis.com/chart?cht=qr&chs=150x150&ch1=www.google.com.ar&choe=ISO-8859-1' 
  //     // data: {
  //     //   frame_name: "no-frame",
  //     //   qr_code_text: "https://www.qr-code-generator.com/",
  //     //   image_format: "SVG",
  //     //   qr_code_logo: "scan-me-square"
  //     // }
  //   })
  //   .then(response => {
  //     let image = response.data
  //     console.log(image)
  //     // let b64Image = window.btoa(image)

  //     // getQR(b64Image)})
  //   })
  //   .catch(err => console.log(err))


  //   fetch('https://api.qrserver.com/v1/create-qr-code/?size=150x150&type=link&data=http://campaigns.lovable.solutions/table/5')
  //   .then(response => { 
  //     // console.log(response)
  //     response.blob()
  //     .then(blob => {
  //       getQR(blob)
  //     })
  //   })
  //   // .then(myJson => console.log(myJson))
  //   .catch(err => console.log(err))
  // }, [])

  const toggleCheckin = () => {
    let data = { name, number, email, room: roomSelected, }

    dispatch(checkIn(data, license, hotelId))
    setAlertCheckIn(false)
  }

  const handleRoom = room => {
    setRoomSelected(room.roomNumber)
    setRoomType(room.type)

    if(license === 'lite') {
      room.available ? setAlertCheckIn(true) : room.services.includes('Emergencia en Curso') ? setAlertEmergency(room) : setAlertCheckOut(true)
    } else {
      dispatch({ type: 'GET_ROOM', payload: room })
      history.push(`./room/${room.roomNumber}`)
    }
  }

  const { classes } = props

  return (
    <div style={{ userSelect: 'none' }}>
      {QR ? ( <img src={window.URL.createObjectURL(QR)} />) : ''}
      <GridContainer>
        {
          rooms.map((room, key) => {
            let ifEmergency = room.services.includes('Emergencia en Curso')
            return (
              <GridItem xs={12} sm={6} md={6} lg={4} key={key}>
                <Card
                  className='card-dashboard'
                  style={{ userSelect: 'none', cursor: 'pointer', backgroundColor: ifEmergency ? 'rgb(170, 170, 170)' : 'white' }}
                  onClick={() => license === 'lite' ? handleRoom(room) : room.available === true ? '' : handleRoom(room)}
                >
                  <CardHeader stats icon>
                    <CardIcon
                      color="rose"
                      style={{
                        boxShadow: '0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(170, 170, 170,.4)',
                        background:
                          ifEmergency
                            ? 'rgb(248, 67, 63)'
                            : room.available === false
                                ? '#ff2068'
                                : room.available === true
                                  ? '#6b6b6b'
                                  : room.available === 'Limpieza'
                                    ? '#32CD32'
                                    : 'linear-gradient(to bottom, #e63673, #e63673), linear-gradient(to right, #ec407a, #d81b60)'
                      }}>
                      {
                        room.available === false
                          ? <People />
                          : room.available === true
                            ? <AddCircle />
                            : room.available === 'Limpieza'
                              ? <Transfer />
                              : <AddCircle />
                      }
                    </CardIcon>
                    <p className={classes.cardCategory} style={{ color: ifEmergency ? 'white' : '#999' }}>
                      {
                        room.available === false
                          ? 'En uso'
                          : room.available === 'Limpieza'
                            ? 'Listo para Limpieza'
                            : 'Habilitado'
                      }
                    </p>
                    <h3 className={classes.cardTitle} style={{ color: ifEmergency ? 'white' : '#3C4858' }}>
                      Habitación {room.roomNumber}
                    </h3>
                    {
                      license === 'lite' && room.roomCode
                        ? <p style={{ color: '#999999', margin: 0 }}>Codigo: {room.roomCode}</p>
                        : <div style={{ height: 20 }} />
                    }
                  </CardHeader>
                  <CardFooter style={{ height: 25 }} stats>
                    <div className={classes.stats} style={{ color: ifEmergency ? 'white' : '#999' }}>
                      {room.type}
                    </div>
                    {
                      room.services.length >= 1 ?
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <div style={{ paddingBottom: 3 }}>
                            <PriorityHigh style={{ color: 'red' }} />
                          </div>
                          <p style={{ margin: 0, fontSize: 12, color: ifEmergency ? 'white' : 'rgba(0, 0, 0, 0.87)' }}>
                            {room.services[0]}
                          </p>
                        </div> : ''
                    }
                  </CardFooter>
                </Card>
              </GridItem>
            )
          })
        }
      </GridContainer>
      {
        alertCheckIn && (
          <SweetAlert
            style={{ display: 'block' }}
            title='CheckIn'
            showCancel
            onConfirm={() => toggleCheckin(roomSelected)}
            onCancel={() => setAlertCheckIn(false)}
            confirmBtnCssClass={`${classes.button} ${classes.success}`}
            cancelBtnCssClass={`${classes.button} ${classes.danger}`}
          >
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
              <p style={{ margin: 0 }}>Nombre y Apellido</p>
              <input style={{ width: '100%', height: 30, fontSize: 16 }} onChange={e => setName(e.target.value)} type="text" />
              <p style={{ margin: 0 }}>Email</p>
              <input style={{ width: '100%', height: 30, fontSize: 16 }} onChange={e => setEmail(e.target.value)} type="email" />
              <p style={{ margin: 0 }}>Teléfono</p>
              <input style={{ width: '100%', height: 30, fontSize: 16 }} onChange={e => setNumber(e.target.value)} type="number" />
            </div>
          </SweetAlert>
        )
      }
      {
        alertCheckOut && (
          <SweetAlert
            warning
            showCancel
            style={{ display: 'block' }}
            title='CheckOut'
            onConfirm={() => {
              dispatch(checkOut(roomSelected, roomType, hotelId))
              setAlertCheckOut(false)
            }}
            onCancel={() => setAlertCheckOut(false)}
            confirmBtnCssClass={`${classes.button} ${classes.success}`}
            cancelBtnCssClass={`${classes.button} ${classes.danger}`}
          >
            ¿Desea realizar el checkout de ésta Habitación?
          </SweetAlert>
        )
      }
      {
        alertEmergency && (
          <SweetAlert
            warning
            showCancel
            style={{ display: 'block' }}
            title='Emergencia en Curso'
            onConfirm={() => {
              dispatch(liteEmergency(hotelId, alertEmergency))
              setAlertEmergency(null)
            }}
            onCancel={() => setAlertEmergency(null)}
            confirmBtnCssClass={`${classes.button} ${classes.success}`}
            cancelBtnCssClass={`${classes.button} ${classes.danger}`}
          >
            ¿Se resolvió la emergencia?
          </SweetAlert>
        )
      }
    </div>
  )
})
