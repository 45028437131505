const initialState = {
  uid: '',
  loading: false,
  errorEmail: false,
  errorPassword: false,
  error: '',
  isLogin: 'checking',
  hotel: '',
  color: '#000'
}

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case 'LOGIN_STATUS':
      return {
        ...state,
        loading: action.payload.loading,
        error: action.payload.error,
        errorEmail: action.payload.errorEmail,
        errorPassword: action.payload.errorPassword
      }

    case 'USER_DATA':
      return {
        ...state,
        isLogin: action.payload.isLogin,
        uid: action.payload.uid,
        hotel: action.payload.hotel,
      }

    case 'LOGIN_RESET':
      return {
        ...state,
        loading: false,
        errorEmail: false,
        errorPassword: false,
        error: ''
      }

    case 'LOGIN_ALLRESET':
      return {
        ...state,
        isLogin: 'inactive'
      }

    case 'SET':
      return {
        ...state,
        uid: action.payload
      }

    default:
      return state
  }
}
