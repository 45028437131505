import firebase from '../../services/firebase'

const userAuth = firebase.firestore().collection('users')
const dbHotel = firebase.firestore().collection('hotels')


export const userCreation = (email, password, settings, services, code, license) => dispatch => {
  firebase.auth().createUserWithEmailAndPassword(email, password).catch(function (error) {
    console.log(error.message)
  }).then(user => {
    const uid = user.user.uid

    userAuth.doc(uid).set({
      uid: uid, hotel: code
    })

    dbHotel.doc(code.toString()).set({
      name: "",
      email: email,
      phone: "",
      settings: settings,
      services: services,
      onCreation: true,
      license: license
    })

  }).catch(err => console.log(err))

}


export const hotelCreation = (hotelId, state) => dispatch => {

  const {
    hotelName,
    email,
    password,
    phone,
    direction,
    primaryColor,
    fontColor,
    backgroundColor,
    rooms } = state


  let hotel = dbHotel.doc(hotelId.toString())

  //First  update  the New  Info given by the User
  hotel.update({
    email: email,
    password: password,
    name: hotelName,
    phone: phone,
    direction: direction,
    roomTypes: rooms,
    campaignsTemplates: [
      { text: "Feliz cumpleaños de parte de todo el staff del hotel", title: "Cumpleaños" },
      { text: "Querido cliente, le informamos que posee una deuda de ****. Abonelo lo antes posible. Muchas gracias.", title: "Deuda" }
    ],
    styles: {
      primaryColor: primaryColor,
      fontColor: fontColor,
      backgroundColor: backgroundColor
    }

  })
    .catch(err => console.log(err))

  let hotelRooms = []
  //Looping in the rooms  to  push an organize value with its current type of  rooms in hotelRooms
  for (let i = 0; i <= rooms.length - 1; i++) {

    for (let j = 0; j <= rooms[i].rooms.length - 1; j++) {

      hotelRooms.push({ room: rooms[i].rooms[j], type: rooms[i].name })

    }
  }



  // Creation all the Docs of the Hotel
  Promise.all(
    [
      hotel.collection("chat").doc("test").set({ message: "test1" }),
      hotel.collection("checkIn").doc("test").set({ test: "test1" }),
      hotel.collection("cleanUsers").doc("test").set({ test: "test1" }),
      hotel.collection("notifications").doc("test").set({ test: "test1" })
    ]
  )
    .then(
      console.log("success")
    )
    .catch(err => console.log(err))

  //Looping  the hotelRooms let for making each room doc with the corresponding information
  for (let i = 0; i <= hotelRooms.length - 1; i++) {

    hotel.collection("rooms").doc(hotelRooms[i].room.number.toString()).set({
      available: true,
      number: hotelRooms[i].room.number,
      premiun: hotelRooms[i].room.premiun,
      roomCode: "",
      services: [],
      type: hotelRooms[i].type
    })
  }


  //First  we need the current Services info and images for create suchs  values , the only  and best way  i got is the next way 
  hotel.get()
    .then(
      info => info.data().services
    )
    .then(services => {

      let allServices = [
        {
          key: "disturb",
          active: false,
          status: services.disturb,
          image: 'https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Images%2Fdoor-knob.svg?alt=media&token=13fe387b-4460-4b8a-8e3c-711a96fec20b',
          name: 'No Molestar',
          labelBot: 'MOLESTAR',
          imageActive: 'https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Images%2Fdoor-knobred.svg?alt=media&token=dca46c2e-4f96-4746-8768-8f67a987faf9',
          rooms: {},
          labelTop: 'NO',
          onDash: true
        },
        {
          key: "clean",
          active: false,
          onDash: true,
          status: services.clean,
          image: 'https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Images%2Fsweep.svg?alt=media&token=8dae853a-e0e2-42b9-bbde-68f86acda28f',
          name: 'Limpieza',
          labelBot: 'HABITACIÓN',
          imageActive: 'https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Images%2FsweepActive.svg?alt=media&token=feb7b2e6-4846-497c-85eb-6b39c0dddc4f',
          rooms: {},
          labelTop: 'LIMPIEZA DE'
        },
        {
          key: "food",
          labelBot: 'COMIDA',
          onDash: true,
          imageActive: 'https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Images%2Fdishactive.svg?alt=media&token=16ae4112-7e7c-4c7f-ac10-e2ef63048cd6',
          rooms: {},
          labelTop: 'PEDIR',
          active: false,
          status: services.food,
          image: 'https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Images%2Fdish.svg?alt=media&token=d0a710c6-8d54-4338-b046-39ff62681188',
          name: 'Comida'
        },
        {
          key: "emergency",
          labelBot: 'URGENCIA',
          onDash: true,
          imageActive: 'https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Images%2Falarmred.svg?alt=media&token=b4236a80-7276-4096-943c-1e4f14cb3e3a',
          rooms: {},
          labelTop: 'SOLICITAR',
          active: false,
          status: services.emergency,
          image: 'https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Images%2Falarm.svg?alt=media&token=73a33e01-d96b-4217-bdc9-c336c9b3a3ba',
          name: 'Emergencia',
          emergencyStatus: false
        },
        {
          key: "chat",
          imageActive: 'https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Images%2Fchat.svg?alt=media&token=3174c6ea-b90d-449c-83f2-edeadab0799c',
          rooms: {},
          labelTop: 'INICIAR',
          active: false,
          status: services.chat,
          image: 'https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Images%2Fchat.svg?alt=media&token=3174c6ea-b90d-449c-83f2-edeadab0799c',
          name: 'webChat',
          labelBot: 'CHAT'
        },
        {
          key: "survey",
          rooms: {},
          labelTop: 'REALIZAR',
          active: false,
          image: 'https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Images%2Fsurvey.svg?alt=media&token=7019e984-5fc9-436f-8f74-b7de014a3436',
          name: 'encuesta',
          labelBot: 'ENCUESTA',
          status: services.surveys,
          imageActive: 'https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Images%2Fsurvey.svg?alt=media&token=7019e984-5fc9-436f-8f74-b7de014a3436'
        },
        {
          key: "alarm",
          rooms: {},
          labelTop: 'INICIAR',
          active: false,
          status: services.alarm,
          image: 'https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Images%2Falarm-clock.svg?alt=media&token=d18c309d-8766-4619-b080-8332a3dd1e6a',
          name: 'Alarma',
          labelBot: 'DESPERTADOR',
          imageActive: 'https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Images%2Falarm-clockred.svg?alt=media&token=53746184-cfc3-4f56-9eb3-d303d569d6a9'
        },
        {
          key: "towels",
          rooms: {},
          labelTop: 'CAMBIAR',
          active: false,
          status: services.towels,
          onDash: true,
          image: 'https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Images%2Ftowels.svg?alt=media&token=43de0c96-2ae4-4784-9f43-7ebdf3e463ed',
          name: 'Toallas',
          labelBot: 'TOALLAS',
          imageActive: 'https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Images%2FtowelsRed.svg?alt=media&token=e056255c-8485-4b9b-ad10-d3c94ee2c204'
        },
        {
          key: "events",
          labelBot: 'EVENTOS',
          imageActive: 'https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Images%2Fcalendar.svg?alt=media&token=dd791647-6ca4-450f-818e-02c3dca8de26',
          rooms: {},
          labelTop: 'REVISAR',
          active: false,
          status: services.events,
          image: 'https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Images%2Fcalendar.svg?alt=media&token=dd791647-6ca4-450f-818e-02c3dca8de26',
          name: 'Calendario'
        },
        {
          key: "reservation",
          active: false,
          status: services.reservation,
          onDash: true,
          image: 'https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Images%2Freservation.svg?alt=media&token=402428f9-2fc0-413b-8cac-3a9a174a8c4f',
          name: 'Reservación',
          labelBot: 'MESA',
          imageActive: 'https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Images%2Freservationred.svg?alt=media&token=1577ea11-957b-4b52-899e-2277902e5212',
          rooms: {},
          labelTop: 'RESERVAR'
        },
        {
          key: "ski",
          labelBot: 'DE SKI',
          imageActive: 'https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Images%2Fskired.svg?alt=media&token=0fdae721-1c1c-400d-8c9f-0ac14c7a8669',
          rooms: {},
          labelTop: 'EQUIPO',
          active: false,
          status: services.ski,
          image: 'https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Images%2Fski.svg?alt=media&token=c9fc3481-f894-49da-be55-2eef92f71260',
          name: 'Ski'
        }
        , {
          key: "map",
          rooms: {},
          labelTop: "MAPA DEL",
          labelBot: "COMPLEJO",
          name: "Mapa",
          active: false,
          status: services.map,
          image: "https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Images%2Fmaps.svg?alt=media&token=f2211559-2fb6-45cd-97b1-4088ea46d733",
          imageActive: "https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Images%2Fmaps.svg?alt=media&token=f2211559-2fb6-45cd-97b1-4088ea46d733",

        },
        {
          key: "information",
          rooms: {},
          labelTop: "INFO",
          lavelBot: "",
          active: false,
          status: services.information,
          name: "Información",
          image: "https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Images%2Finfo.svg?alt=media&token=8cd86b6b-d0c0-4629-98ef-433a44b6db41",
          imageActive: "https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Images%2Finfo.svg?alt=media&token=8cd86b6b-d0c0-4629-98ef-433a44b6db41"
        },
        {
          key: "medic",
          rooms: {},
          labelTop: "SOLICITAR MEDICO",
          labelBot: "MEDICO",
          onDash: true,
          active: false,
          status: services.medic,
          name: "Medico",
          image: "https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Images%2Fstethoscope.svg?alt=media&token=5e01b2e7-f498-4495-bfea-ce564493c8e9",
          imageActive: "https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Images%2Fstethoscopered.svg?alt=media&token=9fbd2dc2-5b02-443e-9359-302549ce0de2"
        }
      ]


      for (let i = 0; i <= allServices.length - 1; i++) {

        hotel.collection("services").doc(allServices[i].key).set(allServices[i])
      }


    })

  // //Turning Off Creation Mode
  hotel.update({ onCreation: false })
}