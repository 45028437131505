import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Button from '../components/button'
import { getService, changeStatus } from 'redux/actions/webappServices'

export default ({ room }) => {
  const service = useSelector(state => state.services.alarm)
  const dispatch = useDispatch()
  const hotelId = localStorage.getItem('hotel')

  useEffect(() => dispatch(getService(hotelId, room, 'alarm')), [hotelId, room, dispatch])

  const { name, key } = service

  return <Button key={key} onClick={() => changeStatus(hotelId, room, name, key)} service />
}
