import React, { Component } from 'react'
import { connect } from 'react-redux'
import { sendSurvey } from '../../redux/actions/survey'

// sweetAlertStyle 
import SweetAlert from 'react-bootstrap-sweetalert'
import sweetAlertStyle from '../../assets/jss/material-dashboard-pro-react/views/sweetAlertStyle'

// Core Components
import Wizard from '../../components/Wizard/Wizard'
import GridItem from '../../components/Grid/GridItem'
import GridContainer from '../../components/Grid/GridContainer'

// Steps
import Select from './steps/select'

// Styles
import withStyles from '@material-ui/core/styles/withStyles'

const styles = {
  ...sweetAlertStyle,
}

class Survey extends Component {
  constructor(props) {
    super(props)

    this.state = {
      alert: false,
      ready: false
    }
  }

  componentDidMount() {
    const { history, surveyName, classes } = this.props
    this.unblock = history.block(targetLocation => {
      if (targetLocation.pathname !== '/admin/surveys' && surveyName !== '' ) {
        this.setState({
          alert: (
            <SweetAlert
              warning
              style={{ display: 'block' }}
              title='¿Seguro que quieres irte?'
              onConfirm={() => this.successDelete(targetLocation.pathname)}
              onCancel={() => this.hideAlert()}
              confirmBtnCssClass={`${classes.button} ${classes.success}`}
              cancelBtnCssClass={`${classes.button} ${classes.danger}`}
              confirmBtnText='ABANDONAR'
              cancelBtnText='CANCELAR'
              showCancel
            >
              ¡Perderás los cambios!
            </SweetAlert>
          )
        })
        return this.state.ready
      }
    })
  }

  componentWillUnmount() {
    this.props.dispatch({ type: 'RESET_SURVEY' })
    this.unblock()
  }

  successSave() {
    this.setState({
      alert: null,
      ready: true
    }, () => this.props.history.push('/admin/rooms')
    )
    this.props.dispatch({ type: 'RESET_SURVEY' })
  }

  hideAlert() {
    this.setState({
      alert: null
    })
  }

  successDelete(location) {
    this.setState({
      alert: null,
      ready: true
    }, () => this.props.history.push(location)
    )
  }

  render() {
    const { dispatch, classes } = this.props
    const { alert } = this.state
    return (
      <React.Fragment>
        {alert}
        <GridContainer justify='center' style={{ userSelect: 'none' }}>
          <GridItem xs={12} sm={8}>
            <Wizard
              validate
              steps={[
                { stepName: 'SELECCIONAR', stepComponent: Select, stepId: 'client' },
                // { stepName: 'ENVIAR', stepComponent: Send, stepId: 'rooms' }
              ]}
              title='Enviar Encuesta'
              subtitle='Para enviar la encuesta siga los pasos.'
              finishButtonClick={() => {
                this.setState({
                  alert: (
                    <SweetAlert
                      success
                      style={{ display: 'block', marginTop: '-200px' }}
                      title='Felicitaciones'
                      onConfirm={() => this.successSave()}
                      onCancel={() => this.successSave()}
                      confirmBtnCssClass={
                        classes.button + ' ' + classes.success
                      }
                    >
                      ¡Se ha completado la Encuesta!
                    </SweetAlert>
                  )
                })
                dispatch(sendSurvey())
              }}
            />
          </GridItem>
        </GridContainer>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  surveyName: state.survey.surveyName
})

export default connect(mapStateToProps)(withStyles(styles)(Survey))
