import firebase from '../../services/firebase'

const hotel = firebase.firestore().collection('hotels')

export const getRoom = (id, number) => dispatch => {
  hotel.doc(id).collection('rooms').doc(number).onSnapshot(room => {
    let arr = []

    arr.push({ ...room.data(), roomNumber: room.id })

    dispatch({ type: 'GET_ROOM', payload: arr[0] })
  })
}

export const getRooms = id => dispatch => {
  hotel.doc(id).collection('rooms').onSnapshot(rooms => {
    let arr = []

    rooms.docs.forEach(room => arr.push({ ...room.data(), roomNumber: room.id }))

    arr.sort((a, b) => a.number - b.number)
    arr.sort((a, b) => a.available - b.available).sort((a, b) => b.services.length - a.services.length)

    dispatch({ type: 'GET_ROOMS', payload: arr })
  })
}

export const getUser = (id, code) => (dispatch) => {
  hotel.doc(id).collection('checkIn').doc(code.toString()).onSnapshot(user => dispatch({ type: 'GET_USER', payload: user.data() }))
}
