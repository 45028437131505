import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

// Actions
import { getUser, getRoom } from "redux/actions/rooms";
import { checkOut } from "redux/actions/checkIn";
import { getService, addService, removeService } from "redux/actions/services";

// Icons&Core
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Check from "@material-ui/icons/Check";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CircularProgress from "@material-ui/core/CircularProgress";

// Core Components
import Card from "components/Card/Card";
import GridItem from "components/Grid/GridItem";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import Button from "components/CustomButtons/Button";
import Accordion from "components/Accordion/Accordion";
import GridContainer from "components/Grid/GridContainer";
import CustomInput from "components/CustomInput/CustomInput";
import userProfileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle";

// Styles
import withStyles from "@material-ui/core/styles/withStyles";

const styles = {
  ...userProfileStyles,
  ...customSelectStyle
};

export default withStyles(styles)(props => {
  const classes = props.classes;
  const dispatch = useDispatch();
  const room = useSelector(state => state.rooms.room);
  const user = useSelector(state => state.user.user);
  const services = useSelector(state => state.services.services);
  const history = useHistory();
  const hotelId = localStorage.getItem("hotel");

  useEffect(() => {
    dispatch(getRoom(hotelId, props.match.params.room));
    dispatch(getService(hotelId));

    if (room.roomCode) dispatch(getUser(hotelId, room.roomCode));
  }, [room.roomCode, dispatch, hotelId, props.match.params.room]);

  const companions = companions =>
    companions.map((companion, key) => {
      return (
        <div key={key} style={{ width: "100%", display: "flex" }}>
          <GridItem xs={12} sm={6} md={5}>
            <CustomInput
              labelText="Nombre del Acompañante"
              id="name"
              value={companion.name}
              formControlProps={{ fullWidth: true }}
            />
          </GridItem>
          <GridItem xs={12} sm={6} md={5}>
            <CustomInput
              labelText="Documento del Acompañante"
              id="name"
              value={companion.doc}
              formControlProps={{
                fullWidth: true
              }}
            />
          </GridItem>
          <FormControlLabel
            control={
              <Checkbox
                tabIndex={-1}
                checkedIcon={<Check className={classes.checkedIcon} />}
                icon={<Check className={classes.uncheckedIcon} />}
                classes={{
                  checked: classes.checked,
                  root: classes.checkRoot
                }}
              />
            }
            classes={{
              label: classes.label
            }}
            label="Menor de edad"
          />
        </div>
      );
    });

  return room.type && user.name ? (
    <div style={{ width: "100%", heigth: "100%" }}>
      <GridItem xs={12} sm={12} md={6} lg={12}>
        <Card>
          <CardHeader>
            <h4 style={{ fontSize: "35px" }} className={classes.cardTitle}>
              Habitación {room.roomNumber}
            </h4>
          </CardHeader>
          <CardBody>
            <Accordion
              active={true}
              collapses={[
                {
                  title: " Habitación",
                  content: (
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Tipo"
                          id="type"
                          value={room.type || ""}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Codigo"
                          id="code"
                          value={room.roomCode || ""}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                  )
                },
                {
                  title: "Reserva",
                  content: (
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Ingreso"
                          id="checkin"
                          value={room.checkIn || ""}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Salida"
                          id="checkout"
                          value={room.checkOut || ""}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                  )
                },
                {
                  title: "Cliente",
                  content: (
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Nombre del Titular"
                          id="name"
                          value={user.name + " " + user.lastName}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Documento"
                          id="document"
                          value={user.document}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Correo Electronico"
                          id="email"
                          value={user.email}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                        <CustomInput
                          labelText="Telefono Celular"
                          id="phone"
                          value={user.phone}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                  )
                },
                user.companions.length >= 1
                  ? {
                      title: "Acompañantes",
                      content: (
                        <GridContainer>
                          {companions(user.companions)}
                        </GridContainer>
                      )
                    }
                  : "",
                {
                  title: "Servicios",
                  content: (
                    <GridContainer style={{ flexDirection: "column" }}>
                      <div
                        style={{ width: "100%", flexDirection: "row-reverse" }}
                      >
                        {room.services.length === 0 ? (
                          <GridItem xs={12} sm={12} md={4}>
                            <CustomInput
                              labelText="Servicio"
                              id="sinServicios"
                              value={"Sin Servicios"}
                              formControlProps={{ fullWidth: true }}
                            />
                          </GridItem>
                        ) : (
                          room.services.map((service, key) => {
                            return (
                              <div
                                key={key}
                                style={{
                                  display: "flex",
                                  justifyItems: "center"
                                }}
                              >
                                <GridItem xs={12} sm={12} md={4}>
                                  <CustomInput
                                    labelText="Servicio"
                                    id="checkout"
                                    value={service}
                                    formControlProps={{ fullWidth: true }}
                                  />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={4}>
                                  <CustomInput
                                    labelText="Estatus del Servicio"
                                    id="checkout"
                                    value="Pendiente"
                                    formControlProps={{ fullWidth: true }}
                                  />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={3}>
                                  <Button
                                    style={{ marginTop: 18, marginLeft: 28 }}
                                    onClick={() =>
                                      dispatch(
                                        removeService(
                                          localStorage.getItem("hotel"),
                                          room.number.toString(),
                                          service
                                        )
                                      )
                                    }
                                    color="rose"
                                  >
                                    Confirmar Servicio
                                  </Button>
                                </GridItem>
                              </div>
                            );
                          })
                        )}
                        <GridItem xs={12} sm={12} md={4} lg={4}>
                          <FormControl fullWidth>
                            <InputLabel htmlFor="simple-select">
                              Elegir Servicio
                            </InputLabel>
                            <Select
                              MenuProps={{
                                className: classes.selectMenu
                              }}
                              classes={{
                                select: classes.select
                              }}
                              value={room.validServices}
                              inputProps={{
                                name: "simpleSelect",
                                id: "simple-select"
                              }}
                            >
                              <MenuItem
                                disabled
                                classes={{
                                  root: classes.selectMenuItem
                                }}
                              >
                                Elegir Servicio
                              </MenuItem>
                              {services.map((service, key) => {
                                return (
                                  <div key={key}>
                                    <MenuItem
                                      classes={{
                                        root: classes.selectMenuItem,
                                        selected: classes.selectMenuItemSelected
                                      }}
                                      value={service.name}
                                      onClick={() =>
                                        dispatch(
                                          addService(
                                            localStorage.getItem("hotel"),
                                            room.number.toString(),
                                            service.key
                                          )
                                        )
                                      }
                                    >
                                      {service.name}
                                    </MenuItem>
                                  </div>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </GridItem>
                      </div>
                    </GridContainer>
                  )
                }
              ]}
            />
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                marginTop: 30
              }}
            >
              <Button
                onClick={() => {
                  dispatch(checkOut(room.number, room.type, hotelId));
                  history.push("/admin/rooms");
                }}
                color="rose"
                className={classes.updateProfileButton}
              >
                Finalizar Estadia
              </Button>
            </div>
          </CardBody>
        </Card>
      </GridItem>
    </div>
  ) : (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <CircularProgress color="secondary" />
    </div>
  );
});
