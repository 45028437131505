import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getClean, changeCleanStatus } from 'redux/actions/webappServices'

import Button from '../components/button'
import SweetAlert from 'react-bootstrap-sweetalert'

export default ({ room, classes }) => {
  const service = useSelector(state => state.services.clean)
  const dispatch = useDispatch()
  const hotelId = localStorage.getItem('hotel')
  const [alert, setAlert] = useState(null)
  console.log(hotelId)
  const item = bool => {
    return (
      <SweetAlert
        warning
        style={{ display: 'block' }}
        title={
          bool ? `finalizar el Pedido` : `Confirma antes de realizar el Pedido`
        }
        onConfirm={() => {
          changeCleanStatus(hotelId, room, service.name, service.key)
          setAlert(null);
        }}
        onCancel={() => setAlert(null)}
        confirmBtnCssClass={classes.button + ' ' + classes.success}
        cancelBtnCssClass={classes.button + ' ' + classes.danger}
        confirmBtnText="CONFIRMAR"
        cancelBtnText="CANCELAR"
        showCancel
      />
    )
  }

	useEffect(() => dispatch(getClean(hotelId, room, 'clean')), [dispatch, hotelId, room])

  return (
    <div>
      <Button key={service.key} onClick={() => setAlert(item(service.onUse))} service={service} />
      {alert}
    </div>
  )
}
